// component
import { Category, LocalMall, LocalOffer, LocalShippingSharp, PaidSharp, PeopleOutline, Person, PieChart, Reddit, Settings, ShoppingCart, ShoppingCartCheckoutSharp, SupervisorAccount } from '@mui/icons-material';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <PieChart />,
  },

  {
    title: 'users list',
    path: '/dashboard/user',
    icon: <SupervisorAccount />
  },
  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: <Category />
  },
  {
    title: 'brands',
    path: '/dashboard/brands',
    icon: <LocalOffer />
  },
  {
    title: 'products',
    path: '/dashboard/products',
    icon: <ShoppingCart />
  },
  {
    title: 'offers',
    path: '/dashboard/offers',
    icon: icon("ic_offer")
  },
  {
    title: 'All Orders',
    path: '/dashboard/manage_orders',
    icon: <LocalMall />
  },
  {
    title: 'replacement Orders',
    path: '/dashboard/replacement_orders',
    icon: <ShoppingCartCheckoutSharp />
  },
  {
    title: 'return Orders',
    path: '/dashboard/return_orders',
    icon: <LocalShippingSharp />
  },
  {
    title: 'manage refund',
    path: '/dashboard/refund',
    icon: <PaidSharp />
  },
  {
    title: 'manage admin',
    path: '/dashboard/admins',
    icon: <Person />
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: <Settings />
  },

];

export default navConfig;
