import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'

function OrderAddress({ shippingAddress, billingAddress }) {
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-evenly"} sx={{ p: 2, bgcolor: "#121212", borderRadius: "8px" }}>

                <Paper sx={{ width: 550 }}>
                    <Typography p={1} variant='h6'>Shipping Address</Typography>
                    <Stack direction={"row"} p={1}>
                        <Stack width={120} sx={{ borderRight: "1px solid black" }}>
                            <Typography>Fullname</Typography>
                            <Typography>Mobile</Typography>
                            <Typography>Address</Typography>
                            <Typography>City</Typography>
                            <Typography>Landmark</Typography>
                            <Typography>Postcode</Typography>
                            <Typography>Country</Typography>
                        </Stack>
                        <Stack ml={2}>
                            <Typography>{shippingAddress.fullname || "NA"}</Typography>
                            <Typography>{shippingAddress.mobile}</Typography>
                            <Typography >{shippingAddress.address}</Typography>
                            <Typography >{shippingAddress.city}</Typography>
                            <Typography >{shippingAddress.landmark || "NA"}</Typography>
                            <Typography>{shippingAddress.postCode}</Typography>
                            <Typography>{shippingAddress.country}</Typography>
                        </Stack>

                    </Stack>
                </Paper>

                <Paper sx={{ width: 550 }}>
                    <Typography p={1} variant='h6'>Billing Address</Typography>
                    <Stack direction={"row"} p={1}>
                        <Stack width={120} sx={{ borderRight: "1px solid black" }}>
                            <Typography>Fullname</Typography>
                            <Typography>Mobile</Typography>
                            <Typography>Address</Typography>
                            <Typography>City</Typography>
                            <Typography>Landmark</Typography>
                            <Typography>Postcode</Typography>
                            <Typography>Country</Typography>
                        </Stack>
                        <Stack ml={2}>
                            <Typography>{shippingAddress.fullname || "NA"}</Typography>
                            <Typography>{billingAddress.mobile}</Typography>
                            <Typography >{billingAddress.address}</Typography>
                            <Typography >{billingAddress.city}</Typography>
                            <Typography >{shippingAddress.landmark || "NA"}</Typography>
                            <Typography>{billingAddress.postCode}</Typography>
                            <Typography>{billingAddress.country}</Typography>
                        </Stack>

                    </Stack>
                </Paper>
            </Stack>
        </>
    )
}

export default OrderAddress