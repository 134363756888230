import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Popover, Select, Stack, TextField, Typography } from '@mui/material';
import { ContentCopy, FilterAlt, FilterList, RemoveRedEye, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


// ----------------------IMPORTS END---------------------------------||
const url = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
    textfiled: {
        colorScheme: "dark",

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    switch_track: {
        backgroundColor: "#185c5c",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));
function FailedOrder() {

    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);

    const [pageState, setPageState] = useState({
        loading: false,
        total: 0,
        page: 0,
        pageSize: 10
    })

    const [filterOpen, setFilterOpen] = useState(null);

    const [filters, setFilters] = useState({
        order: "",
        user: "",
        start: "",
        end: ""
    });

    const [oidFilter, setOidFilter] = useState("");

    const [uidFilter, setUidFilter] = useState("");

    const [startDate, setStartDate] = useState("");

    const [endDate, setEndDate] = useState("");

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            setPageState((old) => ({ ...old, loading: true }))
            const page = pageState.page < 0 ? 0 : pageState.page;
            const res = await axios.get(`${url}/order/all?paymentStatus=FAILED&page=${page}&size=${pageState.pageSize}&startDate=${filters.start}&endDate=${filters.end}&orderId=${filters.order}&userId=${filters.user}`)
            setPageState((old) => ({ ...old, loading: false, total: res.data.data.maxRecords }))
            setOrders(res.data.data.orders)
        }
        fetchData();
    }, [pageState.page, pageState.pageSize, filters])

    const GridAlignment = 'center';

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No',
            filterable: false,
            width: 80,
            align: GridAlignment,
            headerAlign: GridAlignment,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            valueFormatter: params => params.value ?
                moment(params?.value).format("DD/MM/YYYY") : "",
            flex: 1,
            align: GridAlignment,
            headerAlign: GridAlignment,
        },
        {
            field: 'orderId',
            headerName: 'Order Id',
            width: 250,
            flex: 1,
            headerAlign: GridAlignment,
            align: GridAlignment
        },
        {
            field: 'userId',
            headerName: 'User ID',
            width: 250,
            renderCell: (params) => <Stack direction={"row"}>
                <Button sx={{ color: "#01ffff" }} onClick={()=>navigate(`/dashboard/user/${params.value}`)} ><Visibility fontSize='small' /> View</Button>
                <Button sx={{ color: "#01ffff" }} onClick={() => navigator.clipboard.writeText(params.value)}><ContentCopy fontSize='small' /> Copy</Button>
            </Stack>,
            flex: 1,
            headerAlign: GridAlignment,
            align: GridAlignment,
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            width: 250,
            flex: 1,
            headerAlign: GridAlignment,
            align: GridAlignment
        },
    ];

    const rows = orders.map((order, index) => ({
        slno: pageState.page * pageState.pageSize + index + 1,
        ...order
    }))

    const handleFilterBoxClose = () => {
        setFilterOpen(null)
        setOidFilter("");
        setUidFilter("");
        setStartDate("");
        setEndDate("");

    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        setFilters((old) => ({ ...old, order: oidFilter, user: uidFilter, start: startDate, end: endDate }))
        handleFilterBoxClose();
    }

    return (
        <>
            <Stack direction={"row"} spacing={2} alignItems={"center"} m={1} mb={3} justifyContent={"flex-end"}>

                <IconButton onClick={(e) => setFilterOpen(e.currentTarget)}>
                    <FilterList sx={{ color: "white" }} />
                </IconButton>

                {/* FILTER BOX */}
                <Popover
                    elevation={2}
                    anchorEl={filterOpen}
                    open={filterOpen}
                    onClose={() => setFilterOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <form onSubmit={handleFilterSubmit}>
                        <Stack p={3} width={400} spacing={1} >
                            <Typography variant="h5">Filter by</Typography>

                            <TextField className={classes.textfiled}
                                onChange={(e) => setOidFilter(e.target.value)}
                                value={oidFilter}
                                size='small'
                                label="Order ID"
                            />
                            <TextField className={classes.textfiled}
                                value={uidFilter}
                                onChange={(e) => setUidFilter(e.target.value)}
                                size='small'
                                label="User ID"
                            />
                            <TextField className={classes.textfiled} onChange={(e) => setStartDate(e.target.value)} size='small' type={"date"} label={"From"} InputLabelProps={{ shrink: true }} />
                            <TextField className={classes.textfiled} onChange={(e) => setEndDate(e.target.value)} size='small' type={"date"} label={"To"} InputLabelProps={{ shrink: true }} />

                            <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} type="submit" fullWidth variant="contained">Filter</Button>
                        </Stack>
                    </form>
                </Popover>
            </Stack>
            <Paper sx={{ p: 2 }}>
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnSeparator": {
                            color: "#454545"
                        }
                    }}
                    getRowId={(row) => row.slno}
                    autoHeight
                    rows={rows}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    pagination
                    loading={pageState.loading}
                    columns={columns}
                    GridAlignment={'center'}
                    page={pageState.page}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState((old) => ({ ...old, page: newPage }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                />
            </Paper>
        </>
    )
}

export default FailedOrder