import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { sentenceCase } from 'change-case';

import { makeStyles } from "@mui/styles";
import { FilterList, Image, MoreVert } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Popover, Select, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";

import { DataGrid } from '@mui/x-data-grid';
import Label from '../components/label';
import Iconify from "../components/iconify";

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    switch_track: {
        backgroundColor: "#01ffff",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));


export default function ProductsPage() {

    const [openEditPopup, setOpenEditPopup] = useState(false)

    const [rowId, setRowId] = useState("")

    const [categories, setCategories] = useState([])

    const [brands, setBrands] = useState([])

    const [searchValue, setSearchValue] = useState("")

    const [pageState, setPageState] = useState({
        data: [],
        total: 0,
        page: 0,
        pageSize: 10
    })

    const [editPopupOpen, setEditPopupOpen] = useState(null);

    const [brandFilter, setBrandFilter] = useState("")

    const [catFilter, setCatFilter] = useState("")

    const [statusFilter, setStatusFilter] = useState("")

    const [stockFilter, setStockFilter] = useState(null)

    const [refurbishedProduct, setRefurbishedProduct] = useState(false);

    const [offerFilter, setOfferFilter] = useState("");

    const [offerData, setOfferData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [filters, setFilters] = useState({
        brand: "",
        cat: "",
        stock: null,
        status: "",
        offer: ""
    });

    const { productIdFromOrder } = useParams();

    const navigate = useNavigate()
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            const productType = refurbishedProduct === true ? "pre-owned" : "brand-new";
            const productRes = await axios.get(`${url}/products/get_all?type=${productType}&page=${pageState.page < 0 ? 0 : pageState.page}&size=${pageState.pageSize}&brand=${filters.brand}&category=${filters.cat}&status=${filters.status}&stock=${filters.stock}&id=${productIdFromOrder || ""}&offerId=${filters.offer}`)
            setIsLoading(false)
            setPageState((old) => ({ ...old, data: productRes.data.data.result, total: productRes.data.data.total }))

            const categoryRes = await axios.get(`${url}/category/all`);
            setCategories(categoryRes.data.data);

            const brandRes = await axios.get(`${url}/brand/all`);
            setBrands(brandRes.data.data.result);
        }
        fetchData();
    }, [pageState.page, pageState.pageSize, refurbishedProduct, filters, productIdFromOrder]);

    const MatEdit = ({ index }) => {
        const handleEditClick = (e) => {
            setOpenEditPopup(e.currentTarget)
            setRowId(index)
        }
        return <FormControlLabel
            control={
                <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
                    <MoreVert style={{ color: "white" }} />
                </IconButton>
            }
        />
    };

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No.',
            filterable: false,
            flex: 1
        },
        {
            field: 'shortName',
            headerName: 'Name',
            width: 250,
            valueGetter: (params) => sentenceCase(params.row.shortName),
            flex: 1
        },

        {
            field: 'categoryName',
            headerName: 'Category',
            width: 120,
            valueGetter: (params) => params.row.categoryDocs[0].name,
            flex: 1
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 120,
            valueGetter: (params) =>
                params.row.brandDocs[0].name,
            flex: 1

        },
        {
            field: 'isBlocked',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => <Paper sx={{ width: 80, fontSize: "12px", bgcolor: `${params.row.isBlocked === true ? "#5c0000" : "#155c00"}`, textAlign: "center" }}>{params.row.isBlocked === true ? "Blocked" : "Active"}</Paper>,
            flex: 1

        },
        {
            field: 'stock',
            headerName: 'InStock',
            width: 90,
            flex: 1
        },
        {
            field: 'createdAt', headerName: 'Date Created', width: 110,
            valueFormatter: params =>
                moment(params?.value).format("DD/MM/YYYY"),
            flex: 1
        },
        {
            field: 'images',
            headerName: 'Image',
            headerAlign: 'center',
            width: 120,
            renderCell: (params) =>
                <Box
                    component="img"
                    sx={{
                        height: 60,
                        objectFit: "contain",
                        width: 80,
                    }}
                    alt=<Image />
                    src={params.row.images[params.row.images.length - 1].fileUrl ? params.row.images[params.row.images.length - 1].fileUrl : null}
                />,
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: "center",
            align: 'center',
            width: 60,
            renderCell: (params) =>
            (
                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                    <MatEdit index={params.row._id} />
                </div>
            ),

            flex: 1
        },
    ];

    const rows = pageState.data.map((result, index) => (
        {
            "slno": pageState.page * pageState.pageSize + index + 1,
            ...result
        }))


    const handleEditBtnClick = () => {
        navigate(`/dashboard/products/${rowId}`)
    }

    const handleSearch = async () => {
        const res = await axios.get(`${url}/products/get_all?name=${searchValue}`)
        setPageState((old) => ({ ...old, page: 0, data: res.data.data.result }))
    }

    const handleFilterBoxOpen = async (event) => {
        setEditPopupOpen(event.currentTarget);
        const res = await axios.get(`${url}/offer/all`)
        setOfferData(res.data.data.offers)
    };

    const handleFilterBoxClose = () => {
        setEditPopupOpen(null);
        setBrandFilter("");
        setCatFilter("");
        setStockFilter(null);
        setStatusFilter("")
        setOfferFilter("")
    };

    const filterOpen = Boolean(editPopupOpen);
    const id = filterOpen ? 'simple-popover' : undefined;


    const handleFilterSubmit = async (e) => {
        e.preventDefault();
        setFilters((old) => ({ ...old, brand: brandFilter, status: statusFilter, stock: stockFilter, cat: catFilter, offer: offerFilter }))
        handleFilterBoxClose();
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    return (
        <>
            <Helmet>
                <title> Dashboard | Products</title>
            </Helmet>
            <Switch classes={{
                track: classes.switch_track,
                switchBase: classes.switch_base,
                colorPrimary: classes.switch_primary,
            }} checked={refurbishedProduct} onChange={(e) => setRefurbishedProduct(e.target.checked)} />
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    {refurbishedProduct ? "Pre-owned Products" : "New Products"}
                </Typography>


                <Link to={"create_product"} style={{ color: "inherit", textDecoration: "none" }}>
                    <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Product
                    </Button>
                </Link>
            </Stack>
            <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                <TextField className={classes.textfiled} onKeyDown={handleSearchKeyDown} onChange={(e) => setSearchValue(e.target.value)} sx={{ mt: 2, width: 450 }} placeholder="Search..." />

                <Tooltip title="Filter">
                    <IconButton onClick={handleFilterBoxOpen}> <FilterList sx={{ color: "white" }} /></IconButton>
                </Tooltip>

            </Stack>
            {/* FILTER BOX */}
            <Popover
                elevation={2}
                id={id}
                open={filterOpen}
                anchorEl={editPopupOpen}
                onClose={handleFilterBoxClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <form onSubmit={handleFilterSubmit}>
                    <Stack p={3} width={400} spacing={1} >
                        <Typography variant="h5">Filter by</Typography>

                        <Autocomplete
                            className={classes.textfiled}
                            size="small"
                            id="combo-box-demo"
                            options={brands}
                            getOptionLabel={(brand) => brand.name}
                            noOptionsText={"No available Brands"}
                            onChange={(event, newValue) => {
                                setBrandFilter(newValue._id)
                            }}
                            renderInput={(params) => <TextField {...params} label="Brand name" />}
                        />

                        <Autocomplete
                            className={classes.textfiled}
                            size="small"
                            id="combo-box-demo"
                            options={categories}
                            getOptionLabel={(cat) => cat.name}
                            noOptionsText={"No available categories"}
                            onChange={(event, newValue) => {
                                setCatFilter(newValue._id)
                            }}
                            renderInput={(params) => <TextField {...params} label="Categories" />}
                        />

                        <Stack direction={"row"} spacing={1}  >
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={statusFilter}
                                    label="Status"
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    <MenuItem value={"false"}>Active</MenuItem>
                                    <MenuItem value={"true"}>Blocked</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Stock</InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={stockFilter}
                                    label="stock"
                                    onChange={(e) => setStockFilter(e.target.value)}
                                >
                                    <MenuItem value={"1"}>In Stock</MenuItem>
                                    <MenuItem value={"0"}>Out of Stock</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <FormControl fullWidth className={classes.textfiled}>
                            <InputLabel id="demo-simple-select-label">Offer</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={offerFilter}
                                label="stock"
                                onChange={(e) => setOfferFilter(e.target.value)}
                            >
                                {
                                    offerData.map((offer) => (
                                        <MenuItem value={offer._id}>{offer.title}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} fullWidth variant="contained">Filter</Button>
                    </Stack>
                </form>
            </Popover>

            <Stack mt={4} sx={{ height: 500, width: '100%' }}>
                <Paper sx={{ p: 2 }}>
                    <DataGrid
                        sx={{
                            "& .MuiDataGrid-columnSeparator": {
                                color: "#454545"
                            }
                        }}
                        columnsp
                        getRowId={(row) => row.slno}
                        autoHeight
                        rows={rows}
                        rowCount={pageState.total}
                        loading={isLoading}
                        rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                        pagination
                        page={pageState.page}
                        pageSize={pageState.pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            setPageState((old) => ({ ...old, page: newPage }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                    />
                </Paper>

            </Stack>

            <Popover
                elevation={2}
                open={Boolean(openEditPopup)}
                anchorEl={openEditPopup}
                onClose={() => setOpenEditPopup(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleEditBtnClick}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
            </Popover>
        </>
    )
}