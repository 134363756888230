import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from "formik";
import { styled } from '@mui/system';
// 
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Snackbar, Stack, TextField, Typography, useMediaQuery, Link as MUILink, Popover, useTheme, TableHead, Modal, Box, Tooltip, Skeleton } from '@mui/material';
import { Add, ArrowBack, ArrowBackIos, Close, Done, Expand, ExpandMore as ExpandIcon, MonetizationOn, More, MoreVert } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { fCurrency } from '../../utils/formatNumber';
// componenets
import OrderAddress from './OrderAddress';
import OrderUserDetails from './OrderUserDetails';
import OrderDetails from './OrderDetails';
import { orderPackageIdManageSchema } from '../../validations/orderManage';
import Iconify from '../iconify/Iconify';
// -------------------------------IMPORTS END -----------------------------------//
const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    menuList: {
        elevation: 0,
    },
});
const url = process.env.REACT_APP_API_URL;

function Order() {

    const [shippingAdd, setShippingAdd] = useState({});

    const [billingAdd, setBillingAdd] = useState({});

    const [user, setUser] = useState({});

    const [invoId, setInvoId] = useState("");

    const [shippingStatus, setShippingStatus] = useState('');

    const [replacementStatus, setReplacementStatus] = useState("");

    const [returnStatus, setReturnStatus] = useState("");

    const [invoiceNotHavingProducts, setInvoiceNotHavingProducts] = useState([]);

    const [invoiceNumber, setInvoiceNumber] = useState("");

    const [invoiceFile, setInvoiceFile] = useState("");

    const [invoiceIds, setInvoiceIds] = useState([]);

    const [dialogOpen, setDilogOpen] = useState(false);

    const [invoiceFiles, setInvoiceFiles] = useState([]);

    const [cancelComment, setCancelComment] = useState("");

    const [expanded, setExpanded] = useState(false);

    const [returnDialogOpen, setReturnDialogOpen] = useState(false);

    const [returnComment, setReturnComment] = useState("");

    const [returnReason, setReturnReason] = useState("");

    const [refundAmount, setRefundAmount] = useState(0);

    const [replacementComment, setReplacementComment] = useState("");

    const [replacementReason, setReplacementReason] = useState("");

    const [replacementDialogOpen, setReplacementDialogOpen] = useState(false);

    const [refreshPage, setRefreshPage] = useState(false);

    const [invoiceOptionOpen, setInvoiceOptionOpen] = useState(false);

    const [orderDoc, setOrderDoc] = useState({});

    const [statusCount, setStatusCount] = useState({});

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [openInvoiceAddModal, setOpenInvoiceAddModal] = useState(false);

    const [addInvoiceNumber, setAddInvoiceNumber] = useState("");

    const [addInvoiceIds, setAddInvoiceIds] = useState([]);

    const [invoiceData, setInvoiceData] = useState([]);

    const [addInvoiceFile, setAddInvoiceFile] = useState("");

    const [dateOfDelivery, setDateOfDelivery] = useState("");

    const [filteredProducts, setFilteredProducts] = useState([]);

    const [orders, setOrders] = useState([]);

    const [refundStatus, setRefundStatus] = useState("");

    const [refundDialogOpen, setRefundDialogOpen] = useState(false);

    const [refundComment, setRefundComment] = useState("");

    const [refundDate, setRefundDate] = useState("");

    const [refund, setRefund] = useState("");

    const [paymentStatus, setPaymentStatus] = useState("")

    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

    const [paymentRemark, setPaymentRemark] = useState("")


    const [orderData, setOrderData] = useState({
        orderId: "",
        date: "",
        paymentMode: "",
        paymentStatus: "",
        shippingCharge: 0,
        totalSellingPrice: 0,
        totalRefundAmount: 0,
        replacementReference: {},
        replacementOrder: {},
    });

    const [pageLoadiing, setPageLoadiing] = useState(false);

    const formData = new FormData();
    const { orderId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigate();

    const initialValues = {
        courierId: "",
        invoiceNumber: ""
    };

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: orderPackageIdManageSchema,
        onSubmit: (values, { resetForm }) => {
            formData.append("invoiceNumber", values.invoiceNumber);
            formData.append("courierId", values.courierId);
            handlePackageManagementForm();
            resetForm();
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            setPageLoadiing(true);
            try {

                const res = await axios.get(`${url}/order?orderId=${orderId}`);
                setOrderDoc(res.data.data);
                setFilteredProducts(res.data.data.invoices);
                setOrders(res.data.data.invoices);
                setShippingAdd(res.data.data.shippingAddress);
                setBillingAdd(res.data.data.billingAddress);
                setUser(res.data.data.userId);
                setRefund(res.data.data.refundAmount);
                setOrderData((old) => ({
                    ...old, orderId: res.data.data.orderId,
                    date: res.data.data.createdAt,
                    paymentMode: res.data.data.paymentMode,
                    paymentStatus: res.data.data.paymentStatus,
                    shippingCharge: res.data.data.shippingCharge || 0,
                    totalSellingPrice: res.data.data.invoices.reduce((a, b) => a + b.sellingPrice, 0),
                    totalRefundAmount: res.data.data.invoices.reduce((a, b) => a + b.refundAmount || 0, 0),
                    replacementReference: res.data.data.replacementReference,
                    replacementOrder: res.data.data.replacementOrder
                }));
                setStatusCount(res.data.data.counts);

                setInvoiceFiles(res.data.data.invoiceFiles);

                const invoiceNotHavingRes = await axios.get(`${url}/order/without-invoicenumber?orderId=${orderId}`);
                setInvoiceNotHavingProducts(invoiceNotHavingRes.data.data);
                setPageLoadiing(false);
            } catch (e) {
                console.log(e); 
                setPageLoadiing(false);
            }
        };
        fetchData();
    }, [orderId, dialogOpen, returnDialogOpen, refreshPage, replacementDialogOpen, openInvoiceAddModal, deleteDialogOpen, refundDialogOpen, paymentDialogOpen]);

    const handleChangetag = (event) => {
        const {
            target: { value },
        } = event;
        setInvoiceIds(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeAddInvoiceProducts = (event) => {
        const {
            target: { value },
        } = event;
        setAddInvoiceIds(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <Stack {...other} />;
    })(({ theme, expand }) => ({
        textAlign: "center",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));


    const handleExpandClick = (id) => {
        setExpanded(expanded => ({
            ...expanded,
            [id]: !expanded[id],
        }));
    };

    // 
    const handlePackageManagementForm = async () => {
        try {
            formData.append("invoiceIds", JSON.stringify(invoiceIds));
            formData.append("invoice", invoiceFile);
            formData.append("orderId", orderId);
            const res = await axios.put(`${url}/order/add-invoicenumber`, formData);
            console.log(res.data.data);
            enqueueSnackbar("Successfully submited!", { variant: 'success' });
            setInvoiceIds([]);
            setInvoiceFile("");
            setRefreshPage(true);
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
            console.log(e);
        }
    };

    const shippingStatusHandler = async (e) => {
        setShippingStatus(e.target.value);
        setDilogOpen(true);
    };

    const paymentStatusHandler = async (e) => {
        setPaymentStatus(e.target.value);
        setPaymentDialogOpen(true);
    }

    const handleShippingStatusDialog = async () => {
        try {
            await axios.put(`${url}/order/shipping-status`, {
                orderId, shippingStatus, invoiceId: invoId, cancelComment, deliveryDate: dateOfDelivery
            });
            enqueueSnackbar("Shipping status updated!", { variant: 'success' });
            setDilogOpen(false);
            setShippingStatus("");
            setInvoId("");
        } catch (e) {
            console.log(e);
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
        }
    };
    const retrunStatusHandle = (e) => {
        setReturnStatus(e.target.value);
        setReturnDialogOpen(true);
    };


    const handlePaymentStatusDialog = async () => {
        try {
            await axios.put(`${url}/order/update-payment-status`, {
                orderId, paymentStatus, invoiceId: invoId, paymentRemark
            });
            enqueueSnackbar("Payment status updated!", { variant: 'success' });
            setPaymentDialogOpen(false);
            setPaymentStatus("");
            setInvoId("");
        } catch (e) {
            console.log(e);
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
        }
    }

    const handleReturnStatusDialog = async () => {
        try {
            await axios.post(`${url}/order/respond-return`, {
                orderId,
                invoiceId: invoId,
                returnStatus,
                returnReason,
                returnComment,
                refundAmount
            });
            enqueueSnackbar("Return status updated!", { variant: 'success' });
            setReturnDialogOpen(false);
            setInvoId("");
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
            console.log(e);
        }
    };

    const replacementStatusHandle = (e) => {
        setReplacementStatus(e.target.value);
        setReplacementDialogOpen(true);
    };

    const handleReplacementStatusDialog = async () => {
        try {
            await axios.post(`${url}/order/respond-replacement`, {
                orderId,
                invoiceId: invoId,
                replacementStatus,
                replacementComment,
                replacementReason
            });
            enqueueSnackbar("Replacement status updated!", { variant: 'success' });
            setReplacementDialogOpen(false);
            setInvoId("");
            setReplacementStatus("");
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
            console.log(e);
        }
    };

    const refundStatusHandle = (e) => {
        setRefundStatus(e.target.value);
        setRefundDialogOpen(true);
    };


    const handleRefundDialog = async () => {
        try {
            await axios.put(`${url}/order/update-refund-status`, {
                orderId,
                invoiceId: invoId,
                refundStatus,
                refundComment,
                refundDate,
                refundAmount,
            });
            enqueueSnackbar("Refund status updated!", { variant: 'success' });
            setRefundDialogOpen(false);
            setInvoId("");
            setRefundStatus("");
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
            console.log(e);
        }
    };

    const handleInvoiceDelete = async () => {
        try {
            await axios.post(`${url}/order/delete-invoice`, {
                orderId,
                invoiceNumber,
            });
            setRefreshPage(true);
            enqueueSnackbar("Successfully Deleted!", { variant: 'success' });
            setDeleteDialogOpen(false);
            setInvoiceOptionOpen(false);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteInvoceClose = () => {
        setDeleteDialogOpen(false);
        setInvoiceOptionOpen(false);
    };

    const hancdleAddInvoiceModalOpen = async () => {
        setOpenInvoiceAddModal(true);
        const res = await axios.get(`${url}/order/invoices?orderId=${orderId}`);
        setInvoiceData(res.data.data);
    };

    const handleAddInvoiceModalSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.append("orderId", orderId);
            formData.append("invoiceIds", JSON.stringify(addInvoiceIds));
            formData.append("invoiceNumber", addInvoiceNumber);
            formData.append("invoice", addInvoiceFile);
            const res = await axios.put(`${url}/order/upload-invoice`, formData);
            console.log(res.data.data);
            enqueueSnackbar("Successfully added!", { variant: 'success' });
            setOpenInvoiceAddModal(false);
            setAddInvoiceNumber("");
            setAddInvoiceIds([]);
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
            console.log(e);
        }
    };

    const handleSearch = (event) => {
        const filteredList = orders.filter((product) =>
            product.productName.toLowerCase().includes(event.target.value.toLowerCase()) ||
            product.invoiceNumber?.toLowerCase().includes(event.target.value.toLowerCase()) ||
            product.courierId?.toLowerCase().includes(event.target.value.toLowerCase()) ||
            product.productSkuId.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredProducts(filteredList);
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };
    return (
        <>
            <Stack direction={"row"}>
                <IconButton onClick={() => navigate("/dashboard/manage_orders")}>
                    <ArrowBack sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h4">
                    {`Order Details - ${JSON.stringify(orderDoc.replacementReference) !== '{}' ? "Replacement" : "Normal"}`}
                </Typography>

            </Stack>
            {/* STATUS BAR */}
            {!pageLoadiing ?
                <Accordion sx={{ mt: 3 }} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h5'>Order Status</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack>
                            <Paper sx={{ p: 3, bgcolor: "#121212", }}>
                                <Stack direction={"row"} spacing={3} mt={1} alignItems={"center"} justifyContent={"space-evenly"}>
                                    {
                                        JSON.stringify(orderData.replacementReference) !== '{}' &&
                                        <Paper sx={{ width: 400, height: 155 }}>
                                            <Typography p={1} variant='h6'>Approvals</Typography>
                                            <Stack direction={"row"} p={1}>
                                                <Stack width={120} sx={{ borderRight: "1px solid" }}>
                                                    <Typography>Await Approval</Typography>
                                                    <Typography>Approved</Typography>
                                                    <Typography>Rejected</Typography>
                                                </Stack>
                                                <Stack ml={2}>
                                                    <Typography>{statusCount.awaitApproval}</Typography>
                                                    <Typography>{statusCount.approved}</Typography>
                                                    <Typography>{statusCount.rejected}</Typography>
                                                </Stack>

                                            </Stack>
                                        </Paper>
                                    }
                                    <Paper sx={{ width: 400, height: 155 }}>
                                        <Typography p={1} variant='h6'>Shipping</Typography>
                                        <Stack direction={"row"} p={1}>
                                            <Stack width={120} sx={{ borderRight: "1px solid black" }}>
                                                <Typography>Pending</Typography>
                                                <Typography>Shipped</Typography>
                                                <Typography>Delivered</Typography>
                                                <Typography>Canceled</Typography>
                                            </Stack>
                                            <Stack ml={2}>
                                                <Typography>{statusCount.shippingPending}</Typography>
                                                <Typography>{statusCount.shipped}</Typography>
                                                <Typography>{statusCount.delivered}</Typography>
                                                <Typography>{statusCount.cancelled}</Typography>
                                            </Stack>

                                        </Stack>
                                    </Paper>
                                    <Paper sx={{ width: 400, height: 155, }}>
                                        <Typography p={1} variant='h6'>Return</Typography>
                                        <Stack direction={"row"} p={1}>
                                            <Stack width={120} sx={{ borderRight: "1px solid black" }}>
                                                <Typography>Pending</Typography>
                                                <Typography>Approved</Typography>
                                                <Typography>Rejected</Typography>
                                            </Stack>
                                            <Stack ml={2}>
                                                <Typography>{statusCount.returnPending}</Typography>
                                                <Typography>{statusCount.returnApproved}</Typography>
                                                <Typography>{statusCount.returnRejected}</Typography>
                                            </Stack>

                                        </Stack>
                                    </Paper>
                                    <Paper sx={{ width: 400, height: 155 }}>
                                        <Typography p={1} variant='h6'>Replacement</Typography>
                                        <Stack direction={"row"} p={1}>
                                            <Stack width={120} sx={{ borderRight: "1px solid black" }}>
                                                <Typography>Pending</Typography>
                                                <Typography>Approved</Typography>
                                                <Typography>Rejected</Typography>
                                            </Stack>
                                            <Stack ml={2}>
                                                <Typography>{statusCount.replacementPending}</Typography>
                                                <Typography>{statusCount.replacementApproved}</Typography>
                                                <Typography>{statusCount.replacementRejected}</Typography>
                                            </Stack>

                                        </Stack>
                                    </Paper>

                                </Stack>
                            </Paper>
                        </Stack>
                    </AccordionDetails>
                </Accordion> : (
                    <Accordion sx={{ mt: 3 }} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h5'>Order Status</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Paper sx={{ p: 3, bgcolor: "#121212", }}>
                                    <Stack direction={"row"} spacing={3} mt={1} alignItems={"center"} justifyContent={"space-evenly"}>
                                        <Skeleton sx={{ width: 400, height: "200px" }} />
                                        <Skeleton sx={{ width: 400, height: "200px" }} />
                                        <Skeleton sx={{ width: 400, height: "200px" }} />
                                        <Skeleton sx={{ width: 400, height: "200px" }} />

                                    </Stack>
                                </Paper>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )
            }

            {/* ORDER DETAILS */}
            <Accordion sx={{ mt: 3 }} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5'>Order Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        <OrderDetails order={orderData} />
                    </Stack>
                </AccordionDetails>
            </Accordion>

            {/* USER DETAILS */}
            <Accordion sx={{ mt: 3 }}>
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5'>User Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack >
                        <OrderUserDetails userId={user._id} userName={user.fullname} userEmail={user.email} />
                    </Stack>
                    <Stack mt={3}>
                        <OrderAddress shippingAddress={shippingAdd} billingAddress={billingAdd} />
                    </Stack>
                </AccordionDetails>
            </Accordion>

            {/* PACKAGE MANAGEMENT */}
            <Accordion sx={{ mt: 3 }}>
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5'>Package Management</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={3}>
                        <Paper sx={{ p: 2, bgcolor: "#121212" }} >
                            <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                                <Stack direction={"row"} spacing={3} justifyContent={"center"} mt={1}>

                                    <Stack spacing={2} width={450} >
                                        <TextField
                                            className={classes.textfiled}
                                            value={values.courierId}
                                            name='courierId'
                                            label="Courier ID"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.courierId && touched.courierId ? <Typography color={"error"} variant={"body2"}>{errors.courierId}</Typography> : null}
                                        <TextField
                                            className={classes.textfiled}
                                            value={values.invoiceNumber}
                                            name='invoiceNumber'
                                            label="Invoice Number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.invoiceNumber && touched.invoiceNumber ? <Typography color={"error"} variant={"body2"}>{errors.invoiceNumber}</Typography> : null}
                                    </Stack>

                                    <Stack spacing={4} width={450}>
                                        <FormControl className={classes.textfiled}>
                                            <InputLabel id="demo-multiple-checkbox-label">Products</InputLabel>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={invoiceIds}
                                                onChange={handleChangetag}
                                                input={<OutlinedInput label="products" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {invoiceNotHavingProducts.map((invoice) => (
                                                    <MenuItem key={invoice.invoiceId} value={invoice.invoiceId}>
                                                        <Checkbox checked={invoiceIds.indexOf(invoice.invoiceId) > -1} />
                                                        <ListItemText primary={invoice.productName} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <label htmlFor="file">Choose Invoice  <input onChange={(e) => setInvoiceFile(e.target.files[0])} type="file" name="file" id="file" />
                                        </label>


                                    </Stack>
                                </Stack>
                                <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, width: 400 }} disabled={invoiceNotHavingProducts.length === 0 && true} type='submit' variant='contained' >Submit</Button>
                            </form>
                        </Paper>
                    </Stack>

                </AccordionDetails>
            </Accordion>

            {/* PRODUCTS */}
            <Accordion sx={{ mt: 3, }} defaultExpanded    >
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5'>Products</Typography>
                </AccordionSummary>
                <TextField placeholder='Search...' sx={{ width: 500, ml: 3, mb: 2 }} className={classes.textfiled} onChange={handleSearch} />
                <AccordionDetails>
                    <Stack>
                        <Paper sx={{ p: 3, bgcolor: "#121212" }}>
                            <Grid container spacing={3} mt={0.5}>
                                {filteredProducts.map((order) => {
                                    return <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {!pageLoadiing ? <Card sx={{ boxShadow: "none" }}>
                                            <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} mx={2}>
                                                <Link to={`/dashboard/products/${order.productId}`} style={{ color: "inherit", textDecoration: "none" }}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar variant='square' sx={{ bgcolor: "#121212", width: 70, height: 70, }} alt="Remy Sharp" src={order.image} />
                                                        }
                                                        title={order.productName}
                                                        subheader={`Quantity : ${order.quantity}`}
                                                    />
                                                </Link>
                                                <Stack mr={5} direction={"row"} spacing={2} alignItems={"center"}>
                                                    {
                                                        order.returnStatus === "PENDING" ?
                                                            <Tooltip title={"RETURN PENDING"}>
                                                                <Stack sx={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    borderRadius: "50%",
                                                                    bgcolor: "#f75325"
                                                                }} />
                                                            </Tooltip>
                                                            : ""
                                                    }
                                                    {
                                                        order.replacementStatus === "PENDING" ?
                                                            <Tooltip title={"REPLACEMENT PENDING"}>
                                                                <Stack sx={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    borderRadius: "50%",
                                                                    bgcolor: "yellow"
                                                                }} />
                                                            </Tooltip>
                                                            : ""
                                                    }
                                                    {
                                                        order.refundStatus === "PENDING" ?
                                                            <Tooltip title={"REFUND PENDING"}>
                                                                <MonetizationOn sx={{
                                                                    fontSize: "21px",
                                                                    color: "#27C700"
                                                                }} />
                                                            </Tooltip> : ""
                                                    }
                                                    {
                                                        order.returnStatus === "APPROVED" || order.refundStatus === "APPROVED" || order.replacementStatus === "APPROVED" ?
                                                            <Tooltip title={"APPROVED"}>
                                                                <Stack>
                                                                    <Done sx={{ fontSize: 22, color: "#27C700" }} />
                                                                </Stack>
                                                            </Tooltip> : ""
                                                    }
                                                    {
                                                        order.returnStatus === "REJECTED" || order.refundStatus === "REJECTED" || order.replacementStatus === "REJECTED" ?
                                                            <Tooltip title={"REJECTED"}>
                                                                <Stack>
                                                                    <Close sx={{ fontSize: 22, color: "#E11F1C" }} />
                                                                </Stack>
                                                            </Tooltip> : ""
                                                    }
                                                </Stack>
                                            </Stack>
                                            <CardContent>
                                                <Grid container>
                                                    {/* DETAILS */}
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Typography variant='h6'>Details</Typography>
                                                        <Stack direction={"row"}>
                                                            <Stack spacing={1}>
                                                                <Typography variant="body2">Courier ID</Typography>
                                                                <Typography variant="body2">SKUID</Typography>
                                                                <Typography variant="body2">Invoice Number</Typography>
                                                                <Typography variant="body2">Pyment Mode</Typography>
                                                                <Typography variant="body2">Pyment Status</Typography>
                                                                <Typography variant="body2">Selling Price</Typography>
                                                               {
                                                                order.paymentRemark &&  <Typography variant="body2">Payment Remark</Typography>
                                                               }
                                                                {
                                                                    order.refundAmount ?
                                                                        <Typography variant="body2">Refund Amount</Typography>
                                                                        : ""
                                                                }

                                                            </Stack>
                                                            <Stack ml={3} spacing={1}>

                                                                <Typography variant="body2" >
                                                                    {order.courierId || "NA"}
                                                                </Typography>
                                                                <Typography variant="body2" >
                                                                    {order.productSkuId || "NA"}
                                                                </Typography>
                                                                <Typography variant="body2" >
                                                                    {order.invoiceNumber ? order.invoiceNumber : "NA"}
                                                                </Typography>

                                                                <Typography variant="body2" >
                                                                    {order.paymentMode}
                                                                </Typography>
                                                                <Typography variant="body2" >
                                                                    {order.paymentStatus}
                                                                </Typography>
                                                                <Typography variant="body2" >
                                                                    {`₹ ${fCurrency(order.sellingPrice)}`}
                                                                </Typography>
                                                                {
                                                                    order.refundAmount ?
                                                                        <Typography variant="body2">₹ {fCurrency(order.refundAmount)}</Typography>
                                                                        : ""
                                                                }
                                                                {
                                                                    order.paymentRemark && <Typography variant="body2">{order.paymentRemark}</Typography>
                                                                }
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Stack spacing={2}>
                                                            <Typography variant='h6'>Status</Typography>


                                                            {orderDoc.paymentMode === "COD" && <FormControl fullWidth className={classes.textfiled}>
                                                                <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                                                                <Select
                                                                    className={classes.menuList}
                                                                    disabled={order.replacementStatus !== "NA" || order.returnStatus !== "NA"}
                                                                    onClick={() => setInvoId(order._id)}
                                                                    size='small'
                                                                    id='samual john'
                                                                    labelId="demo-simple-select-label"
                                                                    value={order.paymentStatus}
                                                                    label="shipping status"
                                                                    onChange={(e) => { setInvoId(order._id); paymentStatusHandler(e); }}
                                                                >
                                                               
                                                                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                                    <MenuItem value={"COMPLETED"}>COMPLETED</MenuItem>
                                                                  
                                                                </Select>
                                                            </FormControl>}


                                                            <FormControl fullWidth className={classes.textfiled}>
                                                                <InputLabel id="demo-simple-select-label">Shipping Status</InputLabel>
                                                                <Select
                                                                    className={classes.menuList}
                                                                    disabled={order.replacementStatus !== "NA" || order.returnStatus !== "NA"}
                                                                    onClick={() => setInvoId(order._id)}
                                                                    size='small'
                                                                    id='samual john'
                                                                    labelId="demo-simple-select-label"
                                                                    value={order.shippingStatus}
                                                                    label="shipping status"
                                                                    onChange={(e) => { setInvoId(order._id); shippingStatusHandler(e); }}
                                                                >
                                                                    <MenuItem value={"NA"}>NA</MenuItem>
                                                                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                                    <MenuItem value={"SHIPPED"}>SHIPPED</MenuItem>
                                                                    <MenuItem value={"DELIVERED"}>DELIVERED</MenuItem>
                                                                    <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <FormControl fullWidth className={classes.textfiled}>
                                                                <InputLabel id="demo-simple-select-label">Replacement Status</InputLabel>
                                                                <Select
                                                                    disabled={order.returnStatus !== "NA"}
                                                                    size='small'
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={order.replacementStatus}
                                                                    label="replacement status"
                                                                    onChange={(e) => { setInvoId(order._id); replacementStatusHandle(e); }}
                                                                >
                                                                    <MenuItem value={"NA"}>NA</MenuItem>
                                                                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                                    <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                                                    <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <FormControl fullWidth className={classes.textfiled}>
                                                                <InputLabel id="demo-simple-select-label">Return Status</InputLabel>
                                                                <Select
                                                                    disabled={order.replacementStatus !== "NA"}
                                                                    onClick={() => setInvoId(order._id)}
                                                                    size='small'
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={order.returnStatus}
                                                                    label="Return status"
                                                                    onChange={retrunStatusHandle}
                                                                >
                                                                    <MenuItem value={"NA"}>NA</MenuItem>
                                                                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                                    <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                                                    <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            {order.refundStatus !== "NA" &&
                                                                <FormControl fullWidth className={classes.textfiled}>
                                                                    <InputLabel id="demo-simple-select-label">Refund Status</InputLabel>
                                                                    <Select
                                                                        disabled={order.refundStatus === "NA"}
                                                                        onClick={() => setInvoId(order._id)}
                                                                        size='small'
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={order.refundStatus}
                                                                        label="Return status"
                                                                        onChange={refundStatusHandle}
                                                                    >
                                                                        <MenuItem value={"NA"}>NA</MenuItem>
                                                                        <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                                                        <MenuItem onClick={() => setRefundAmount(order.sellingPrice)} value={"APPROVED"}>APPROVED</MenuItem>
                                                                        <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            }
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Typography ml={2} variant='h6'>Date</Typography>
                                                        <Stack direction={"row"} mt={2}>
                                                            <Stack ml={2} spacing={2}>
                                                                <Typography>Delivery Date</Typography>
                                                                <Typography>Replacement Date</Typography>
                                                                <Typography>Return Date</Typography>
                                                                {
                                                                    order.refundDate &&
                                                                    <Typography>Refund Date</Typography>
                                                                }
                                                            </Stack>
                                                            <Stack ml={3} spacing={2}>
                                                                <Typography>{order.deliveryDate ? moment(order.deliveryDate).format("DD-MM-YYYY") : "NA"}</Typography>
                                                                <Typography>{order.replacementDate ? moment(order.replacementDate).format("DD-MM-YYYY") : "NA"}</Typography>
                                                                <Typography>{order.returnDate ? moment(order.returnDate).format("DD-MM-YYYY") : "NA"}</Typography>
                                                                {
                                                                    order.refundDate &&
                                                                    <Typography>{order.refundDate ? moment(order.refundDate).format("DD-MM-YYYY") : "NA"}</Typography>
                                                                }
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>

                                            <CardActions sx={{ justifyContent: "center" }}>
                                                <ExpandMore
                                                    expand={expanded}
                                                    onClick={() => handleExpandClick(order._id)}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                >
                                                    <Button sx={{ color: "#01ffff" }}>Expand More <ExpandIcon /></Button>
                                                </ExpandMore>
                                            </CardActions>
                                            <Collapse in={expanded[order._id]} timeout="auto" unmountOnExit>
                                                <CardContent>
                                                    <Stack direction={"column"} spacing={3}>
                                                        <Stack sx={{ width: "auto" }}>
                                                            <Typography variant='h6'>RETURN</Typography>
                                                            <Typography variant='body2'>
                                                                Reason: {order.returnReason || "NA"}
                                                            </Typography>

                                                            <Typography variant='body2'>
                                                                Message : {order.returnComment || "NA"}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography variant='h6'>
                                                                REPLACEMENT
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                Reason : {order.replacementReason || "NA"}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                Message : {order.replacementComment || "NA"}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </CardContent>
                                            </Collapse>
                                        </Card>
                                            : (
                                                <>
                                                    <Stack spacing={2}>
                                                        <Stack direction={"row"} spacing={2}>
                                                            <Skeleton variant="circular">
                                                                <Avatar />
                                                            </Skeleton>
                                                            <Skeleton width="100%">
                                                                <Typography>.</Typography>
                                                            </Skeleton>
                                                        </Stack>
                                                        <Skeleton variant="rectangular" width="100%" height={300} sx={{ borderRadius: "10px" }} />
                                                    </Stack>
                                                </>
                                            )
                                        }
                                    </Grid>;
                                })}
                            </Grid>
                        </Paper>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            {/* INVOICES */}
            <Stack mt={3}>
                <Paper sx={{ p: 2 }} >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant='h5'>Invoices</Typography>
                        <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant='contained' onClick={hancdleAddInvoiceModalOpen}> <Add /> Add Invoice</Button>
                    </Stack>

                    <Stack mt={1} spacing={2}>

                        <Stack direction={"row"} spacing={2}>
                            {invoiceFiles.map((invoice) => (

                                <Card key={invoice._id} sx={{ width: 245, boxShadow: "none", bgcolor: "#121212" }}>
                                    <MUILink color={"inherit"} underline="hover" sx={{ "&:hover": { cursor: "pointer" } }} onClick={() => window.open(invoice.fileUrl)}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={invoice.fileUrl}
                                            alt="Invoice image"
                                        />
                                        <CardContent sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {invoice.invoiceNumber}
                                            </Typography>
                                            <IconButton onClick={(e) => { setInvoiceOptionOpen(e.currentTarget); setInvoiceNumber(invoice.invoiceNumber); }}>
                                                <MoreVert />
                                            </IconButton>
                                        </CardContent>
                                    </MUILink>
                                </Card>
                            ))}
                        </Stack>

                    </Stack>
                </Paper>
            </Stack>

            {/* invoice delete */}
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={deleteDialogOpen}
                onClose={handleDeleteInvoceClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete the invoice ?"}
                </DialogTitle>

                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={handleDeleteInvoceClose}>Cancel</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleInvoiceDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add invoice modal */}
            <Modal
                open={openInvoiceAddModal}
                onClose={() => setOpenInvoiceAddModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Brand
                    </Typography>

                    <form onSubmit={handleAddInvoiceModalSubmit}>
                        <Stack spacing={2} p={1}>
                            <TextField
                                className={classes.textfiled}
                                onChange={(e) => setAddInvoiceNumber(e.target.value)}
                                value={addInvoiceNumber}
                                name='invoiceNumber'
                                label="Invoice Number"
                            />
                            <FormControl className={classes.textfiled}>
                                <InputLabel id="demo-multiple-checkbox-label">Products</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={addInvoiceIds}
                                    onChange={handleChangeAddInvoiceProducts}
                                    input={<OutlinedInput label="products" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {invoiceData.map((invoice) => (
                                        <MenuItem key={invoice.invoiceId} value={invoice.invoiceId}>
                                            <Checkbox checked={addInvoiceIds.indexOf(invoice.invoiceId) > -1} />
                                            <ListItemText primary={invoice.productName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <label htmlFor="file">Choose Invoice  <input onChange={(e) => setAddInvoiceFile(e.target.files[0])} type="file" name="file" id="file" />
                            </label>

                            <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} type='submit' variant='contained' fullWidth>Update</Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
            {/* SHIPPING */}
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={dialogOpen}
                onClose={() => setDilogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure do you want to change the status to ${shippingStatus}?`}
                </DialogTitle>
                {shippingStatus === "CANCELED" &&

                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Cancel Comment"
                            onChange={(e) => setCancelComment(e.target.value)}
                        />
                    </DialogContent>
                }
                {shippingStatus === "DELIVERED" &&
                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            InputLabelProps={{ shrink: true }}
                            type={"date"}
                            required
                            fullWidth
                            label="Delivery Date"
                            onChange={(e) => setDateOfDelivery(e.target.value)}
                        />
                    </DialogContent>
                }
                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={() => setDilogOpen(false)}>Close</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleShippingStatusDialog} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Payment */}
             {/* SHIPPING */}
             <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={paymentDialogOpen}
                onClose={() => setPaymentDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure do you want to change the payment status?`}
                </DialogTitle>

                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Payment Remark"
                            onChange={(e) => setPaymentRemark(e.target.value)}
                        />
                    </DialogContent>
               
                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={() => setPaymentDialogOpen(false)}>Close</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handlePaymentStatusDialog} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* RETURN */}
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={returnDialogOpen}
                onClose={() => setReturnDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure do you want to change the status to ${returnStatus}?`}
                </DialogTitle>
                {returnStatus === "REJECTED" &&

                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Return Comment"
                            onChange={(e) => setReturnComment(e.target.value)}
                        />
                    </DialogContent>
                }
                {returnStatus === "PENDING" &&
                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Return Reason"
                            onChange={(e) => setReturnReason(e.target.value)}
                        />
                    </DialogContent>
                }
                {/* {returnStatus === "APPROVED" &&
                    <DialogContent sx={{ p: 2 }}>
                        <FormControl fullWidth sx={{ mt: 2 }} className={classes.textfiled}>
                            <InputLabel htmlFor="outlined-adornment-amount">Refund Amount</InputLabel>
                            <OutlinedInput
                                value={refundAmount}
                                onChange={(e) => setRefundAmount(e.target.value)}
                                type='number'
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                label="refund amount"
                            />
                        </FormControl>
                    </DialogContent>
                } */}

                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={() => setReturnDialogOpen(false)}>Close</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleReturnStatusDialog} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* REPLACE */}
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={replacementDialogOpen}
                onClose={() => setReplacementDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure do you want to change the status to ${replacementStatus}?`}
                </DialogTitle>
                {replacementStatus === "REJECTED" &&

                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Replacement Comment"
                            onChange={(e) => setReplacementComment(e.target.value)}
                        />
                    </DialogContent>
                }
                {replacementStatus === "PENDING" &&
                    <DialogContent sx={{ p: 2 }}>
                        <TextField sx={{ mt: 1 }}
                            className={classes.textfiled}
                            fullWidth
                            label="Replacement Reason"
                            onChange={(e) => setReplacementReason(e.target.value)}
                        />
                    </DialogContent>
                }
                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={() => setReplacementDialogOpen(false)}>Close</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleReplacementStatusDialog} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* REFUND */}
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                open={refundDialogOpen}
                onClose={() => setRefundDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure do you want to change the status to ${refundStatus}?`}
                </DialogTitle>

                {refundStatus === "APPROVED" &&
                    <>
                        <DialogContent sx={{ p: 2 }}>
                            <TextField sx={{ mt: 1 }}
                                className={classes.textfiled}
                                fullWidth
                                label="Refund Comment"
                                onChange={(e) => setRefundComment(e.target.value)}
                            />
                            <TextField sx={{ mt: 1 }}
                                className={classes.textfiled}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label="Refund Date"
                                type="date"
                                onChange={(e) => setRefundDate(e.target.value)}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }} className={classes.textfiled}>
                                <InputLabel htmlFor="outlined-adornment-amount">Refund Amount</InputLabel>
                                <OutlinedInput
                                    value={refundAmount}
                                    onChange={(e) => setRefundAmount(e.target.value)}
                                    type='number'
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                    label="refund amount"
                                />
                            </FormControl>
                        </DialogContent>
                    </>
                }

                <DialogActions>
                    <Button sx={{ color: "#01ffff" }} onClick={() => setRefundDialogOpen(false)}>Close</Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleRefundDialog} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Popover
                elevation={2}
                open={Boolean(invoiceOptionOpen)}
                anchorEl={invoiceOptionOpen}
                onClose={() => setInvoiceOptionOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem sx={{ color: 'error.main' }} onClick={() => setDeleteDialogOpen(true)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>


        </>
    );
}

export default Order;