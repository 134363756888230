import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import { useNavigate } from "react-router-dom"
// @mui
import { alpha } from '@mui/material/styles';
import { AccountCircle, Home, PowerSettingsNew } from '@mui/icons-material';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';

import { logout } from '../../../redux/userSlice';


// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentAdmin } = useSelector((state) => state.user)
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleHomeClick = () => {
    setOpen(null)
    navigate("/dashboard/app")
  };
  const handleProfileClick = () => {
    setOpen(null)
    navigate(`/dashboard/profile/${currentAdmin._id}`)
  };

  const handleLogout = async () => {
    await axios.get(`${url}/auth/admin/logout`);
    // navigate("/login/app")
    dispatch(logout())
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={currentAdmin.profilePic || account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        elevation={2}
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentAdmin.fullname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentAdmin.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleHomeClick} >
            <Home sx={{ fontSize: "18px", mr: "5px" }} />
            <Typography variant='body2'>Home</Typography>
          </MenuItem>
          <MenuItem onClick={handleProfileClick}>
            <AccountCircle sx={{ fontSize: "18px", mr: "5px" }} />
            <Typography variant='body2'>Profile</Typography>
          </MenuItem>

        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, color: "#01ffff" }}>
          <PowerSettingsNew sx={{ fontSize: "20px", mr: "5px" }} />
          <Typography variant='body1' fontSize={14}>Logout</Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
