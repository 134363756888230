import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"

import { MoreVert } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import { Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Popover, Select, Stack, TextField, Typography } from "@mui/material";

import { DataGrid } from '@mui/x-data-grid';
import Iconify from "../components/iconify";

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
});

export default function SubCategoryPage() {


    const { parentCategoryId } = useParams();

    const [results, setResults] = useState([])

    const [parentCategoryName, setParentCategoryName] = useState("")

    const [isBlocked, setIsBlocked] = useState(false);

    const [name, setName] = useState("");

    const [open, setOpen] = useState(false);

    const [errMsg, setErrMsg] = useState("")

    const [openEditPopup, setOpenEditPopup] = useState(false)

    const [openEditModal, setOpenEditModal] = useState(false)

    const [subCategoryInput, setSubcategoryInput] = useState("")

    const [subCategoryIsBlocked, setSubCategoryIsBlocked] = useState(false)

    const [updateError, setUpdateError] = useState("")

    const [rowId, setRowId] = useState("")


    const navigate = useNavigate()
    const classes = useStyles();

    const search = new URLSearchParams(window.location.search);
    const id = search.get('id')

    useEffect(() => {
        const fetchSubCats = async () => {
            try {
                const res = await axios.get(`${url}/sub/category/getall?category=${parentCategoryId}`)
                setResults(res.data.data)

                const categoryRes = await axios.get(`${url}/category/single?id=${parentCategoryId}`)
                setParentCategoryName(categoryRes.data.data.name)

            } catch (e) {
                console.log(e)
            }
        }
        fetchSubCats();
    }, [parentCategoryId, open, openEditModal])


    const createSubcategoryHandler = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${url}/sub/category/create`, {
                parentCategory: parentCategoryId,
                name,
                isBlocked
            })

            setOpen(false)
            setName("")
            setErrMsg("")

        } catch (error) {
            console.log(error)
            setErrMsg(error.response.data.message)
        }
    }

    const updateSubCategoryHandler = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${url}/sub/category/update`, {
                id,
                name: subCategoryInput,
                isBlocked: subCategoryIsBlocked
            })
            setOpenEditModal(false)
            setSubcategoryInput("")
            setSubCategoryIsBlocked(false)
            navigate(`/dashboard/sub/category/${parentCategoryId}`)
        } catch (e) {
            console.log(e)
            setUpdateError(e.response.data.message)
        }
    }

    const handleEditModalOpen = async (id) => {
        try {

            setOpenEditModal(true)
            setOpenEditPopup(false)
            console.log(id)
            navigate(`?id=${id}`)

            const res = await axios.get(`${url}/sub/category/single?id=${id}`);
            setSubcategoryInput(res.data.data.name)
            setSubCategoryIsBlocked(res.data.data.isBlocked)

        } catch (e) {
            console.log(e)
        }

    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const MatEdit = ({ index }) => {
        const handleEditClick = (e) => {
            setOpenEditPopup(e.currentTarget)
            setRowId(index)
        }

        return <FormControlLabel
            control={
                <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick} >
                    <MoreVert style={{ color: "black" }} />
                </IconButton>
            }
        />
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No.',
            filterable: false,
        },
        {
            field: 'name', headerName: 'Name', width: 200,
            valueGetter: (params) => capitalizeFirstLetter(params.row.name)
        },
        { field: '_id', headerName: 'ID', width: 220 },
        {
            field: 'createdAt', headerName: 'Date Created', width: 200,
            valueFormatter: params =>
                moment(params?.value).format("DD/MM/YYYY"),
        },
        {
            field: 'categoryName',
            headerName: 'Category',
            width: 190,
        },
        {
            field: 'isBlocked',
            headerName: 'Status',
            width: 190,
            valueGetter: (params) => params.row.isBlocked === true ? "blocked" : "active"
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) =>
            (
                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                    <MatEdit index={params.row._id} />
                </div>
            )

        },
    ];

    const rows = results.map((result, index) => (
        {
            "slno": index + 1,
            "categoryName": parentCategoryName,
            ...result
        }))

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };

    return (
        <>
            <Helmet>
                <title> Dashboard: Subcategories</title>
            </Helmet>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    Subcategories
                </Typography>
                <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                    New Subcategory
                </Button>
            </Stack>
            {/* CREATE MODAL */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create new Subcategory
                    </Typography>
                    <form onSubmit={createSubcategoryHandler}>
                        <Typography color={"error"} variant="body2">{errMsg}</Typography>
                        <TextField className={classes.textfiled} onChange={(e) => setName(e.target.value)} sx={{ mt: 3 }} fullWidth id="outlined-basic" label="Subcategory name" required variant="outlined" />
                        <Box sx={{ minWidth: 120, mt: 2 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isBlocked}
                                    label="Blocked"
                                    onChange={(e) => setIsBlocked(e.target.value)}
                                >
                                    <MenuItem value={"true"}>Yes</MenuItem>
                                    <MenuItem value={"false"}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button type="submit" sx={{ backgroundColor: "#01ffff", mt: 2, color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Create</Button>
                    </form>
                </Box>
            </Modal>

            {/* EDIT MODAL */}
            <Modal
                open={openEditModal}
                onClose={() => setOpenEditModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Subcategory
                    </Typography>
                    <form onSubmit={updateSubCategoryHandler}>
                        <Typography color={"error"} variant="body2">{updateError}</Typography>
                        <TextField className={classes.textfiled} value={subCategoryInput} onChange={(e) => setSubcategoryInput(e.target.value)} sx={{ mt: 3 }} fullWidth id="outlined-basic" label="Subcategory name" required variant="outlined" />
                        <Box sx={{ minWidth: 120, mt: 2 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subCategoryIsBlocked}
                                    label="Blocked"
                                    onChange={(e) => setSubCategoryIsBlocked(e.target.value)}
                                >
                                    <MenuItem value={"true"}>Yes</MenuItem>
                                    <MenuItem value={"false"}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Update</Button>
                    </form>
                </Box>
            </Modal>

            <Stack mt={10} sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnSeparator": {
                            color: "#454545"
                        }
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10]}
                    getRowId={(row) => row.slno}
                />
            </Stack>

            <Popover
                open={Boolean(openEditPopup)}
                anchorEl={openEditPopup}
                onClose={() => setOpenEditPopup(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => handleEditModalOpen(rowId)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
            </Popover>
        </>
    )
}