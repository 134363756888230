import PropTypes from 'prop-types';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
// @mui
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, Box, Stack, TextField } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

MonthlyOrders.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const useStyles = makeStyles({
  textfiled: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#01FFFF"
    },
    colorScheme: "dark"
  },
});

export default function MonthlyOrders({ title, subheader, chartLabels, chartColors, chartData, onStartDate, onEndDate, startDate, endDate, ...other }) {
  const classes = useStyles();
  const chartOptions = useChart({
    colors: chartColors,
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other} sx={{ boxShadow: "none" }}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction={"row"} mt={2} ml={2} spacing={2}>
        <TextField size='small' className={classes.textfiled} type={"date"} label={"Start Date"} InputLabelProps={{ shrink: true }} value={startDate || moment().subtract(5, 'months').format("YYYY-MM-DD")} onChange={(e) => onStartDate(e.target.value)} />
        <TextField className={classes.textfiled} size='small' type={"date"} label={"End Date"} InputLabelProps={{ shrink: true }} value={endDate || moment().format("YYYY-MM-DD")} onChange={(e) => onEndDate(e.target.value)} />
      </Stack>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
