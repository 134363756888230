import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import { SnackbarProvider } from 'notistack';
import { store } from './redux/store';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { logout } from './redux/userSlice';


// ----------------------------------------------------------------------
const { dispatch } = store;

// axios intercepters
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("accessToken");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
}, (error) => {
  console.log(error)
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log(error.response)
      dispatch(logout())
      window.location = '/';
    }
    return Promise.reject(error);
  }
);
// axios intercepters ends

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store)

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HelmetProvider>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <App />
          </SnackbarProvider>

        </BrowserRouter>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
