import React from 'react'
import { Stack } from '@mui/system'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { AlternateEmail, Key, Person } from '@mui/icons-material'


function OrderUserDetails({ userId, userEmail, userName }) {
    return (
        <Stack>
            <Paper sx={{ p: 2, bgcolor: "#121212" }}>
                <Stack>
                    <Typography variant='h6' >User Details</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} p={1}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4} md={4}>
                                <Stack direction={"row"} sx={{ border: "1px solid white", borderRadius: "5px" }} alignItems={"center"}>
                                    <Stack bgcolor={"#01ffff"} sx={{ width: 60, height: 50, borderRadius: "5px" }} alignItems={"center"} justifyContent={"center"}>
                                        <Key sx={{ fontSize: "30px", color: "#000" }} />
                                    </Stack>
                                    <Stack justifyContent={"center"} m={1}>
                                        <Typography variant='body1'>{userId}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Stack direction={"row"} sx={{ border: "1px solid white", borderRadius: "5px" }} alignItems={"center"}>
                                    <Stack bgcolor={"#01ffff"} sx={{ width: 60, height: 50, borderRadius: "5px" }} alignItems={"center"} justifyContent={"center"}>
                                        <Person sx={{ fontSize: "30px", color: "#000" }} />
                                    </Stack>
                                    <Stack justifyContent={"center"} m={1}>
                                        <Typography variant='body1'>{userName}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Stack direction={"row"} sx={{ border: "1px solid white", borderRadius: "5px" }} alignItems={"center"}>
                                    <Stack bgcolor={"#01ffff"} sx={{ width: 60, height: 50, borderRadius: "5px" }} alignItems={"center"} justifyContent={"center"}>
                                        <AlternateEmail sx={{ fontSize: "30px", color: "#000" }} />
                                    </Stack>
                                    <Stack justifyContent={"center"} m={1}>
                                        <Typography variant='body1'>{userEmail}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default OrderUserDetails