import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from "moment"
import { sentenceCase } from 'change-case';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormGroup,
  Popover,
  MenuItem,
  Modal,
  Box,
  Select,
  FormControl,
  Button,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material';
// components
import { MoonLoader } from 'react-spinners';
import { useSnackbar } from 'notistack';
import { Create, FilterList } from '@mui/icons-material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import Label from '../components/label';


// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  textfiled: {
    colorScheme: "dark",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#01FFFF"
    }
  },
});

const TABLE_HEAD = [
  { id: 's.no', label: 'S.No', alignRight: false },
  { id: 'date', label: 'Created At', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false, },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('date');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [page, setPage] = useState(0);

  const [userCount, setUserCount] = useState(0)

  const [users, setUsers] = useState([])

  const [openEditPopup, setOpenEditPopup] = useState(null)

  const [blockModalOpen, setBlockModalOpen] = useState(null)

  const [isBlocked, setIsBlocked] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  const [searchInput, setSearchInput] = useState("");

  const [statusFilter, setStatusFilter] = useState(null);

  const [emailFilter, setEmailFilter] = useState("");

  const [filterOpen, setFilterOpen] = useState(null);

  const [idFilter, setIdFilter] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    email: "",
    name: "",
    id: "",
    status: ""
  });

  const { uid } = useParams();
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();

  const classes = useStyles();

  const search = new URLSearchParams(window.location.search);
  const id = search.get('id')

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get(`${url}/member/admin/all?name=${filters.name}&page=${page}&size=${rowsPerPage}&userId=${uid || filters.id}&status=${filters.status}&email=${filters.email}`)
        setUsers(res.data.data.records)
        setUserCount(res.data.data.maxRecords)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUsers();
  }, [page, rowsPerPage, blockModalOpen, searchInput, uid, filterOpen, filters])

  const handleFilterSubmit = async () => {
    setFilters((old) => ({ ...old, email: emailFilter, id: idFilter, status: statusFilter }))
    handleFilterBoxClose();
  }

  const handleOpenMenu = (event, id) => {
    setOpenEditPopup(event.currentTarget);
    navigate(`?id=${id}`)
  };

  const handleModalOpen = async (id) => {
    setBlockModalOpen(true)
    setOpenEditPopup(null)
    const res = await axios.get(`${url}/member/single?id=${id}`)
    setIsBlocked(res.data.data.isBlocked)
  }
  const handleBlockModalClose = () => {
    setBlockModalOpen(false)
    navigate(`/dashboard/user`)
  }

  const blockUserHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      await axios.put(`${url}/member/update?id=${id}`, { isBlocked })
      enqueueSnackbar("User status updated!", { variant: 'success' })
      setIsLoading(false)
      handleBlockModalClose();
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      enqueueSnackbar(e.response.data.message, { variant: 'success' })
      setErrMsg(e.response.data.message)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: "none",
    p: 4,
  };


  const handleFilterBoxOpen = async (event) => {
    setFilterOpen(event.currentTarget);
  };

  const handleFilterBoxClose = () => {
    setFilterOpen(null);
    setEmailFilter("");
    setStatusFilter("");
    setIdFilter("");
  };


  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setFilters((old) => ({ ...old, name: searchInput }))
    }
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Users </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users List
          </Typography>
        </Stack>
        <Card sx={{ boxShadow: "none" }}>
          <Stack p={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <TextField placeholder='search...' className={classes.textfiled} onKeyDown={handleSearchKeyDown} sx={{ width: 300 }} onChange={(e) => setSearchInput(e.target.value)} />
            <Tooltip title="Filter">
              <IconButton onClick={handleFilterBoxOpen}> <FilterList sx={{ color: "white" }} /></IconButton>
            </Tooltip>
            <Popover
              elevation={2}
              open={filterOpen}
              anchorEl={filterOpen}
              onClose={handleFilterBoxClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack p={3} width={400} spacing={1} >
                <Typography variant="h5">Filter by</Typography>

                <TextField label='ID' className={classes.textfiled} value={idFilter} fullWidth onChange={(e) => setIdFilter(e.target.value)} />

                <TextField label='Email' className={classes.textfiled} value={emailFilter} fullWidth onChange={(e) => setEmailFilter(e.target.value)} />

                <Stack direction={"row"} className={classes.textfiled} spacing={1}  >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={statusFilter}
                      label="Status"
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <MenuItem value={false}>Active</MenuItem>
                      <MenuItem value={true}>Blocked</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} onClick={handleFilterSubmit} type="submit" fullWidth variant="contained">Filter</Button>
              </Stack>
            </Popover>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userCount}
                  onRequestSort={handleRequestSort}

                />
                <TableBody>
                  {filteredUsers.map((row, index) => {
                    const { _id, fullname, email, isBlocked, createdAt } = row;
                    const created = moment(createdAt).format("DD/MM/YYYY")
                    return (
                      <TableRow hover key={_id} tabIndex={-1} >
                        <TableCell width={100} align='right' padding='normal'>
                          <Typography align="center" >{page * rowsPerPage + index + 1}</Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar />

                            <Typography variant="subtitle2" noWrap>
                              {created}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{fullname}</TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">
                          <FormGroup>
                            <Paper sx={{ width: 80, fontSize: "12px", bgcolor: `${isBlocked === true ? "#5c0000" : "#155c00"}`, textAlign: "center" }}>{isBlocked === true ? "Blocked" : "Active"}</Paper>
                          </FormGroup>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton onClick={(e) => handleOpenMenu(e, _id)} size="large" color="inherit">
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>


                          <Popover
                            elevation={1}
                            open={Boolean(openEditPopup)}
                            anchorEl={openEditPopup}
                            onClose={() => { setOpenEditPopup(null); navigate(`/dashboard/user`) }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left ' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={() => handleModalOpen(_id)} >
                              <Create sx={{ mr: 2, fontSize: "17px" }} />
                              Edit
                            </MenuItem>
                          </Popover>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[2, 5, 10, 25]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Modal
        open={blockModalOpen}
        onClose={handleBlockModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Status Update
          </Typography>

          <form onSubmit={blockUserHandler}>
            <Typography color={"red"} m={1} variant='body2'>{errMsg}</Typography>

            <TextField
              className={classes.textfiled}
              select
              fullWidth
              sx={{ mb: 2 }}
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isBlocked}
              label="are you sure you want to change status?"
              onChange={(e) => setIsBlocked(e.target.value)}
            >
              <MenuItem value="false">Active</MenuItem>
              <MenuItem value="true">Blocked</MenuItem>
            </TextField>

            {
              isLoading ? <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>
                <MoonLoader
                  color="#ffffff"
                  loading={isLoading}
                  size={18}
                /></Button> :
                <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Update</Button>
            }
          </form>
        </Box>
      </Modal>
    </>
  );
}
