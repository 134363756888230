import PropTypes from 'prop-types';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
// @mui
import { makeStyles } from '@mui/styles';
import { Box, Card, CardHeader, Stack, TextField } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

MonthlyUserCount.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};
const useStyles = makeStyles({
  textfiled: {
    colorScheme: "dark",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#01FFFF"
    }
  },
});
export default function MonthlyUserCount({ title, subheader, chartData, onStartDate, onEndDate, startDate, chartColors, endDate, ...other }) {
  const classes = useStyles();
  const chartLabels = chartData.map((i) => i.key);

  const chartSeries = chartData.map((i) => i.value);
  const chartOptions = useChart({
    colors: chartColors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card {...other} sx={{ boxShadow: "none" }}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction={"row"} mt={2} ml={2} spacing={2}>
        <TextField className={classes.textfiled} size='small' type={"date"} label={"Start Date"} InputLabelProps={{ shrink: true }} value={startDate || moment().subtract(5, 'months').format("YYYY-MM-DD")} onChange={(e) => onStartDate(e.target.value)} />
        <TextField className={classes.textfiled} size='small' type={"date"} label={"End Date"} InputLabelProps={{ shrink: true }} value={endDate || moment().format("YYYY-MM-DD")} onChange={(e) => onEndDate(e.target.value)} />
      </Stack>
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
