import React, { forwardRef, useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { makeStyles } from "@mui/styles";
import { Box, Button, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, MenuItem, Modal, OutlinedInput, Paper, Select, Snackbar, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Add, ArrowBack, Close, RadioButtonCheckedSharp } from "@mui/icons-material";
import ProductSpec from "../components/productSpec/ProductSpec";

const url = process.env.REACT_APP_API_URL;

const Alert = forwardRef((props, ref) =>
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

const useStyles = makeStyles((theme) => ({
    textfiled: {

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        },
        // "& .MuiOutlinedInput-input": {
        //     color: "#01FFFF"
        // },
        // "&:hover .MuiOutlinedInput-input": {
        //     color: "#01FFFF"
        // },
        // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        //     color: "#01FFFF"
        // },
        "& .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
    },
    switch_track: {
        backgroundColor: "#185c5c",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));
export default function ProductEditPage() {

    const [name, setName] = useState("");

    const [open, setOpen] = useState(false);

    const [shortName, setShortName] = useState("");

    const [blocked, setBlocked] = useState(false);

    const [popular, setPopular] = useState(false);

    const [mrp, setMrp] = useState();

    const [stock, setStock] = useState(null)

    const [description, setDescription] = useState("");

    const [tags, setTags] = useState([])

    const [imagesInput, setImagesInput] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const [errMsg, setErrMsg] = useState("")

    const [disabled, setDisabled] = useState(true)

    const [productImages, setProductImages] = useState([])

    const [categories, setCategories] = useState([])

    const [categoryInput, setCategoryInput] = useState("")

    const [brands, setBrands] = useState([]);

    const [brandsInput, setBrandsInput] = useState("")

    const [latestDeal, setLatestDeal] = useState(false)

    const [newArrival, setNewArrival] = useState(false)

    const [productType, setProductType] = useState("")

    const [sellingPrice, setSellingPrice] = useState(null);

    const [offerPrice, setOfferPrice] = useState(null);

    const [adminPrice, setAdminPrice] = useState(null);

    const [offerEnabled, setOfferEnabled] = useState(false);

    const [offerTitle, setOfferTitle] = useState("");

    const [offers, setOffers] = useState([]);

    const [items, setItems] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [specFormData, setSpecFormData] = useState('');

    const [shortDescription, setShortDescription] = useState("");

    const [skuid, setSkuid] = useState("");

    const [shippingCharge, setShippingCharge] = useState(0);

    // update

    const [tableDescDocument, setTableDescDocument] = useState([]);

    const [pageRefresh, setPageRefresh] = useState(false);

    const [returnPeriod, setReturnPeriod] = useState(null);

    const [replacementPeriod, setReplacementPeriod] = useState(null);


    const formData = new FormData();

    const { productId } = useParams();
    const classes = useStyles();

    const navigate = useNavigate()

    function handleStateValues(data) {
        setName(data.name)
        setShortName(data.shortName);
        setBlocked(data.isBlocked);
        setPopular(data.isPopular);
        setMrp(data.mrp);
        setStock(data.stock);
        setDescription(data.description);
        setTags(data.tags)
        setProductImages(data.images)
        setLatestDeal(data.latestDeal)
        setNewArrival(data.newArrival)
        setProductType(data.productType)
        setOfferEnabled(data.offerEnabled)

        setOfferTitle(data.offerId || "");
        setOfferPrice(data.offerPrice);
        setAdminPrice(data.adminPrice)
        setSellingPrice(data.sellingPrice)
        setShortDescription(data.shortDescription)
        setItems(data.specifications)
        setSkuid(data.skuId)
        setShippingCharge(data.shippingCharge)

        setReturnPeriod(data.returnPeriod)
        setReplacementPeriod(data.replacementPeriod)
    }

    const getCategories = async () => {
        const categoryRes = await axios.get(`${url}/category/all`,{
            params:{
                productType
            }
        })
            setCategories(categoryRes.data.data)
    }
    
    const getOffers = async () => {
        const res = await axios.get(`${url}/offer/titles`,{
            params: {
                productType
            }
        });
        setOffers(res.data.data.offers);
    }

    useEffect(() => {
        const fetchData = async () => {
            const productRes = await axios.get(`${url}/products/single?id=${productId}`)
            handleStateValues(productRes.data.data)
            setTableDescDocument(productRes.data.data.tableDescription)
            
            getCategories();

            const brandRes = await axios.get(`${url}/brand/all`)
            setBrands(brandRes.data.data.result)

            const getBrandRes = await axios.get(`${url}/brand/single?id=${productRes.data.data.brand._id}`)
            setBrandsInput(getBrandRes.data.data._id)

            const getCategoryRes = await axios.get(`${url}/category/single?id=${productRes.data.data.category._id}`)
            setCategoryInput(getCategoryRes.data.data._id)

         
        }
        fetchData();
    }, [productId, pageRefresh])

    useEffect(() => {
        setCategoryInput("")
        getCategories();
        getOffers();
    }, [productType])



    formData.append("id", productId)
    formData.append("name", name)
    formData.append("shortName", shortName)
    formData.append("mrp", mrp);
    formData.append("stock", stock);
    formData.append("isBlocked", blocked);
    formData.append("isPopular", popular);
    formData.append("tags", tags);
    formData.append("description", description)
    formData.append("category", categoryInput)
    formData.append("brand", brandsInput)
    formData.append("productType", productType)
    formData.append("shortDescription", shortDescription)
    formData.append("skuId", skuid)
    formData.append("shippingCharge", shippingCharge)
    formData.append("returnPeriod", returnPeriod)
    formData.append("replacementPeriod", replacementPeriod)
    formData.append("offerEnabled", offerEnabled)
    formData.append("latestDeal", latestDeal)
    if (offerTitle) {
        formData.append("offerId", offerTitle);
    }
    if (offerEnabled) {
        formData.append("offerPrice", offerPrice);
    }
    if (items.length > 0) {
        formData.append("specifications", JSON.stringify(items))
    }
    formData.append("adminPrice", adminPrice)

    Array.from(imagesInput).forEach((image) => {
        formData.append("productImg", image)
    })
    const updateHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const res = await axios.put(`${url}/products/update`, formData)
            setOpen(true);
            if (res.data.data) {
                setTimeout(() => {
                    navigate("/dashboard/products")
                    setIsLoading(false)
                }, 3000)
            }
        } catch (e) {
            setIsLoading(false)
            setErrMsg(e.response.data.message)
            console.log(e)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSwitchChange = (e) => {
        setDisabled(!e.target.checked)
    }

    const categoryChangeHandler = (e) => {
        setCategoryInput(e.target.value)
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleAddSpec = (event) => {
        event.preventDefault();
        if (specFormData.length !== 0) {
            setItems([...items, specFormData]);
            setSpecFormData("")
        }
    };

    const handleAddSpecValue = (event) => {
        setSpecFormData(event.target.value);
    };

    const handleAddSpecFormSubmitHandler = (e) => {
        e.preventDefault();
        handleCloseModal();
    }

    const handleRemoveItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };
    return <>
        <Helmet>
            <title> Dashboard | Edit Product</title>
        </Helmet>
        <Stack justifyContent={"space-between"} direction={"row"}>

            <Typography variant="h4">
                <IconButton onClick={() => navigate("/dashboard/products")}>
                    <ArrowBack sx={{ color: "white" }} />
                </IconButton> Edit Product Details
            </Typography>
            <FormGroup >
                <FormControlLabel control={<Switch classes={{
                    track: classes.switch_track,
                    switchBase: classes.switch_base,
                    colorPrimary: classes.switch_primary,
                }} onChange={handleSwitchChange} />} label="Enable Editing" />
            </FormGroup>
        </Stack>

        <Stack sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={updateHandler}>

                <Grid item xs={12} sm={12} alignItems={"center"} mb={3}>
                    <Typography variant="body2">Product Images</Typography>
                    <Stack direction={"row"} sx={{ mt: 1, }} spacing={3} >
                        {Array.from(productImages).map((image) =>
                            <img key={image._id} style={{ width: 120, objectFit: "contain", border: "1px solid #fff", borderRadius: "5px" }} src={image.fileUrl} alt="Product" />
                        )}
                    </Stack>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            name="name"
                            label="Product Name"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={shortName}
                            onChange={(e) => setShortName(e.target.value)}
                            required
                            name="shortName"
                            label="Product Short Name"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={skuid}
                            onChange={(e) => setSkuid(e.target.value)}
                            required
                            name="skuid"
                            label="SKUID"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    {/* Brands */}
                    <Grid item xs={12} sm={4}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Change Brand *</InputLabel>
                                <Select

                                    disabled
                                    value={brandsInput}
                                    onChange={(e) => setBrandsInput(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="change brand"
                                >
                                    {brands.map(({ _id, name }) =>
                                        <MenuItem key={_id} value={_id}>{name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
            

                    <Grid item xs={12} sm={4}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label"> Product Type</InputLabel>
                                <Select
                                    disabled
                                    value={productType}
                                    onChange={(e) => setProductType(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="change product type"
                                >
                                    <MenuItem value={"brand-new"}>Brand-new</MenuItem>
                                    <MenuItem value={"pre-owned"}>Pre-owned</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                            {/* CATEGORY */}
                            <Grid item xs={12} sm={4}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Category *</InputLabel>
                                <Select
                                    disabled
                                    value={categoryInput}
                                    onChange={categoryChangeHandler}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="change category"
                                >
                                    {categories.map(({ _id, name }) =>
                                        <MenuItem key={_id} value={_id}>{name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Offer Enabled</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={offerEnabled}
                                    onChange={(e) => setOfferEnabled(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Offer Enabled"
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Offer Title</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={offerTitle}
                                    onChange={(e) => setOfferTitle(e.target.value)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="offer title">
                                    {
                                        offers.map((offer) => (
                                            <MenuItem key={offer._id} value={offer._id}>{offer.title}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={mrp}
                            onChange={(e) => setMrp(e.target.value)}
                            required
                            name="mrp"
                            label="MRP"
                            fullWidth
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={adminPrice}
                            onChange={(e) => setAdminPrice(e.target.value)}
                            required
                            name="adminPrice"
                            label="Admin Price"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={offerPrice}
                            onChange={(e) => setOfferPrice(e.target.value)}
                            name="offerPrice"
                            label="Offer Price"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField className={classes.textfiled}
                            disabled
                            value={`₹ ${sellingPrice}`}
                            onChange={(e) => setSellingPrice(e.target.value)}
                            required
                            name="sellingPrice"
                            label="Selling Price"
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>




                    {/* LATEST DEALS */}
                    <Grid item xs={12} sm={3}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Latest Deal</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={latestDeal}
                                    onChange={(e) => setLatestDeal(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="latest deal"
                                >
                                    <MenuItem value={"true"} >Yes</MenuItem>
                                    <MenuItem value={"false"} >No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    {/* new arrival */}
                    <Grid item xs={12} sm={3}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">New Arrival</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={newArrival}
                                    onChange={(e) => setNewArrival(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="latest deal"
                                >
                                    <MenuItem value={"true"} >Yes</MenuItem>
                                    <MenuItem value={"false"} >No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={blocked}
                                    onChange={(e) => setBlocked(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="blocked"
                                >
                                    <MenuItem value={"true"} >Yes</MenuItem>
                                    <MenuItem value={"false"} >No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Popular</InputLabel>
                                <Select
                                    disabled={disabled}
                                    value={popular}
                                    onChange={(e) => setPopular(e.target.value)}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="popular"
                                >
                                    <MenuItem value={"true"} >Yes</MenuItem>
                                    <MenuItem value={"false"} >No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    {/* Stock and period */}

                    <Grid item xs={12} sm={3}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={shippingCharge}
                            onChange={(e) => setShippingCharge(e.target.value)}
                            required
                            name="shippingCharge"
                            label="Shipping Charge"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            required
                            name="stock"
                            label="In Stock"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={returnPeriod}
                            onChange={(e) => setReturnPeriod(e.target.value)}
                            required
                            name="returnPeriod"
                            label="Return Period"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={replacementPeriod}
                            onChange={(e) => setReplacementPeriod(e.target.value)}
                            required
                            name="replacementPeriod"
                            label="Replacement Period"
                            type={"number"}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    {/* SPECS */}
                    <Grid item xs={12} sm={12}>
                        <TextField className={classes.textfiled}
                            sx={{ caretColor: "transparent" }}
                            disabled={disabled}
                            name="specifications"
                            autoComplete="off"
                            label="Specifications"
                            fullWidth
                            variant="outlined"
                            onClick={handleOpenModal}
                            value={items.map((item, index) => (` ${index + 1}. ${item}`))}
                        />
                    </Grid>
                    {/* TAGS */}
                    <Grid item xs={12} sm={12}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={tags}
                            onChange={(e) => setTags(e.target.value.split(","))}
                            name="tags"
                            label="Tags"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                            name="shortDescription"
                            label="Short Description"
                            fullWidth
                            autoComplete="shipping address-line1"
                            variant="outlined"
                            multiline
                            rows={2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.textfiled}
                            disabled={disabled}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            name="description"
                            label="Description"
                            fullWidth
                            autoComplete="shipping address-line1"
                            variant="outlined"
                            multiline
                            rows={3}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack>
                            <label htmlFor="images">Click here to update Product images
                                <Stack direction={"row"} spacing={1}>
                                    {Array.from(imagesInput).map((image) => (
                                        <img key={image.index} style={{ objectFit: "contain", width: 60 }} src={image ? URL.createObjectURL(image) : null} alt="selected images" />
                                    ))}
                                </Stack>

                                <input disabled={disabled} name="productImg" onChange={(event) => { setImagesInput(event.target.files) }} id="images"
                                    type="file" accept="images/*" multiple />
                            </label>
                        </Stack>
                    </Grid>

                    {/* <Stack> */}
                    <Stack sx={{ ml: 4, mt: 2 }}>
                        <Typography color={"error"}>{errMsg}</Typography>
                        <Stack alignItems={"center"}>
                            {
                                isLoading ?
                                    <Button type="submit" sx={{ mt: 2, width: 500, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant="contained" fullWidth>
                                        <MoonLoader
                                            color="#000"
                                            loading={isLoading}
                                            size={18}
                                        /></Button> :
                                    <Button type="submit" sx={{ mt: 2, width: 500, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant="contained" disabled={disabled}>Update Product</Button>
                            }
                        </Stack>

                    </Stack>
                </Grid>
            </form>

            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Product Update Success
                </Alert>
            </Snackbar>

        </Stack>
        <Divider sx={{ mt: 6 }} />

        <Stack mt={3}>
            <ProductSpec productDescData={tableDescDocument} onPageRefresh={setPageRefresh} pageRefresh={pageRefresh} />
        </Stack>



        {/* SPEC MODAL */}
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Specifications
                </Typography>
                <form onSubmit={handleAddSpecFormSubmitHandler} >
                    <Stack sx={{
                        overflowY: 'scroll',
                        maxHeight: '60vh',
                    }}>
                        <List component="ul" >
                            {items.map((item, index) => (
                                <Stack key={index} direction={"row"} alignItems={"center"}>
                                    <ListItem key={index}>{`${index + 1} :    ${item}`}</ListItem>
                                    <IconButton onClick={() => handleRemoveItem(index)}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                    <br />
                                </Stack>
                            ))}
                        </List>
                    </Stack>
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel htmlFor="outlined-adornment-password">Specifications</InputLabel>
                        <OutlinedInput
                            value={specFormData}
                            onChange={handleAddSpecValue}
                            id="outlined-adornment-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleAddSpec}
                                        edge="end"
                                    >
                                        <Add sx={{ color: "#01ffff" }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="specifications"
                        />
                    </FormControl>


                    <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth type="submit">Submit</Button>
                </form>
            </Box>
        </Modal>


    </>
}