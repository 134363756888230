import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TablePagination, TableRow, TableHead, Button, FormControlLabel, IconButton, TextField, Tooltip, Typography, Stack, FormControl, InputLabel, Popover, MenuItem, Select, Paper } from '@mui/material';
import { Cached, Close, ContentCopy, Done, Download, FilterList, FormatListNumbered, HourglassEmpty, IosShare, Person2, Visibility } from '@mui/icons-material';
import Zoom from '@mui/material/Zoom';
import { useSnackbar } from 'notistack';
import Iconify from '../iconify/Iconify';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
});
// ----------------------IMPORTS END---------------------------------||
const url = process.env.REACT_APP_API_URL;

function ReplacementPending() {
    const [orders, setOrders] = useState([]);

    const [pageState, setPageState] = useState({
        loading: false,
        total: 0,
        page: 0,
        pageSize: 10
    })

    const [fromDate, setFromDate] = useState("");

    const [toDate, setToDate] = useState("");

    const [filterOpen, setFilterOpen] = useState(null);

    const [orderType, setOrderType] = useState("");

    const [filters, setFilters] = useState({
        orderId: "",
        userId: "",
        replaceRefId: "",
        payMode: "",
        start: "",
        end: "",
        shipStatus: "",
        returnStatus: "",
        replacementStatus: "",
        orderType: "",
        courierId: ""
    });

    const [oidFilter, setOidFilter] = useState("");

    const [uidFilter, setUidFilter] = useState("");

    const [refIdFilter, setRefIdFilter] = useState("");

    const [payModeFilter, setPayModeFilter] = useState("");

    const [shippingStatus, setShippingStatus] = useState("");

    const [returnStatus, setReturnStatus] = useState("");

    const [replacementStatus, setReplacementStatus] = useState("");

    const [queueListOpen, setQueueListOpen] = useState(false);

    const [queueListPage, setQueueListPage] = useState(0);

    const [queueListRowsPerPage, setQueueListRowsPerPage] = useState(5);

    const [exportJobs, setExportJobs] = useState([]);

    const [exportJobCount, setExportJobCount] = useState(0);

    const [courierIdFilter, setCourierIdFilter] = useState("");

    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const classes = useStyles();


    useEffect(() => {
        const fetchData = async () => {
            setPageState((old) => ({ ...old, loading: true }))
            const page = pageState.page < 0 ? 0 : pageState.page;

            const res = await axios.get(`${url}/order/all?orderType=REPLACEMENT_PENDING&page=${page}&size=${pageState.pageSize}&startDate=${filters.start}&endDate=${filters.end}&orderId=${filters.orderId}&userId=${filters.userId}&replacementReference=${filters.replaceRefId}&replacementStatus=${filters.replacementStatus}&courierId=${filters.courierId}`)

            setPageState((old) => ({ ...old, loading: false, total: res.data.data.maxRecords }))
            setOrders(res.data.data.orders)
        }
        fetchData();
    }, [pageState.page, pageState.pageSize, filters, queueListPage, queueListOpen, reload])

    const ProfileRender = ({ index }) => {
        const handleEditClick = () => {
            navigate(`/dashboard/user/${index}`)
            console.log(index)
        }
        return <FormControlLabel
            control={
                <Tooltip title={"view"} arrow TransitionComponent={Zoom}>
                    <IconButton sx={{ pl: 1, color: "#01ffff" }} aria-label="add an alarm" onClick={handleEditClick} >
                        <Person2 />
                    </IconButton>
                </Tooltip>
            }
        />
    };

    const GridAlignment = 'left';

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No',
            filterable: false,
            width: 60,
            align: "center",
            headerAlign: GridAlignment,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            valueFormatter: params => params.value ?
                moment(params?.value).format("DD/MM/YYYY") : "",
            width: 105,
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'orderId',
            headerName: 'Order Id',
            width: 150,
            headerAlign: GridAlignment,
            align: "center",
            valueFormatter: params => params.value ? params.value.split("-")[1] : "",
            renderCell: (params) =>
                <Stack>
                    <Button onClick={() => navigator.clipboard.writeText(params.value)} sx={{ color: "inherit", "&:hover": {} }}>
                        <Typography variant='body2' >{params.value && params.value.split("-")[1]}</Typography>
                    </Button>
                    <Stack direction={"row"} justifyContent={"center"}>
                        <Link to={params.value} style={{ color: "inherit", textDecoration: "none" }}>
                            <Button size='small' sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }}  >
                                <Visibility fontSize='small' /> view
                            </Button>
                        </Link>
                    </Stack>
                </Stack>,
        },
        {
            field: 'userId',
            headerName: 'User ID',
            width: 100,
            renderCell: (params) => <Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Tooltip title={"copy"} arrow TransitionComponent={Zoom} >
                        <IconButton sx={{ color: "#01ffff" }} onClick={() => navigator.clipboard.writeText(params.value)}>
                            <ContentCopy fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <ProfileRender index={params.value} />
                </Stack>
            </Stack>,
            // flex: 1,
            headerAlign: GridAlignment,
            align: "left",
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            flex: 1,
            headerAlign: GridAlignment,
            renderCell: (params) =>
                <Typography variant='body2'>{params.value}</Typography>,
            align: "center"
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            renderCell: (params) => <Stack>
                <Typography variant='body2' mb={"4px"}> {params.value.type.toUpperCase()}</Typography>
                {
                    params.value.type === "Replacement" &&
                    <Stack alignItems={"center"}>
                        <Stack direction={"row"} spacing={1} height={1} alignItems={"center"}>
                            <HourglassEmpty sx={{ fontSize: 16, color: "#D3BA00" }} fontSize='small' />
                            <Typography variant='body2'>{params.value.approval}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Done sx={{ fontSize: 16, color: "#27C700" }} fontSize='small' />
                            <Typography variant='body2'> {params.value.approved}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Close sx={{ fontSize: 16, color: "#E11F1C" }} fontSize='small' />
                            <Typography variant='body2'>{params.value.rejected} </Typography>
                        </Stack>
                    </Stack>
                }
            </Stack>,
            flex: 1,
            align: "center  ",
            headerAlign: GridAlignment,

        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption'>SP </Typography>
                        <Typography textAlign={"left"} variant='caption'>SC </Typography>
                        <Typography textAlign={"left"} variant='caption'>RA </Typography>
                        <Typography textAlign={"left"} variant='caption'>TA </Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.selling)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.shipping)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.refund)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.total)}</Typography>
                    </Stack>

                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'replacementPending',
            headerName: 'Pending',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={14}>PENDING</Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={14}>{params.value}</Typography>
                    </Stack>

                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
    ];

    const rows = orders.map((order, index) => ({
        slno: pageState.page * pageState.pageSize + index + 1,
        orderType: {
            type: order.replacementReference ? "Replacement" : "Normal",
            approval: order.invoices.awaitApproval,
            approved: order.invoices.approved,
            rejected: order.invoices.rejected
        },
        replacementPending: order.invoices.replacementPending,
        price: {
            selling: order.sellingPrice,
            shipping: order.shippingCharge,
            total: order.grandTotal,
            refund: order.refundAmount,
        },
        ...order
    }))


    const handleFilterBoxClose = () => {
        setFilterOpen(false)
        setOidFilter("");
        setUidFilter("");
        setRefIdFilter("");
        setPayModeFilter("")
        setToDate("")
        setFromDate("")
        setShippingStatus("");
        setReplacementStatus("");
        setReturnStatus("")
        orderType("")
    }

    const handleFilterSubmit = () => {
        setFilters((old) => ({ ...old, orderId: oidFilter, userId: uidFilter, replaceRefId: refIdFilter, payMode: payModeFilter, start: fromDate, end: toDate, shipStatus: shippingStatus, returnStatus, replacementStatus, orderType, courierId: courierIdFilter }))
        handleFilterBoxClose();
    }



    return (
        <>
            <Stack direction={"row"} spacing={2} alignItems={"center"} m={1} mb={3} justifyContent={"flex-end"}>
                <Tooltip title="Filter">
                    <IconButton onClick={(e) => setFilterOpen(e.currentTarget)}> <FilterList sx={{ color: "white" }} /></IconButton>
                </Tooltip>
            </Stack>
            {/* FILTER BOX */}
            <Popover
                elevation={2}
                anchorEl={filterOpen}
                open={filterOpen}
                onClose={() => setFilterOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack p={3} width={400} spacing={1} >
                    <Typography variant="h5">Filter by</Typography>
                    <Stack spacing={2}>
                        <Stack direction={"row"} spacing={1}>
                            <TextField
                                className={classes.textfiled}
                                onChange={(e) => setOidFilter(e.target.value)}
                                value={oidFilter}
                                size='small'
                                label="Order ID"
                            />
                            <TextField
                                className={classes.textfiled}
                                value={uidFilter}
                                onChange={(e) => setUidFilter(e.target.value)}
                                size='small'
                                label="User ID"
                            />
                        </Stack>

                        <Stack direction={"row"} spacing={1} >
                            <TextField
                                className={classes.textfiled}
                                value={refIdFilter}
                                onChange={(e) => setRefIdFilter(e.target.value)}
                                size='small'
                                label="Replacement Ref. ID"
                            />
                            <TextField
                                className={classes.textfiled}
                                value={courierIdFilter}
                                onChange={(e) => setCourierIdFilter(e.target.value)}
                                size='small'
                                label="Courier ID"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField className={classes.textfiled} size='small' onChange={(e) => setFromDate(e.target.value)} type={"date"} label={"From"} InputLabelProps={{ shrink: true }} />
                            <TextField className={classes.textfiled} onChange={(e) => setToDate(e.target.value)} size='small' type={"date"} label={"To"} InputLabelProps={{ shrink: true }} />
                        </Stack>
                    </Stack>


                    <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} type="submit" onClick={handleFilterSubmit} fullWidth variant="contained">Filter</Button>
                </Stack>
            </Popover>
            <Paper sx={{ p: 2 }}>

                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnSeparator": {
                            color: "#454545"
                        }
                    }}
                    getRowId={(row) => row.slno}
                    autoHeight
                    rows={rows}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    pagination
                    rowCount={pageState.total}
                    loading={pageState.loading}
                    columns={columns}
                    GridAlignment={'center'}
                    page={pageState.page}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState((old) => ({ ...old, page: newPage }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                    rowHeight={100}
                />
            </Paper>

            {/* LIST MODAL */}

        </>
    )
}

export default ReplacementPending