import * as Yup from "yup"

export const productSchema = Yup.object({
    name: Yup.string().required("Please enter Product Name"),
    shortName: Yup.string().required("Please enter Product short name"),
    mrp: Yup.number().min(1).required("Please enter MRP field"),
    adminPrice: Yup.number().min(1).required("Please enter Admin price"),
    offerPrice: Yup.number().min(1),
    stock: Yup.number().required("Please provide stock"),
    returnPeriod: Yup.number().optional().min(1),
    replacementPeriod: Yup.number().optional().min(1),
    description: Yup.string().required("please enter product description"),
    tags: Yup.string().min(2).required("please provide tags"),
    skuid: Yup.string().required("Please enter skuid"),
    shortDescription: Yup.string().required("Please enter Short description"),
    shippingCharge: Yup.number().min(0)
})