import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { MoonLoader } from 'react-spinners';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom"
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardMedia, Grid, Modal, Paper, Stack, TextField, Typography } from '@mui/material'
import account from '../_mock/account';
import Iconify from '../components/iconify';
import { loginSuccess } from '../redux/userSlice';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    switch_track: {
        backgroundColor: "#185c5c",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));


function ProfilePage() {

    const [fullname, setFullname] = useState("");

    const [email, setEmail] = useState("");

    const [open, setOpen] = useState(null);

    const [errMsg, setErrMsg] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [imageInput, setImageInput] = useState("");

    const [updateData, setUpdateData] = useState([]);

    const [adminData, setAdminData] = useState({
        _id: "",
        name: "",
        accType: "",
        isBlocked: false
    });

    const classes = useStyles();

    const formData = new FormData();

    const { id } = useParams();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`${url}/admin/get_admin?id=${id}`)
            setAdminData({
                _id: res.data.data._id,
                fullname: res.data.data.fullname,
                email: res.data.data.email,
                accType: res.data.data.accType.replace("_", " "),
                isBlocked: res.data.data.isBlocked,
                profilePic: res.data.data.profilePic
            })
            setFullname(res.data.data.fullname)
            setEmail(res.data.data.email)
        }
        fetchData();
    }, [id, open])

    const updateAdminProfileHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            formData.append("id", id)
            formData.append("fullname", fullname)
            formData.append("profilePic", imageInput)
            const res = await axios.put(`${url}/admin/update`, formData);
            setUpdateData(res.data.data)
            setIsLoading(false)
            setOpen(false)
            dispatch(loginSuccess(res.data.data))


        } catch (e) {
            setIsLoading(false)
            setErrMsg(e.response.data.message)
            console.log(e)
        }
    }

    const handleOpen = async () => {
        setOpen(true)
        setErrMsg("")
    }

    const handleClose = () => {
        setOpen(false)
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };
    return (
        <>
            <Helmet>
                <title> Dashboard | Profile</title>
            </Helmet>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    Admin Profile
                </Typography>

                <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} onClick={handleOpen} variant="contained" startIcon={<Iconify icon="material-symbols:edit" />}>
                    Edit Profile
                </Button>
            </Stack>

            <Stack p={6} alignItems={"center"}>
                <Paper sx={{ p: 5, width: 750 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                            <Card >
                                <CardMedia
                                    component="img"
                                    alt="adminProfile"
                                    width="100"
                                    image={adminData.profilePic || account.photoURL}
                                />

                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={3} >
                            <Stack direction={"column"} spacing={3} alignItems={"left"} mt={3} sx={{ borderRight: "1px solid black" }}>
                                <Typography >Admin ID </Typography>
                                <Typography >Admin Name </Typography>
                                <Typography >Admin Email </Typography>
                                <Typography >Admin Type </Typography>
                                <Typography >Status     </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Stack direction={"column"} spacing={3} alignItems={"left"} mt={3}>
                                <Typography >{adminData._id}</Typography>
                                <Typography >{adminData.fullname}</Typography>
                                <Typography >{adminData.email}</Typography>
                                <Typography >{adminData.accType.toUpperCase()}</Typography>
                                <Typography >{adminData.isBlocked ? "BLOCKED" : "ACTIVE"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Stack>
            {/* UPDATE MODAL */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Profile
                    </Typography>
                    <form onSubmit={updateAdminProfileHandler}>
                        <Typography variant={"body2"} color={"error"}>{errMsg}</Typography>

                        <TextField className={classes.textfiled} value={fullname} onChange={(e) => setFullname(e.target.value)} sx={{ mt: 3 }} name="fullname" fullWidth id="outlined-basic" label="Fullname" required variant="outlined" />
                        <TextField className={classes.textfiled} value={email} disabled sx={{ mt: 3, mb: 2 }} name="email" fullWidth id="outlined-basic" label="Email" required variant="outlined" />
                        <input
                            onChange={(e) => setImageInput(e.target.files[0])}
                            name='profilePic'
                            type="file"
                            accept="image/*"
                        />

                        {
                            isLoading ? <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant="contained" fullWidth>
                                <MoonLoader
                                    color="#000"
                                    loading={isLoading}
                                    size={18}
                                /></Button> :
                                <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant="contained" fullWidth>Update</Button>
                        }
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default ProfilePage