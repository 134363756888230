import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import moment from 'moment';
import { MoonLoader } from 'react-spinners';
import { sentenceCase } from 'change-case';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { FilterList, Image, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import Label from '../components/label';
import Iconify from '../components/iconify';

const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  textfiled: {
    colorScheme: 'dark',
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#01FFFF',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#01FFFF',
    },
  },
});
export default function OffersPage() {
  const [offers, setOffers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [errMsg, setErrMsg] = useState('');

  const [images, setImages] = useState([]);

  const [isBlocked, setIsBlocked] = useState(false);

  const [open, setOpen] = useState(null);

  const [title, setTitle] = useState('');

  const [shortTitle, setShortTitle] = useState('');

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const [productType, setProductType] = useState('brand-new');

  const [rowId, setRowId] = useState('');

  const [openEditPopup, setOpenEditPopup] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);

  const [pageState, setPageState] = useState({
    loading: false,
    total: 0,
    page: 0,
    pageSize: 10,
  });

  const [searchInput, setSearchInput] = useState('');

  const [filters, setFilters] = useState({
    status: '',
    title: '',
    start: '',
    end: '',
    productType: '',
  });

  const [filterOpen, setFilterOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState('');

  const [startDateFilter, setStartDateFilter] = useState('');

  const [endDateFilter, setEndDateFilter] = useState('');

  const [productTypeFilter, setProductTypeFilter] = useState('');

  const formData = new FormData();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    setPageState((old) => ({ ...old, loading: true }));
    const page = pageState.page < 0 ? 0 : pageState.page;
    const fetchData = async () => {
      const offerAllRes = await axios.get(
        `${url}/offer/all?title=${searchInput}&page=${page}&size=${pageState.pageSize}&isBlocked=${filters.status}&startDate=${filters.start}&endDate=${filters.end}&productType=${filters.productType}`
      );
      setOffers(offerAllRes.data.data.offers);
      setPageState((old) => ({ ...old, loading: false, total: offerAllRes.data.data.maxRecords }));
    };
    fetchData();
  }, [openEditModal, searchInput, pageState.page, pageState.pageSize, open, filters, filterOpen]);

  const createNewOfferHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      formData.append('title', title);
      formData.append('shortTitle', shortTitle);
      formData.append('isBlocked', isBlocked);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('productType', productType);
      Array.from(images).forEach((image) => { 
        formData.append('images', image);
      });

      await axios.post(`${url}/offer/add`, formData);
      setIsLoading(false);
      enqueueSnackbar('Offer created', { variant: 'success' });
      setOpen(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e.response.data.message, { variant: 'error' });
      setErrMsg(e.response.data.message);
    }
  };

  // open modal
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const MatEdit = ({ index }) => {
    const handleEditClick = (e) => {
      setOpenEditPopup(e.currentTarget);
      setRowId(index);
    };
    return (
      <FormControlLabel
        control={
          <IconButton color="secondary" aria-label="add an alarm" onClick={handleEditClick}>
            <MoreVert style={{ color: 'white' }} />
          </IconButton>
        }
      />
    );
  };

  const handleOpenEditModal = async () => {
    const offerRes = await axios.get(`${url}/offer?offerId=${rowId}`);
    setOpenEditModal(true);
    setOpenEditPopup(false);
    setTitle(offerRes.data.data.title);
    setIsBlocked(offerRes.data.data.isBlocked);
    setProductType(offerRes.data.data.productType);
    setStartDate(moment(offerRes.data.data.startDate).format('yyyy-MM-DD'));
    setEndDate(moment(offerRes.data.data.endDate).format('yyyy-MM-DD'));
    setImages(offerRes.data.data.images);
    setShortTitle(offerRes.data.data.shortTitle);
  };

  const updateOfferHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      formData.append('title', title);
      formData.append('offerId', rowId);
      formData.append('shortTitle', shortTitle);
      formData.append('isBlocked', isBlocked);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('productType', productType);
      Array.from(images).forEach((image) => {
        formData.append('images', image);
      });
      const res = await axios.put(`${url}/offer/update`, formData);
      console.log(res.data.data);
      setIsLoading(false);
      enqueueSnackbar('Offer Updated', { variant: 'success' });
      setOpenEditModal(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e.response.data.message, { variant: 'error' });
      setErrMsg(e.response.data.message);
      console.log(e);
    }
  };

  const columns = [
    {
      field: 'slno',
      headerName: 'Sl.No.',
      filterable: false,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Offer Title    ',
      width: 250,
      valueGetter: (params) => sentenceCase(params.row.title),
      flex: 1,
    },
    {
      field: 'productType',
      headerName: 'Product Type',
      width: 150,
      valueGetter: (params) => params.row.productType,
      flex: 1,
    },
    {
      field: 'isBlocked',
      headerName: 'Status',
      width: 90,
      renderCell: (params) => (
        <Paper
          sx={{
            width: 80,
            fontSize: '12px',
            bgcolor: `${params.row.isBlocked === true ? '#5c0000' : '#155c00'}`,
            textAlign: 'center',
          }}
        >
          {params.row.isBlocked === true ? 'Blocked' : 'Active'}
        </Paper>
      ),
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 120,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
      flex: 1,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 120,
      valueFormatter: (params) => moment(params?.value).format('DD/MM/YYYY'),
      flex: 1,
    },
    {
      field: 'images',
      headerName: 'Image',
      headerAlign: 'center',
      width: 120,
      renderCell: (params) => (
        <Box
          component="img"
          sx={{
            height: 30,
            objectFit: 'contain',
            width: 130,
          }}
          alt=<Image />
          src={params.row.images[0]}
        />
      ),
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 60,
      renderCell: (params) => (
        <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
          <MatEdit index={params.row._id} />
        </div>
      ),
      flex: 1,
    },
  ];

  const rows = offers.map((result, index) => ({
    slno: pageState.page * pageState.pageSize + index + 1,
    ...result,
  }));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'none',
    p: 4,
  };

  const handleFilterSubmit = () => {
    setFilters((old) => ({
      ...old,
      status: statusFilter,
      start: startDateFilter,
      end: endDateFilter,
      productType: productTypeFilter,
    }));
    handleFilterBoxClose();
  };

  const handleFilterBoxClose = (e) => {
    setFilterOpen(null);
    setStatusFilter('');
    setStartDateFilter('');
    setEndDateFilter('');
    setProductTypeFilter('');
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Offers</title>
      </Helmet>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Typography variant="h4">Offers</Typography>
        <Button
          sx={{ backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
          onClick={handleOpen}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Offer
        </Button>
      </Stack>
      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
        <TextField
          className={classes.textfiled}
          sx={{ mt: 2, width: 500 }}
          placeholder="Search..."
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Tooltip title="Filter">
          <IconButton onClick={(e) => setFilterOpen(e.currentTarget)}>
            {' '}
            <FilterList sx={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      {/* CREATE OFFER */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create new Offer
          </Typography>
          <form onSubmit={createNewOfferHandler}>
            <Typography variant={'body2'} color={'error'}>
              {errMsg}
            </Typography>
            <Box
              sx={{
                p: 1,
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 510,
              }}
            >
              <TextField
                className={classes.textfiled}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mt: 3 }}
                name="title"
                fullWidth
                id="outlined-basic"
                label="Offer Title"
                required
                variant="outlined"
              />
              <TextField
                className={classes.textfiled}
                onChange={(e) => setShortTitle(e.target.value)}
                sx={{ mt: 3 }}
                name="shortTitle"
                fullWidth
                id="outlined-basic"
                label="Short Title"
                required
                variant="outlined"
              />
              <Box sx={{ minWidth: 120, mt: 2 }}>
                <FormControl fullWidth className={classes.textfiled}>
                  <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={productType}
                    label="Product Type"
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <MenuItem value={'brand-new'}>Brand-new</MenuItem>
                    <MenuItem value={'pre-owned'}>Pre-owned</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120, mt: 2 }}>
                <FormControl fullWidth className={classes.textfiled}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={isBlocked}
                    label="status"
                    onChange={(e) => setIsBlocked(e.target.value)}
                  >
                    <MenuItem value={'true'}>Blocked</MenuItem>
                    <MenuItem value={'false'}>Active</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                className={classes.textfiled}
                type={'date'}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ mt: 3 }}
                name="startDate"
                fullWidth
                id="outlined-basic"
                label="Start Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.textfiled}
                type={'date'}
                name="endDate"
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ mt: 3 }}
                fullWidth
                id="outlined-basic"
                label="End Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <Stack mt={2}>
                <label htmlFor="images">
                  Click here to upload Offer Labels
                  <input
                    onChange={(event) => {
                      setImages(event.target.files);
                    }}
                    id="images"
                    type="file"
                    accept="images/*"
                    multiple
                  />
                </label>
              </Stack>
            </Box>
            {isLoading ? (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                <MoonLoader color="#ffffff" loading={isLoading} size={18} />
              </Button>
            ) : (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                Create
              </Button>
            )}
          </form>
        </Box>
      </Modal>
      {/* UPDATE modal */}
      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update offer
          </Typography>
          <form onSubmit={updateOfferHandler}>
            <Typography variant={'body2'} color={'error'}>
              {errMsg}
            </Typography>
            <Box
              sx={{
                p: 1,
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 520,
              }}
            >
              <TextField
                className={classes.textfiled}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                sx={{ mt: 3 }}
                name="title"
                fullWidth
                id="outlined-basic"
                label="Offer Title"
                required
                variant="outlined"
              />
              <TextField
                className={classes.textfiled}
                onChange={(e) => setShortTitle(e.target.value)}
                value={shortTitle}
                sx={{ mt: 3 }}
                name="shortTitle"
                fullWidth
                id="outlined-basic"
                label="Short Title"
                required
                variant="outlined"
              />
              <Box sx={{ minWidth: 120, mt: 2 }}>
                <FormControl fullWidth className={classes.textfiled}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    required
                    disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={productType}
                    label="Product Type"
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <MenuItem value={'brand-new'}>Brand-new</MenuItem>
                    <MenuItem value={'pre-owned'}>Pre-owned</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120, mt: 2 }}>
                <FormControl fullWidth className={classes.textfiled}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={isBlocked}
                    label="Status"
                    onChange={(e) => setIsBlocked(e.target.value)}
                  >
                    <MenuItem value={'false'}>Active</MenuItem>
                    <MenuItem value={'true'}>Block</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                className={classes.textfiled}
                type={'date'}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ mt: 3 }}
                name="startDate"
                fullWidth
                id="outlined-basic"
                label="Start Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                className={classes.textfiled}
                type={'date'}
                value={endDate}
                name="endDate"
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ mt: 3 }}
                fullWidth
                id="outlined-basic"
                label="End Date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <Stack mt={1}>
                <label htmlFor="images">
                  Click here to upload offer labels
                  <input
                    onChange={(event) => {
                      setImages(event.target.files);
                    }}
                    id="images"
                    type="file"
                    accept="images/*"
                    multiple
                  />
                </label>
              </Stack>
            </Box>
            {isLoading ? (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                <MoonLoader color="#000" loading={isLoading} size={18} />
              </Button>
            ) : (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                Update
              </Button>
            )}
          </form>
        </Box>
      </Modal>

      <Stack mt={4} sx={{ height: 600 }} alignItems={'center'}>
        <Stack width={'100%'}>
          <Paper sx={{ boxShadow: 'none', p: 2 }} elevation={1}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnSeparator': {
                  color: '#454545',
                },
              }}
              rowsPerPageOptions={[5, 10, 20]}
              getRowId={(row) => row.slno}
              autoHeight
              rows={rows}
              columns={columns}
              GridAlignment={'center'}
              loading={pageState.loading}
              pagination
              paginationMode="server"
              rowCount={pageState.total}
              pageSize={pageState.pageSize}
              page={pageState.page}
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage }));
              }}
              onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
            />
          </Paper>
        </Stack>
      </Stack>
      {/* filter */}
      <Popover
        elevation={2}
        open={filterOpen}
        anchorEl={filterOpen}
        onClose={handleFilterBoxClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack p={3} width={400} spacing={1}>
          <Typography variant="h5">Filter by</Typography>
          <Stack direction={'column'} spacing={2}>
            <Stack direction={'row'} spacing={1}>
              <FormControl fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={statusFilter}
                  label="Status"
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value={'false'}>Active</MenuItem>
                  <MenuItem value={'true'}>Blocked</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productTypeFilter}
                  label="Product Type"
                  onChange={(e) => setProductTypeFilter(e.target.value)}
                >
                  <MenuItem value={'brand-new'}>Brand-new</MenuItem>
                  <MenuItem value={'pre-owned'}>Pre-owned</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack direction={'row'} spacing={1}>
              <TextField
                className={classes.textfiled}
                size="small"
                onChange={(e) => setStartDateFilter(e.target.value)}
                label={'Start Date'}
                type={'date'}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className={classes.textfiled}
                size="small"
                onChange={(e) => setEndDateFilter(e.target.value)}
                label={'End Date'}
                type={'date'}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Button
              sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
              onClick={handleFilterSubmit}
              fullWidth
              variant="contained"
            >
              Filter
            </Button>
          </Stack>
        </Stack>
      </Popover>
      {/* EDIT */}

      <Popover
        elevation={2}
        open={Boolean(openEditPopup)}
        anchorEl={openEditPopup}
        onClose={() => setOpenEditPopup(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleOpenEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover>
    </>
  );
}
