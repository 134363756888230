import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReplacementPending from '../components/replacementOrders/ReplacementPending';
import ReplacementCompleted from '../components/replacementOrders/ReplacementCompleted';

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#01ffff",
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#01ffff'
        }
    }
});

function ReplacementOrdersPage() {
    const [value, setValue] = useState('1');

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Helmet>
                <title> Dashboard | Replacement Orders</title>
            </Helmet>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    Manage Replacement Orders
                </Typography>
            </Stack>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList className={classes.tabs} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Pending" value="1" />
                            <Tab label="Completed" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ReplacementPending />
                    </TabPanel>
                    <TabPanel value="2">
                        <ReplacementCompleted />
                    </TabPanel>
                </TabContext>

            </Box>
        </>
    )
}

export default ReplacementOrdersPage