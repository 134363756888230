import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, TextField } from '@mui/material';
// utils
import { fNumber, fCurrency } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 450;
const LEGEND_HEIGHT = 122;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${290}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppShippingAndSelling.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppShippingAndSelling({ title, subheader, chartColors, chartData, onStartDate, onEndDate, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.name.replace('_', ' '));

  const chartSeries = chartData.map((i) => i.total);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      show: true,
      floating: true,
      horizontalAlign: 'center',
      formatter: (seriesName, opts) => {
        return [seriesName, " : ₹", opts.w.globals.series[opts.seriesIndex]]
      }
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      textAnchor: 'middle',
      formatter: (value, opts) => {
        return `₹${opts.w.globals.series[opts.seriesIndex]}`
      },
      style: {
        fontSize: '10px',
      },
    },

    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (val) => (`₹ ${val}`),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              formatter: (w) => {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return `₹${a + b}`
                })
              }
            }
          }
        }
      },

    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <StyledChartWrapper dir="ltr">
        <Stack direction={"row"} justifyContent={"space-evenly"} spacing={0.5} mb={2}>
          <TextField size='small' type={"date"} label={"Start Date"} InputLabelProps={{ shrink: true }} onChange={(e) => onStartDate(e.target.value)} />
          <TextField size='small' type={"date"} label={"End Date"} InputLabelProps={{ shrink: true }} onChange={(e) => onEndDate(e.target.value)} />
        </Stack>
        <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />
      </StyledChartWrapper>
    </Card>
  );
}
