
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

export default function App() {

  return (
    <ThemeProvider >
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
