import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { loginSuccess } from '../../../redux/userSlice';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};


const url = process.env.REACT_APP_API_URL;

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch()
  const { currentAdmin } = useSelector((state) => state.user)

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchAdmin = async () => {
      const res = await axios.get(`${url}/admin/get_admin?id=${currentAdmin._id}`)
      dispatch(loginSuccess(res.data.data))
    }
    fetchAdmin();
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,

        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: "center" }}>
        <Stack width={150}>
          <img src='/assets/images/Logo/logo.png' alt='logo' />
        </Stack>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link to={`/dashboard/profile/${currentAdmin._id}`} style={{ "&:hover": { cursor: "pointer", }, color: "inherit", textDecoration: "none" }}>
          <StyledAccount>
            <Avatar src={currentAdmin.profilePic || account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2, }}>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                {currentAdmin.fullname.toUpperCase()}
              </Typography>

              {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {currentAdmin.accType.replace("_", " ")}
              </Typography> */}
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
