import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from "react-redux"
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BrandsPage from './pages/BrandsPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import CategoryPage from './pages/CategoryPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SubCategoryPage from './pages/SubCategoryPage';
import ProductsPage from './pages/ProductsPage';
import ProductEditPage from './pages/ProductEditPage';
import OffersPage from './pages/OffersPage';
import AddProductPage from './pages/AddProductPage';
import ProfilePage from './pages/ProfilePage';
import AdminListPage from './pages/AdminListPage';
import ManageOrdersPage from './pages/ManageOrdersPage';
import OrderPage from './pages/OrderPage';
import SettingsPage from './pages/SettingsPage';
import ReturnOrdersPage from './pages/ReturnOrdersPage';
import ReplacementOrdersPage from './pages/ReplacementOrdersPage';
import RefundOrdersPage from './pages/RefundOrdersPage';
import { LoginForm } from './sections/auth/login';
import ForgotPasswordForm from './sections/auth/forgotPassword/ForgotPasswordForm';
import ResetPasswordForm from './sections/auth/resetPassword/ResetPasswordForm';

// ----------------------------------------------------------------------

export default function Router() {
  const { currentAdmin } = useSelector((state) => state.user)
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: currentAdmin ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'user/:uid', element: <UserPage /> },
        { path: 'categories', element: <CategoryPage /> },
        { path: 'brands', element: <BrandsPage /> },
        { path: 'sub/category/:parentCategoryId', element: <SubCategoryPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'products/:productId', element: <ProductEditPage /> },
        { path: 'offers', element: <OffersPage /> },
        { path: 'products/create_product', element: <AddProductPage /> },
        { path: 'profile/:id', element: <ProfilePage /> },
        { path: 'admins', element: <AdminListPage /> },
        { path: 'admins', element: <AdminListPage /> },
        { path: 'manage_orders', element: <ManageOrdersPage /> },
        { path: 'manage_orders/:orderId', element: <OrderPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'return_orders', element: <ReturnOrdersPage /> },
        { path: 'return_orders/:orderId', element: <OrderPage /> },
        { path: 'replacement_orders', element: <ReplacementOrdersPage /> },
        { path: 'replacement_orders/:orderId', element: <OrderPage /> },
        { path: 'refund', element: <RefundOrdersPage /> },
        { path: 'refund/:orderId', element: <OrderPage /> },
      ],
    },
    {
      path: '/', element: <LoginPage />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: "login", element: <LoginForm /> },
        { path: "forgot-password", element: <ForgotPasswordForm /> },
        { path: "reset-pass", element: <ResetPasswordForm /> },
      ]
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
