import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import { makeStyles } from '@mui/styles';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, MenuItem, Modal, OutlinedInput, Paper, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Add, ArrowBack, Close } from "@mui/icons-material";

import { productSchema } from "../validations/products";


const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
    },

    switch_track: {
        backgroundColor: "#01ffff",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));

function AddProductPage() {
    const [isBlocked, setIsBlocked] = useState(false);

    const [errMsg, setErrMsg] = useState("");

    const [categories, setCategories] = useState([]);

    const [categoryInput, setCategoryInput] = useState("");

    const [brands, setBrands] = useState([]);

    const [brandsInput, setBrandsInput] = useState("");

    const [subCategoryInput, setSubCategoryInput] = useState("");

    const [subCategories, setSubCategories] = useState([]);

    const [isPopular, setIsPopular] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [images, setImages] = useState([]);

    const [latestDeal, setLatestDeal] = useState(false);

    const [newArrival, setNewArrival] = useState(false);

    const [productType, setProductType] = useState("brand-new");

    const [offerEnabled, setOfferEnabled] = useState(false);

    const [offerTitle, setOfferTitle] = useState(null);

    const [offers, setOffers] = useState([]);

    const [items, setItems] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [specFormData, setSpecFormData] = useState('');


    const navigate = useNavigate();

    const formData = new FormData();

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const getCategories = async () => {
        const categoryRes = await axios.get(`${url}/category/all`, {
            params: {
                productType
            }
        });
        setCategories(categoryRes.data.data);
    };

    const getSubCategories = async () => {
        const subCategoryRes = await axios.get(`${url}/sub/category/getall?category=${categoryInput}`);
        setSubCategories(subCategoryRes.data.data || "");
    }

    const getOffers = async () => {
        const res = await axios.get(`${url}/offer/titles`,{
            params: {
                productType
            }
        });
        setOffers(res.data.data.offers);
    }

    useEffect(() => {
        const fetchData = async () => {
            getCategories();
            const brandRes = await axios.get(`${url}/brand/all`);
            setBrands(brandRes.data.data.result);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setCategories([]);
        setSubCategories([]);
        setOffers([]);
        setCategoryInput("");
        getCategories();
        getOffers();
    }, [productType]);

    useEffect(() => {
        if (categoryInput) {
            getSubCategories();
        }
    }, [categoryInput]);





    // FORM VALIDATION
    const initialValues = {
        name: "",
        shortName: "",
        mrp: "",
        adminPrice: null,
        stock: null,
        description: "",
        tags: "",
        offerPrice: "",
        skuid: "",
        shortDescription: "",
        shippingCharge: "",
        returnPeriod: null,
        replacementPeriod: null
    };

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: productSchema,
        onSubmit: (values) => {
            formData.append("name", values.name);
            formData.append("shortName", values.shortName);
            formData.append("mrp", values.mrp);
            formData.append("adminPrice", values.adminPrice);
            formData.append("offerPrice", values.offerPrice);
            formData.append("tags", values.tags);
            formData.append("stock", values.stock);
            formData.append("returnPeriod", values.returnPeriod);
            formData.append("replacementPeriod", values.replacementPeriod);
            formData.append("description", values.description);
            formData.append("skuId", values.skuid);
            formData.append("shortDescription", values.shortDescription);
            formData.append("shippingCharge", values.shippingCharge);
            createProductHandler();
        }
    });

    const createProductHandler = async () => {
        setIsLoading(true);

        formData.append("category", categoryInput);
        formData.append("brand", brandsInput);

        if (subCategoryInput.length > 0) {
            formData.append("subCategory", subCategoryInput);
        }
        formData.append("isBlocked", isBlocked);
        formData.append("isPopular", isPopular);
        formData.append("latestDeal", latestDeal);
        formData.append("newArrival", newArrival);
        formData.append("productType", productType);
        formData.append("offerEnabled", offerEnabled);
        formData.append("specifications", JSON.stringify(items));

        if (offerTitle) {
            formData.append("offerId", offerTitle);
        }

        Array.from(images).forEach((image) => {
            formData.append("productImg", image);
        });
        try {
            const res = await axios.post(`${url}/products/create`, formData);
            if (res.data) {
                setTimeout(() => {
                    navigate("/dashboard/products");
                    enqueueSnackbar("Successfully Created!", { variant: 'success' });
                }, 3000);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            setErrMsg(error.response.data.message);
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleAddSpec = (event) => {
        event.preventDefault();
        if (specFormData.length !== 0) {
            setItems([...items, specFormData]);
            setSpecFormData("");
        }
    };

    const handleAddSpecValue = (event) => {
        setSpecFormData(event.target.value);
    };

    const handleAddSpecFormSubmitHandler = (e) => {
        e.preventDefault();
        handleCloseModal();
    };

    const handleRemoveItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };


    return (
        <>
            <Helmet>
                <title> Dashboard | Add Products</title>
            </Helmet>

            <Stack direction={"row"}>
                <IconButton onClick={() => navigate("/dashboard/products")}>
                    <ArrowBack sx={{ color: "white" }} />
                </IconButton>
                <Typography variant="h4">
                    Create Product
                </Typography>

            </Stack>

            <Stack mt={2}>
                <form onSubmit={handleSubmit}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField className={classes.textfiled}
                                name="name"
                                fullWidth id="outlined-basic"
                                label="Product name"
                                variant="outlined"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {errors.name && touched.name ? <Typography color={"error"} variant={"body2"}>{errors.name}</Typography> : null}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                name="shortName"
                                fullWidth id="outlined-basic"
                                label="Short Name"
                                variant="outlined"
                                value={values.shortName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.shortName && touched.shortName ? <Typography color={"error"} variant={"body2"}>{errors.shortName}</Typography> : null}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField className={classes.textfiled}
                                name="skuid"
                                fullWidth id="outlined-basic"
                                label="SKUID"
                                variant="outlined"
                                value={values.skuid}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.skuid && touched.skuid ? <Typography color={"error"} variant={"body2"}>{errors.skuid}</Typography> : null}
                        </Grid>

                        {/* BRAND */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Select Brand</InputLabel>
                                    <Select
                                        value={brandsInput}
                                        onChange={(e) => setBrandsInput(e.target.value)}
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="select brand"
                                    >
                                        {brands.map(({ _id, name }) => <MenuItem key={_id} value={_id}>{name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>


                        {/* TYPE */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={productType}
                                        label="select brand"
                                        onChange={(e) => setProductType(e.target.value)}
                                    >
                                        <MenuItem value={"brand-new"} >Brand-new</MenuItem>
                                        <MenuItem value={"pre-owned"} >Pre-owned</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* CATEGORY */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                                    <Select
                                        required
                                        value={categoryInput}
                                        onChange={(e) => setCategoryInput(e.target.value)}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="select category"

                                        onBlur={handleBlur}
                                    >
                                        {categories.map(({ _id, name }) =>
                                            <MenuItem key={_id} value={_id}>{name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* Subcategories */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Select Subcategory (optional)</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={subCategoryInput}
                                        label="select subcategory (optional)"
                                        onChange={(e) => setSubCategoryInput(e.target.value)}
                                    >
                                        {subCategories.map(({ _id, name }) =>
                                            <MenuItem key={_id} value={_id}>{name}</MenuItem>

                                        )}

                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>


                        {/* LATEST DEAL */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Latest Deal</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={latestDeal}
                                        label="latest Deal"
                                        onChange={(e) => setLatestDeal(e.target.value)}
                                    >
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* NEW ARRIVAL */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">New Arrival</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newArrival}
                                        label="new arrival"
                                        onChange={(e) => setNewArrival(e.target.value)}
                                    >
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        {/* POPULAR */}
                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Popular</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={isPopular}
                                        label="Popular"
                                        onChange={(e) => setIsPopular(e.target.value)}
                                    >
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={isBlocked}
                                        label="Blocked"
                                        onChange={(e) => setIsBlocked(e.target.value)}
                                    >
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                name="mrp"
                                type={"number"}
                                onWheel={(e) => e.target.blur()}
                                fullWidth
                                id="outlined-basic"
                                label="MRP" variant="outlined"
                                value={values.mrp}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.mrp && touched.mrp ? <Typography color={"error"} variant={"body2"}>{errors.mrp}</Typography> : null}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                type={"number"}
                                name="adminPrice"
                                onWheel={(e) => e.target.blur()}
                                fullWidth id="outlined-basic"
                                label="Admin Price"
                                variant="outlined"
                                value={values.adminPrice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.adminPrice && touched.adminPrice ? <Typography color={"error"} variant={"body2"}>{errors.adminPrice}</Typography> : null}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                type={"number"}
                                name="shippingCharge"
                                onWheel={(e) => e.target.blur()}
                                fullWidth id="outlined-basic"
                                label="Shipping Charge"
                                variant="outlined"
                                value={values.shippingCharge}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.shippingCharge && touched.shippingCharge ? <Typography color={"error"} variant={"body2"}>{errors.shippingCharge}</Typography> : null}
                        </Grid>


                        {/* offers */}
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Offer Enabled</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={offerEnabled}
                                        label="offer enabled"
                                        onChange={(e) => setOfferEnabled(e.target.value)}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Offer title</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={offerTitle}
                                        label="offer title"
                                        onChange={(e) => setOfferTitle(e.target.value)}
                                        defaultValue={"Choose"}
                                    >
                                        {offers.map((offer) => (
                                            <MenuItem key={offer._id} value={offer._id}>{offer.title}</MenuItem>
                                        ))

                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                onWheel={(e) => e.target.blur()} fullWidth id="outlined-basic"
                                type={"number"}
                                name="offerPrice"
                                label="Offer Price"
                                variant="outlined"
                                value={values.offerPrice}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.offerPrice && touched.offerPrice ? <Typography color={"error"} variant={"body2"}>{errors.offerPrice}</Typography> : null}
                        </Grid>

                        {/* stock ,periods */}
                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                type={"number"}
                                name="stock"
                                onWheel={(e) => e.target.blur()}
                                fullWidth id="outlined-basic"
                                label="In Stock"
                                variant="outlined"
                                value={values.stock}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.stock && touched.stock ? <Typography color={"error"} variant={"body2"}>{errors.stock}</Typography> : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                type={"number"}
                                name="returnPeriod"
                                onWheel={(e) => e.target.blur()}
                                fullWidth id="outlined-basic"
                                label="Return Period"
                                variant="outlined"
                                value={values.returnPeriod}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.returnPeriod && touched.returnPeriod ? <Typography color={"error"} variant={"body2"}>{errors.returnPeriod}</Typography> : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField className={classes.textfiled}
                                type={"number"}
                                name="replacementPeriod"
                                onWheel={(e) => e.target.blur()}
                                fullWidth id="outlined-basic"
                                label="Replacement Period"
                                variant="outlined"
                                value={values.replacementPeriod}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.replacementPeriod && touched.replacementPeriod ? <Typography color={"error"} variant={"body2"}>{errors.replacementPeriod}</Typography> : null}
                        </Grid>

                        {/* Spec */}
                        <Grid item xs={12} sm={12}>
                            <TextField className={classes.textfiled}
                                name="specifications"
                                sx={{ minWidth: 120, caretColor: "transparent" }}
                                id="outlined-multiline-static"
                                fullWidth
                                autoComplete={"off"}
                                variant="outlined"
                                label="Specifications"
                                onClick={handleOpenModal}
                                value={items.map((item, index) => (` ${index + 1}. ${item}`))}
                                required
                            />
                        </Grid>
                        {/*  */}
                        {/* <Grid item xs={12} sm={12}>
                            <TextField className={classes.textfiled}
                                name="tableDescription"
                                sx={{ minWidth: 120, caretColor: "transparent" }}
                                id="outlined-multiline-static"
                                fullWidth
                                autoComplete={"off"}
                                variant="outlined"
                                label="Table Description"
                                onClick={handleOpenTableDescModal}
                                value={tableDescData.map((item, index) => (` ${index + 1}. ${item.key} : ${item.value} `))}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <TextField className={classes.textfiled}
                                name="shortDescription"
                                sx={{ minWidth: 120 }}
                                id="outlined-multiline-static"
                                fullWidth
                                variant="outlined"
                                label="Short Description"
                                multiline
                                rows={2}
                                value={values.shortDescription}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.shortDescription && touched.shortDescription ? <Typography color={"error"} variant={"body2"}>{errors.shortDescription}</Typography> : null}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField className={classes.textfiled}
                                name="description"
                                sx={{ minWidth: 120 }}
                                id="outlined-multiline-static"
                                fullWidth
                                variant="outlined"
                                label="Description"
                                multiline
                                rows={4}
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.description && touched.description ? <Typography color={"error"} variant={"body2"}>{errors.description}</Typography> : null}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField className={classes.textfiled}
                                sx={{ minWidth: 120 }}
                                id="outlined-multiline-static"
                                name="tags"
                                fullWidth
                                variant="outlined"
                                label="Tags [seperate with commas (,)]"
                                multiline
                                rows={2}
                                value={values.tags}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.tags && touched.tags ? <Typography color={"error"} variant={"body2"}>{errors.tags}</Typography> : null}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Stack>
                                <label htmlFor="images">Click here to upload Product images
                                    <Stack direction={"row"} spacing={1}>
                                        {Array.from(images).map((image) => (
                                            <img style={{ width: 50 }} src={image ? URL.createObjectURL(image) : null} key={image.index} alt="selected images" />
                                        ))}
                                    </Stack>
                                    <input required onChange={(event) => {
                                        setImages(event.target.files);
                                    }} id="images" type="file" accept="images/*" multiple
                                    />
                                </label>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography color={"error"} variant="body2">{errMsg}</Typography>

                            {
                                isLoading ? <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} type="submit" variant="contained" fullWidth>
                                    <MoonLoader
                                        color="#000"
                                        loading={isLoading}
                                        size={18}
                                    /></Button> :
                                    <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} type="submit" variant="contained" fullWidth>Create</Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </Stack>

            {/* spec modal */}
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Specifications
                    </Typography>
                    <form onSubmit={handleAddSpecFormSubmitHandler}>
                        <Stack sx={{
                            overflowY: 'scroll',
                            maxHeight: '60vh',
                        }}>
                            <List component="ul">
                                {items.map((item, index) => (
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <ListItem key={index}>{`${index + 1} : ${item}`}</ListItem>
                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                            <Close fontSize="small" />
                                        </IconButton>
                                        <br />
                                    </Stack>
                                ))}
                            </List>
                        </Stack>
                        <FormControl variant="outlined" fullWidth className={classes.textfiled} >
                            <InputLabel htmlFor="outlined-adornment-password">Specifications</InputLabel>
                            <OutlinedInput

                                value={specFormData}
                                onChange={handleAddSpecValue}
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleAddSpec}
                                            edge="end"
                                        >
                                            <Add sx={{ color: "#01ffff" }} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="specifications"
                            />
                        </FormControl>


                        <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant="contained" fullWidth type="submit">Submit</Button>
                    </form>
                </Box>
            </Modal>

            {/* Table Desc modal */}
            {/* <Modal
                className={classes.modal}
                open={tableDescModalOpen}
                onClose={handleCloseTableDescModal}
                aria-labelledby="scrollable-modal-title"
                aria-describedby="scrollable-modal-description"
            >
                <div className={classes.paper}>
                    <Typography variant="h5" sx={{ m: 2 }}>Table Description</Typography>
                    <Paper elevation={8} sx={{
                        overflowY: "scroll",
                        maxHeight: 300,
                        mt: 2,

                    }}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell >Key</TableCell>
                                    <TableCell >Value</TableCell>
                                    <TableCell align="center" width={2}>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {tableDescData.map((item, index) => (
                                    <TableRow key={item.key}>
                                        <TableCell sx={{
                                            maxWidth: 200,
                                        }}>{item.key}</TableCell>
                                        <TableCell sx={{
                                            maxWidth: 200,
                                        }} >{item.value}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => handleRemoveTableDescItem(index)}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Stack mt={2}>
                        <Stack direction={"row"}>
                            <TextField className={classes.textfiled}
                                fullWidth
                                size="small"
                                label="Enter Section Title"
                                value={tableDescTitle}
                                onChange={(event) => settableDescTitle(event.target.value)}
                            />
                            <IconButton>
                                <Add />
                            </IconButton>
                        </Stack>

                        <Stack direction={"row"} spacing={1} mt={2} mb={2}>
                            <TextField className={classes.textfiled}
                                fullWidth
                                size="small"
                                label="Enter Description Key"
                                value={tableDescKey}
                                onChange={(event) => setTableDescKey(event.target.value)}
                            />
                            <TextField className={classes.textfiled}
                                fullWidth
                                size="small"
                                label="Enter Description Value"
                                value={tableDescValue}
                                onChange={(event) => setTableDescValue(event.target.value)}
                            />
                            <Button onClick={handleAddTableDesc} variant="contained">Add</Button>
                        </Stack>
                    </Stack>
                    <Button onClick={handleAddTableTitleWithDesc} variant="contained" fullWidth type="button"  >
                        Add Section
                    </Button>
                </div>
            </Modal> */}


        </>
    );
}

export default AddProductPage;