import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

// redux
import { loginFailure, loginStart, loginSuccess } from '../../../redux/userSlice';
// components

import Iconify from '../../../components/iconify';
import { resetPassword } from '../../../validations/login';

// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    }
});
function ResetPasswordForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [errMsg, setErrMsg] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar()

    const token = searchParams.get("token")

    // FORM VALIDATION
    const initialValues = {
        password: "",
        confirmPassword: ""
    }

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: resetPassword,
        onSubmit: async (values) => {
            try {
                const res = await axios.post(`${url}/auth/admin/reset-password/verify`, {
                    token,
                    password: values.confirmPassword
                })
                if (res.data.isError === false) {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                    setTimeout(() => {
                        navigate("/login", { replace: true })
                    }, 700);
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                    setTimeout(() => {
                        navigate("/forgot-password", { replace: true })
                    }, 700);
                }
            } catch (e) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' })
                console.log(e)
            }
        }
    })


    return (
        <>

            <Typography variant="h4" sx={{ color: "white" }}>
                Reset Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <p style={{ color: "red" }}>{errMsg}</p>
                    <TextField
                        className={classes.textfiled}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                        }
                    />
                    {errors.password && touched.password ? <Typography color={"error"} variant={"body2"}>{errors.password}</Typography> : null}
                    <TextField
                        className={classes.textfiled}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Confirm Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                        }
                    />
                </Stack>
                {errors.confirmPassword && touched.confirmPassword ? <Typography color={"error"} variant={"body2"}>{errors.confirmPassword}</Typography> : null}

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
                    <Link onClick={() => navigate("/auth/login")} variant="subtitle2" underline="hover" sx={{ color: "#01FFFF" }}>
                        Remember Password?
                    </Link>
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ bgcolor: "#01FFFF", color: "#000000", "&:hover": { bgcolor: "#26c7c7" } }}>
                    Reset Password
                </LoadingButton>
            </form>
        </>
    );
}

export default ResetPasswordForm