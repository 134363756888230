import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
// @mui
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, Fade, Modal, Stack, Link, Typography, Backdrop, TextField, InputLabel, Select, MenuItem, FormControl, Grid, Card, IconButton, Popover, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { sentenceCase } from 'change-case';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';
import Label from '../components/label/Label';
// mock

const useStyles = makeStyles({
  textfiled: {
    colorScheme: "dark",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#01FFFF"
    }
  },
});
// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

export default function BrandsPage() {

  const [brands, setBrands] = useState([])

  const [open, setOpen] = useState(false);

  const [nameInput, setNameInput] = useState("")

  const [imageInput, setImageInput] = useState("");

  const [errMsg, setErrMsg] = useState("");

  const [isBlocked, setisBlocked] = useState(false)

  const [searchInput, setSearchInput] = useState("")

  const [isLoading, setIsLoading] = useState(false);


  // UPDATES.
  const [openEditMenu, setOpenEditMenu] = useState(false)

  const [openEditModal, setOpenEditModal] = useState(false)

  const [updateBlocked, setUpdateBlocked] = useState(false);

  const [nameUpdateInput, setNameUpdateInput] = useState("")

  const [imageUpdateInput, setImageUpdateInput] = useState("")

  const [selectedBrandId, setSelectedBrandId] = useState("");


  const formData = new FormData();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const fileRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    try {
      const fetchBrands = async () => {
        const res = await axios.get(`${url}/brand/all?search=${searchInput}`)
        setBrands(res.data.data.result)
      }
      fetchBrands();
    } catch (e) {
      console.log(e)
    }
  }, [open, searchInput, openEditModal])


  const createBrandHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      formData.append("name", nameInput)
      formData.append("isBlocked", isBlocked)
      formData.append("brandImg", imageInput)
      await axios.post(`${url}/brand/create`, formData)
      setIsLoading(false)
      enqueueSnackbar("Brand created!", { variant: 'success' })
      setisBlocked(false)
      setNameInput("")
      fileRef.current.value = null;
      setOpen(false)
    } catch (error) {
      setIsLoading(false)
      enqueueSnackbar("Error !", { variant: 'error' })
      setErrMsg(error.response.data.message)
      console.log(error)
    }
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: "none",
    p: 4,
  };


  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }
  // --------------updates ------------------//

  const search = new URLSearchParams(window.location.search);
  const id = search.get('id')


  const handleOpenEditModal = async (id) => {
    try {
      setOpenEditModal(true)
      setOpen(false)
      setOpenEditMenu(null)
      navigate(`?id=${id}`)

      const res = await axios.get(`${url}/brand/single?id=${id}`)
      setUpdateBlocked(res.data.data.isBlocked || false);
      setNameUpdateInput(res.data.data.name)
    } catch (e) {
      console.log(e)
    }
  }


  const updateBrandHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      formData.append("id", id)
      formData.append("name", nameUpdateInput)
      formData.append("isBlocked", updateBlocked)
      formData.append("brandImg", imageUpdateInput)
      await axios.put(`${url}/brand/update`, formData)
      setIsLoading(false)
      enqueueSnackbar("Brand updated!", { variant: 'success' })
      handleCloseEditModal()
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      enqueueSnackbar("Error!", { variant: 'error' })
      setErrMsg(error.response.data.message)
    }
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false)
    navigate(`/dashboard/brands`)
    setOpenEditMenu(null)
  }

  const handleOpenEditPopup = (e, id) => {
    setSelectedBrandId(id);
    setOpenEditMenu(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpenEditMenu(null)
    setSelectedBrandId("");
  }

  const StyledBrandImg = styled('img')({
    top: 0,
    // background: "black",
    boxShadow: "25px",
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
  });
  return (
    <>
      <Helmet>
        <title> Dashboard | Brands </title>
      </Helmet>

      <Container >
        <Stack backg justifyContent={"space-between"} direction={"row"}>
          <Typography variant="h4">
            Brands
          </Typography>
          <Button onClick={handleOpen} sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Brand
          </Button>
        </Stack>
        <TextField className={classes.textfiled} onChange={handleSearch} sx={{ mt: 2, width: 500 }} placeholder="Search..." />
        <Stack flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mt: 5 }}>
          {/* ----------------------------UPDATES------------------------------- */}
          <Grid container spacing={3}>
            {brands.map((brand) => {
              const { _id, name, isBlocked, image } = brand;
              return <Grid key={brand._id} item xs={12} sm={4} md={3}>
                <Card sx={{ minWidth: 200, boxShadow: "none" }}>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    {isBlocked && (
                      <Label
                        variant="filled"
                        color={(isBlocked === true && 'error') || 'info'}
                        sx={{
                          zIndex: 9,
                          top: 16,
                          right: 16,
                          position: 'absolute',
                          textTransform: 'uppercase',
                        }}
                      >
                        {"BLOCKED"}
                      </Label>
                    )}
                    <StyledBrandImg alt={name} src={image.fileUrl} />
                  </Box>
                  <Stack direction={'row'} justifyContent={"space-between"} spacing={2} sx={{ p: 3 }}>
                    <Link color="inherit" underline="hover">
                      <Typography variant="h5" noWrap>
                        {sentenceCase(name)}
                      </Typography>
                    </Link>
                    <IconButton onClick={(e) => handleOpenEditPopup(e, _id)} color="inherit">
                      <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                  </Stack>
                </Card>
              </Grid>
            })}
          </Grid>
        </Stack>
      </Container>


      {/* CREATE MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ mb: 4 }} align='center' id="transition-modal-title" variant="h6" component="h2">
              Create New Brand
            </Typography>
            <form onSubmit={createBrandHandler}>
              <Typography color={"red"} m={1} variant='body2'>{errMsg}</Typography>
              <TextField className={classes.textfiled} onChange={(e) => setNameInput(e.target.value)} value={nameInput} name="name" required sx={{ mb: 2 }} fullWidth id="outlined-basic" label="Brand name" variant="outlined" />

              <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Block brand</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isBlocked}
                  label="block brand"
                  onChange={(e) => setisBlocked(e.target.value)}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
              <input
                ref={fileRef}
                onChange={(e) => setImageInput(e.target.files[0])}
                required
                name='brandImg'
                type="file"
                accept="image/png, image/gif, image/jpeg"
              />
              {
                isLoading ? <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>
                  <MoonLoader
                    color="#ffffff"
                    loading={isLoading}
                    size={18}
                  /></Button> :
                  <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Create</Button>
              }
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* <Pagination onChange={(e, value) => setPage(value)} size='large' count={count} page={page} variant="outlined" color="primary" /> */}
      {/* UPDATE MODAL AND POPOVER */}
      <Popover
        elevation={2}
        open={Boolean(openEditMenu)}
        anchorEl={openEditMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenEditModal(selectedBrandId)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover>


      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Brand
          </Typography>

          <form onSubmit={updateBrandHandler}>
            <Typography color={"red"} m={1} variant='body2'>{errMsg}</Typography>
            <TextField className={classes.textfiled} onChange={(e) => setNameUpdateInput(e.target.value)} value={nameUpdateInput} name="name" sx={{ mb: 2 }} fullWidth id="outlined-basic" label="Brand name" variant="outlined" />

            <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
              <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updateBlocked}
                label="blocked"
                onChange={(e) => setUpdateBlocked(e.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
            <input
              onChange={(e) => setImageUpdateInput(e.target.files[0])}
              name='brandImg'
              type="file"
              accept="image/png, image/gif, image/jpeg"
            />
            {
              isLoading ? <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>
                <MoonLoader
                  color="#ffffff"
                  loading={isLoading}
                  size={18}
                /></Button> :
                <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Update</Button>
            }
          </form>
        </Box>
      </Modal>
    </>
  );
}
