import { createSlice } from "@reduxjs/toolkit";
import { setAdminToken } from "../storeService";


const initialState = {
    currentAdmin: null,
    isLoading: false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginStart: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.currentAdmin = action.payload;
            state.isLoading = false;
            setAdminToken(state.currentAdmin.token)

        },
        loginFailure: (state) => {
            state.isLoading = false;
        },
        logout: (state) => {
            state.currentAdmin = null;
            state.isLoading = false;
            localStorage.clear();
        }
    }
})

export const { loginStart, loginSuccess, loginFailure, logout } = userSlice.actions
export default userSlice.reducer;