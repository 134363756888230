import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { LocalShipping } from '@mui/icons-material'
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import { MoonLoader } from 'react-spinners';

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    // textfiled: {
    //     "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "#01FFFF"
    //     },
    //     "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "#01FFFF"
    //     },
    //     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //         borderColor: "#01FFFF"
    //     },

    //     "& .MuiOutlinedInput-input": {
    //         color: "#01FFFF"
    //     },
    //     "&:hover .MuiOutlinedInput-input": {
    //         color: "#01FFFF"
    //     },
    //     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //         color: "#01FFFF"
    //     },
    //     "& .MuiInputLabel-outlined": {
    //         color: "#01FFFF"
    //     },
    //     "&:hover .MuiInputLabel-outlined": {
    //         color: "#01FFFF"
    //     },
    //     "& .MuiInputLabel-outlined.Mui-focused": {
    //         color: "#01FFFF"
    //     }
    // },
});

const url = process.env.REACT_APP_API_URL;

function ShippingSettings() {
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [shippingData, setShippingData] = useState({});

    const [shippingValue, setShippingValue] = useState(0);

    const [shippingStatus, setShippingStatus] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const shippingRes = await axios.get(`${url}/configuration/shipping-charge`)
            setShippingData(shippingRes.data.data)
            setShippingValue(shippingRes.data.data.value)
            setShippingStatus(shippingRes.data.data.isBlocked)

        }
        fetchData();
    }, [editModalOpen])

    const handleOpen = () => setEditModalOpen(true);
    const handleClose = () => setEditModalOpen(false);

    const handleSettingsUpdate = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            await axios.put(`${url}/configuration/shipping-charge/update`, {
                value: shippingValue,
                isBlocked: shippingStatus
            })
            enqueueSnackbar("Shipping Settings updated!", { variant: 'success' })
            setIsLoading((false))
            handleClose();
        } catch (e) {
            enqueueSnackbar(e.response.data.message, { variant: 'error' })
            setIsLoading(false)
            console.log(e)
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 34,
        p: 4,
    };

    return (
        <>
            <Stack spacing={1}>
                <Card sx={{ boxShadow: "none" }} elevation={0} >
                    <CardActionArea onClick={handleOpen}>
                        <Stack>
                            <Stack justifyContent={"flex-start"} direction={"row"} alignItems="center">
                                <Stack width={100} ml={2}>
                                    <LocalShipping sx={{ fontSize: 55, color: "#01ffff " }} />
                                </Stack>
                                <Typography variant='h5'>Shipping Settings</Typography>
                            </Stack>
                            <Divider />
                            <CardContent sx={{ display: "flex", flexDirection: "column", p: 0, alignItems: "center" }}>
                                <Stack direction={"row"} mt={3} >
                                    <Stack spacing={1}>
                                        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                                            Threshold
                                        </Box>
                                        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                                            Status
                                        </Box>
                                    </Stack>
                                    <Stack ml={2} spacing={1}>
                                        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                                            {shippingData.value}
                                        </Box>
                                        <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                                            {shippingData.isBlocked === true ? "BLOCKED" : "ACTIVE"}
                                        </Box>
                                    </Stack>
                                </Stack>
                            </CardContent>
                            <Divider sx={{ p: 2 }} />
                            <Stack>
                                <Button sx={{ color: "#01ffff" }}>Click Here </Button>
                            </Stack>
                        </Stack>
                    </CardActionArea>
                </Card>
            </Stack>

            <Modal
                open={editModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update Shipping Settings
                    </Typography>
                    <form onSubmit={handleSettingsUpdate}>
                        <Stack spacing={1} mt={2}>
                            <TextField className={classes.textfiled} value={shippingValue} label={"Value"} type={"number"} fullWidth onChange={(e) => setShippingValue(e.target.value)} />
                            {/* <FormControl fullWidth> */}
                            {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                            <TextField
                                select
                                className={classes.textfiled}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={shippingStatus}
                                label="Status"
                                onChange={(e) => setShippingStatus(e.target.value)}
                            >
                                <MenuItem value={"false"}>Actived</MenuItem>
                                <MenuItem value={"true"}>Blocked</MenuItem>
                            </TextField>
                            {/* </FormControl> */}
                            {
                                isLoading ? <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} type="submit" variant="contained" fullWidth>
                                    <MoonLoader
                                        color="#000"
                                        loading={isLoading}
                                        size={18}
                                    /></Button> :
                                    <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Update</Button>
                            }
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default ShippingSettings