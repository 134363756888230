import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import ShippingSettings from '../components/settings/shipping/ShippingSettings'
import PaymentModeSettngs from '../components/settings/shipping/PaymentModeSettngs'

function SettingsPage() {
    return (
        <>
            <Helmet>
                <title> Dashboard | Settings</title>
            </Helmet>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    Settings
                </Typography>
            </Stack>
            <Divider sx={{ mt: 2 }} />
            <Stack mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                        <ShippingSettings />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <PaymentModeSettngs />
                    </Grid>

                </Grid>
            </Stack>
        </>
    )
}

export default SettingsPage