import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { CreditCard } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  textfiled: {
    colorScheme: 'dark',
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#01FFFF',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#01FFFF',
    },
  },
  switch_track: {
    backgroundColor: '#185c5c',
  },
  switch_base: {
    color: '#01ffff',
    '&.Mui-disabled': {
      color: '##207a7a',
    },
    '&.Mui-checked': {
      color: '#01ffff',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#01ffff',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#01ffff',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#01ffff',
    },
  },
}));

const url = process.env.REACT_APP_API_URL;

function PaymentModeSettngs() {
  const [paymentModes, setPaymentModes] = useState({});

  const [modalOpen, setModalOpen] = useState(false);

  const [codChecked, setCodChecked] = useState(false);

  const [onlineChecked, setOnlineChecked] = useState(false);

  const [codName, setCodName] = useState('');

  const [onlineName, setOnlineName] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${url}/configuration/payment-mode/get`);
      setPaymentModes(res.data.data);
      setCodChecked(res.data.data.cod);
      setOnlineChecked(res.data.data.online);
      setCodName(res.data.data.codName);
      setOnlineName(res.data.data.onlineName);
    };
    fetchData();
  }, [modalOpen]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const res = await axios.put(`${url}/configuration/payment-mode/update`, {
        cod: codChecked,
        codName,
        online: onlineChecked,
        onlineName,
      });
      console.log(res.data);
      setModalOpen(false);
    } catch (e) {
      enqueueSnackbar('Error!', { variant: 'error' });
      console.log(e);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 34,
    p: 4,
  };

  return (
    <>
      <Stack spacing={1}>
        <Card sx={{ boxShadow: 'none' }} elevation={0}>
          <CardActionArea onClick={() => setModalOpen(true)}>
            <Stack>
              <Stack alignItems={'center'} direction={'row'}>
                <Stack ml={2} width={100}>
                  <CreditCard sx={{ fontSize: 55, color: '#01ffff ' }} />
                </Stack>
                <Typography variant="h5">Payment Mode</Typography>
              </Stack>
              <Divider />
              <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 0, alignItems: 'center' }}>
                <Stack direction={'row'} mt={3}>
                  <Stack spacing={1}>
                    <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>{paymentModes.codName}</Box>
                    <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>{paymentModes.onlineName}</Box>
                  </Stack>
                  <Stack ml={2} spacing={1}>
                    <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                      {paymentModes.cod === true ? 'ACTIVE' : 'DISABLED'}
                    </Box>
                    <Box sx={{ color: 'text.primary', display: 'inline', fontSize: 14 }}>
                      {paymentModes.online === true ? 'ACTIVE' : 'DISABLED'}
                    </Box>
                  </Stack>
                </Stack>
              </CardContent>
              <Divider sx={{ p: 2 }} />
              <Stack>
                <Button sx={{ color: '#01ffff' }}>Click Here </Button>
              </Stack>
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Payment Mode Settings
          </Typography>
          <Stack justifyContent={'center'} mt={2}>
            <TextField
              label="COD Name"
              value={codName}
              onChange={(e) => setCodName(e.target.value)}
              className={classes.textfiled}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <FormGroup>
              <FormControlLabel
                label="COD"
                control={
                  <Switch
                    classes={{
                      track: classes.switch_track,
                      switchBase: classes.switch_base,
                      colorPrimary: classes.switch_primary,
                    }}
                    checked={codChecked}
                    onChange={(e) => setCodChecked(e.target.checked)}
                  />
                }
              />
            </FormGroup>
            <TextField
              label="Online Name"
              value={onlineName}
              onChange={(e) => setOnlineName(e.target.value)}
              className={classes.textfiled}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <FormGroup>
              <FormControlLabel
                label="Online"
                control={
                  <Switch
                    classes={{
                      track: classes.switch_track,
                      switchBase: classes.switch_base,
                      colorPrimary: classes.switch_primary,
                    }}
                    checked={onlineChecked}
                    onChange={(e) => setOnlineChecked(e.target.checked)}
                  />
                }
              />
            </FormGroup>
            <Button
              sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
              variant="contained"
              fullWidth
              onClick={handleUpdate}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default PaymentModeSettngs;
  