import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
// sections
import {
  AppShippingAndSelling,
  MonthlyUserCount,
  AppRefundAndEffectivePrice,
  MonthlyOrders,
  AllOrders,
  HighestSellingStates
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

export default function DashboardAppPage() {
  const theme = useTheme();

  const [shippingAndSellingData, setShippingAndSellingData] = useState([]);

  const [refundAndEffectivePrice, setRefundAndEffectivePrice] = useState([]);

  const [normalOrders, setNormalOrders] = useState({});

  const [returnOrders, setReturnOrders] = useState({});

  const [replacementOrders, setReplacementOrders] = useState({});

  const [monthlyUserData, setMonthlyUserData] = useState({});

  const [refundAndEffctStart, setRefundAndEffctStart] = useState("");

  const [refundAndEffctEnd, setRefundAndEffctEnd] = useState("");

  const [monthlyOrderStart, setMonthlyOrderStart] = useState("");

  const [monthlyOrderEnd, setMonthlyOrderEnd] = useState("");

  const [monthlyUserStart, setMonthlyUserStart] = useState("");

  const [monthlyUserEnd, setMonthlyUserEnd] = useState("");

  const [allOrders, setAllOrders] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const refundAndEffectiveRes = await axios.get(`${url}/dashboard/effectiveprice-refundamount?startDate=${refundAndEffctStart}&endDate=${refundAndEffctEnd}`)
      setRefundAndEffectivePrice(refundAndEffectiveRes.data.data)

      const monthlyOrdersRes = await axios.get(`${url}/dashboard/monthly-order-count?startDate=${monthlyOrderStart || moment().subtract(5, 'months').format("YYYY-MM-DD")}&endDate=${monthlyOrderEnd || moment().format("YYYY-MM-DD")}`)
      setNormalOrders(monthlyOrdersRes.data.data.normalProducts)
      setReturnOrders(monthlyOrdersRes.data.data.returnProducts)
      setReplacementOrders(monthlyOrdersRes.data.data.replacementProducts)

      const monthlyUserRes = await axios.get(`${url}/dashboard/monthly-new-user-count?startDate=${monthlyUserStart || moment().subtract(5, 'months').format("YYYY-MM-DD")}&endDate=${monthlyUserEnd || moment().format("YYYY-MM-DD")}`)
      setMonthlyUserData(monthlyUserRes.data.data)

      const allOrderRes = await axios.get(`${url}/dashboard/all-orders`);
      setAllOrders(allOrderRes.data.data)

    }
    fetchData();
  }, [refundAndEffctEnd, monthlyOrderEnd, monthlyUserEnd,])


  const monthlyUserDataArr = Object.entries(monthlyUserData).map(([key, value]) => ({ key, value }));

  const months = Object.keys(normalOrders)
  const normalProductsCount = Object.values(normalOrders)
  const returnProductsCount = Object.values(returnOrders)
  const replaceProductsCount = Object.values(replacementOrders)

  let data = refundAndEffectivePrice;

  let sellingPriceTotal = 0;
  let refundAmountTotal = 0;

  data.forEach(item => {
    if (item.name === "SELLING_PRICE") {
      sellingPriceTotal = item.total;
    } else if (item.name === "REFUND_AMOUNT") {
      refundAmountTotal = item.total;
    }
  });
  const effectivePriceTotal = sellingPriceTotal - refundAmountTotal;


  data = data.filter(item => item.name !== "SELLING_PRICE");
  const arr = [...data, { name: "EFFECTIVE_PRICE", total: effectivePriceTotal }]
  return (
    <>
      <Helmet>
        <title> Dashboard | Lambda Ecom.</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <MonthlyOrders
              title="Monthly orders"
              chartLabels={months}
              chartColors={[
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.warning.main,
              ]}
              chartData={[
                {
                  name: 'Normal Orders',
                  type: 'area',
                  fill: 'gradient',
                  data: normalProductsCount,
                },
                {
                  name: 'Return Orders',
                  type: 'area',
                  fill: 'gradient',
                  data: returnProductsCount,
                },
                {
                  name: 'Replacement Orders',
                  type: 'line',
                  fill: 'solid',
                  data: replaceProductsCount,
                },

              ]}
              startDate={monthlyOrderStart}
              endDate={monthlyOrderEnd}
              onStartDate={setMonthlyOrderStart}
              onEndDate={setMonthlyOrderEnd}
            />
          </Grid>


          <Grid item xs={12} md={6} lg={4}>
            <AppRefundAndEffectivePrice
              title="Price Summary"
              chartData={arr}
              chartColors={[
                theme.palette.error.main,
                theme.palette.warning.main,
                theme.palette.info.main,
              ]}
              onStartDate={setRefundAndEffctStart}
              onEndDate={setRefundAndEffctEnd}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <MonthlyUserCount
              title="Month x Users"
              chartData={monthlyUserDataArr}
              onStartDate={setMonthlyUserStart}
              onEndDate={setMonthlyUserEnd}
              startDate={monthlyUserStart}
              endDate={monthlyUserEnd}
              chartColors={[
                "#01ffff"
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AllOrders
              title="All Orders"
              chartData={allOrders}
              chartColors={[
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.warning.main,
              ]}
            />
          </Grid>


        </Grid>

      </Container>
    </>
  );
}
