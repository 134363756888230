import React from 'react'
import { Stack } from '@mui/system'
import { Paper, Skeleton, Typography } from '@mui/material'
import { GridView, LocalMall } from '@mui/icons-material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { fCurrency } from '../../utils/formatNumber'

function OrderDetails({ order }) {

    return (
        <Stack>
            <Paper sx={{ p: 3, bgcolor: "#121212", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Stack direction={"row"} spacing={3} mt={1} alignItems={"center"} justifyContent={"space-evenly"}>
                    <Paper sx={{ width: 500 }}>
                        <Typography p={1} variant='h6'>Order Details</Typography>
                        <Stack direction={"row"} p={2} >
                            <Stack width={135} height={120} sx={{ borderRight: "1px solid black" }} spacing={1}>
                                <Typography>Order ID</Typography>
                                <Typography>Date</Typography>
                                <Typography>Payment Mode</Typography>
                                {order.paymentMode === "ONLINE" &&
                                    <Typography>Payment Status</Typography>
                                }
                            </Stack>
                            <Stack ml={2} spacing={1}>
                                <Typography>{order.orderId}</Typography>
                                <Typography>{moment(order.date).format("DD-MM-YYYY")}</Typography>
                                <Typography>{order.paymentMode}</Typography>
                                {order.paymentMode === "ONLINE" &&
                                    <Typography>{order.paymentStatus}</Typography>
                                }

                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper sx={{ width: 500 }}>
                        <Typography p={1} variant='h6'>Price Details</Typography>
                        <Stack direction={"row"} p={2}>
                            <Stack width={135} sx={{ borderRight: "1px solid black" }} spacing={1}>
                                <Typography>Selling Price</Typography>
                                <Typography>Shipping Price</Typography>
                                <Typography>Refund Amount</Typography>
                                <Typography><strong>Effective Price</strong></Typography>
                            </Stack>
                            <Stack ml={2} spacing={1}>
                                <Typography>₹ {fCurrency(order.totalSellingPrice)}</Typography>
                                <Typography>₹ {fCurrency(order.shippingCharge)}</Typography>
                                <Typography>₹ {fCurrency(order.totalRefundAmount)}</Typography>
                                <Typography><strong>₹ {fCurrency(order.shippingCharge + order.totalSellingPrice - order.totalRefundAmount)}</strong></Typography>
                            </Stack>

                        </Stack>
                    </Paper>


                </Stack>
                {
                    JSON.stringify(order.replacementReference) !== '{}' &&
                    <Paper sx={{ width: 500, mt: 2, p: 1 }}>
                        <Typography variant='h6'>Original Order Reference</Typography>
                        <Link to={`/dashboard/manage_orders/${order.replacementReference.orderId}`} style={{ color: "inherit", textDecoration: "none", }}>
                            <Stack mt={1} direction={"row"} sx={{ border: "1px solid black", borderRadius: "5px" }} alignItems={"center"}>
                                <Stack bgcolor={"#01ffff"} sx={{ width: 50, height: 40, borderRadius: "5px" }} alignItems={"center"} justifyContent={"center"}>
                                    <LocalMall sx={{ fontSize: "22px", color: "#000" }} />
                                </Stack>
                                <Stack justifyContent={"center"} m={1}>
                                    <Typography variant='body1'>{order.replacementReference.orderId}</Typography>
                                </Stack>
                            </Stack>
                        </Link>
                    </Paper>
                }
                {
                    order.replacementOrder !== undefined &&
                    <Paper sx={{ width: 500, mt: 2, p: 1 }}>
                        <Typography variant='h6'>Replacement Order Reference</Typography>
                        <Link to={`/dashboard/manage_orders/${order.replacementOrder.orderId}`} style={{ color: "inherit", textDecoration: "none", }}>
                            <Stack mt={1} direction={"row"} sx={{ border: "1px solid black", borderRadius: "5px" }} alignItems={"center"}>
                                <Stack bgcolor={"#01ffff"} sx={{ width: 50, height: 40, borderRadius: "5px" }} alignItems={"center"} justifyContent={"center"}>
                                    <LocalMall sx={{ fontSize: "22px", color: "#000" }} />
                                </Stack>
                                <Stack justifyContent={"center"} m={1}>
                                    <Typography variant='body1'>{order.replacementOrder.orderId}</Typography>
                                </Stack>
                            </Stack>
                        </Link>
                    </Paper>
                }
            </Paper>


        </Stack>
    )
}

export default OrderDetails