import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Stack } from '@mui/material'
import Order from '../components/order/Order'

function OrderPage() {
    return (
        <>
            <Helmet>
                <title> Dashboard | Order Details</title>
            </Helmet>
            <Stack mt={3}>
                <Order />
            </Stack>
        </>
    )
}

export default OrderPage