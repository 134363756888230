import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
// components
import { forgotPassword } from '../../../validations/login';

// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    }
});
function ForgotPasswordForm() {
    const [errMsg, setErrMsg] = useState("")
    const [submitted, setSubmitted] = useState(false);

    const navigate = useNavigate();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar()

    // FORM VALIDATION
    const initialValues = {
        email: "",
    }

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: forgotPassword,
        onSubmit: async (values) => {
            try {
                const res = await axios.post(`${url}/auth/admin/reset-password`, {
                    email: values.email
                })
                if (res.data.isError === false) {
                    enqueueSnackbar("Password reset link has been send to your email address", { variant: 'success' })
                    setSubmitted(true)
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
            } catch (e) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' })
                console.log(e)
            }
        }
    })

    return (
        <>
            {
                !submitted ? (
                    <>
                        <Typography variant="h4" sx={{ color: "white" }}>
                            Forgot Password?
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <p style={{ color: "red" }}>{errMsg}</p>

                                <TextField type="email" value={values.email}
                                    className={classes.textfiled}
                                    onChange={handleChange}
                                    onBlur={handleBlur} name="email" id="outlined-basic" label="Enter  Email Address" variant="outlined" />
                                {errors.email && touched.email ? <Typography color={"error"} variant={"body2"}>{errors.email}</Typography> : null}
                            </Stack>

                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
                                <Link onClick={() => navigate("/login")} variant="subtitle2" underline="hover" sx={{ color: "#01FFFF" }}>
                                    Remember Password?
                                </Link>
                            </Stack>

                            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ bgcolor: "#01FFFF", color: "#000000", "&:hover": { bgcolor: "#26c7c7" } }}>
                                Submit
                            </LoadingButton>
                        </form>
                    </>
                ) : (
                    <>
                        <Stack>
                            <Typography variant='h2' sx={{ color: "#01ffff" }}>Check Your Email</Typography>
                            <Typography variant='subtitle1' sx={{ color: "#26c7c7" }}>We have sent you an email with a link to reset your password</Typography>
                        </Stack>
                    </>
                )
            }
        </>
    );
}

export default ForgotPasswordForm