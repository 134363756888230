import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TablePagination, TableRow, TableHead, Button, FormControlLabel, IconButton, TextField, Tooltip, Typography, Stack, FormControl, InputLabel, Popover, MenuItem, Select, Paper } from '@mui/material';
import { Cached, Close, ContentCopy, Done, Download, FilterList, FormatListNumbered, HourglassEmpty, IosShare, Person2, Visibility } from '@mui/icons-material';
import Zoom from '@mui/material/Zoom';
import { useSnackbar } from 'notistack';
import Iconify from '../iconify/Iconify';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
});
// ----------------------IMPORTS END---------------------------------||
const url = process.env.REACT_APP_API_URL;

function SuccessOrders() {

    const [orders, setOrders] = useState([]);

    const [pageState, setPageState] = useState({
        loading: false,
        total: 0,
        page: 0,
        pageSize: 10
    })

    const [fromDate, setFromDate] = useState("");

    const [toDate, setToDate] = useState("");

    const [filterOpen, setFilterOpen] = useState(null);

    const [orderType, setOrderType] = useState("");

    const [filters, setFilters] = useState({
        orderId: "",
        userId: "",
        replaceRefId: "",
        paymentMode: "",
        start: "",
        end: "",
        shipStatus: "",
        returnStatus: "",
        replacementStatus: "",
        orderType: "",
        courierId: "",
        invoiceNumber: ""
    });

    const [oidFilter, setOidFilter] = useState("");

    const [uidFilter, setUidFilter] = useState("");

    const [refIdFilter, setRefIdFilter] = useState("");

    const [payModeFilter, setPayModeFilter] = useState("");

    const [shippingStatus, setShippingStatus] = useState("");

    const [returnStatus, setReturnStatus] = useState("");

    const [replacementStatus, setReplacementStatus] = useState("");

    const [queueListOpen, setQueueListOpen] = useState(false);

    const [queueListPage, setQueueListPage] = useState(0);

    const [queueListRowsPerPage, setQueueListRowsPerPage] = useState(5);

    const [exportJobs, setExportJobs] = useState([]);

    const [exportJobCount, setExportJobCount] = useState(0);

    const [courierIdFilter, setCourierIdFilter] = useState("");

    const [invoiceNumber, setInvoiceNumber] = useState("");


    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async () => {
            setPageState((old) => ({ ...old, loading: true }))
            const page = pageState.page < 0 ? 0 : pageState.page;

            const res = await axios.get(`${url}/order/all?paymentStatus=SUCCESS&page=${page}&size=${pageState.pageSize}&startDate=${filters.start}&endDate=${filters.end}&orderId=${filters.orderId}&userId=${filters.userId}&replacementReference=${filters.replaceRefId}&shippingStatus=${filters.shipStatus}&replacementStatus=${filters.replacementStatus}&returnStatus=${filters.returnStatus}&orderType=${filters.orderType}&courierId=${filters.courierId}&invoiceNumber=${filters.invoiceNumber}&paymentMode=${filters.paymentMode}`)

            setPageState((old) => ({ ...old, loading: false, total: res.data.data.maxRecords }))
            setOrders(res.data.data.orders)

            const exportRes = await axios.get(`${url}/queue/order/export?page=${queueListPage}`)
            setExportJobs(exportRes.data.data.data)
            setExportJobCount(exportRes.data.data.maxRecords)
        }
        fetchData();
    }, [pageState.page, pageState.pageSize, filters, queueListPage, queueListOpen, reload])

    const ProfileRender = ({ index }) => {
        const handleEditClick = () => {
            navigate(`/dashboard/user/${index}`)
            console.log(index)
        }
        return <FormControlLabel
            control={
                <Tooltip title={"view"} arrow TransitionComponent={Zoom}>
                    <IconButton sx={{ pl: 1, color: "#01ffff" }} aria-label="add an alarm" onClick={handleEditClick} >
                        <Person2 />
                    </IconButton>
                </Tooltip>
            }
        />
    };

    const GridAlignment = 'left';

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No',
            filterable: false,
            width: 60,
            align: "center",
            headerAlign: GridAlignment,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            valueFormatter: params => params.value ?
                moment(params?.value).format("DD/MM/YYYY") : "",
            width: 105,
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'orderId',
            headerName: 'Order Id',
            width: 150,
            headerAlign: GridAlignment,
            align: "center",
            valueFormatter: params => params.value ? params.value.split("-")[1] : "",
            renderCell: (params) =>
                <Stack>
                    <Button onClick={() => navigator.clipboard.writeText(params.value)} sx={{ color: "inherit", "&:hover": {} }}>
                        <Typography variant='body2' >{params.value && params.value.split("-")[1]}</Typography>
                    </Button>
                    <Stack direction={"row"} justifyContent={"center"}>
                        <Link to={params.value} style={{ color: "inherit", textDecoration: "none" }}>
                            <Button size='small' sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }}  >
                                <Visibility fontSize='small' /> view
                            </Button>
                        </Link>
                    </Stack>
                </Stack>,
        },
        {
            field: 'userId',
            headerName: 'User ID',
            width: 100,
            renderCell: (params) => <Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Tooltip title={"copy"} arrow TransitionComponent={Zoom} >
                        <IconButton sx={{ color: "#01ffff" }} onClick={() => navigator.clipboard.writeText(params.value)}>
                            <ContentCopy fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <ProfileRender index={params.value} />
                </Stack>
            </Stack>,
            // flex: 1,
            headerAlign: GridAlignment,
            align: "left",
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            width: 100,
            headerAlign: GridAlignment,
            renderCell: (params) =>
                <Typography variant='body2'>{params.value}</Typography>,
            align: "center"
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            renderCell: (params) => <Stack>
                <Typography variant='body2' mb={"4px"}> {params.value.type.toUpperCase()}</Typography>
                {
                    params.value.type === "Replacement" &&
                    <Stack alignItems={"center"}>
                        <Stack direction={"row"} spacing={1} height={1} alignItems={"center"}>
                            <HourglassEmpty sx={{ fontSize: 16, color: "#D3BA00" }} fontSize='small' />
                            <Typography variant='body2'>{params.value.approval}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Done sx={{ fontSize: 16, color: "#27C700" }} fontSize='small' />
                            <Typography variant='body2'> {params.value.approved}</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Close sx={{ fontSize: 16, color: "#E11F1C" }} fontSize='small' />
                            <Typography variant='body2'>{params.value.rejected} </Typography>
                        </Stack>
                    </Stack>
                }
            </Stack>,
            flex: 1,
            align: "center  ",
            headerAlign: GridAlignment,

        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption'>SP </Typography>
                        <Typography textAlign={"left"} variant='caption'>SC </Typography>
                        <Typography textAlign={"left"} variant='caption'>RA </Typography>
                        <Typography textAlign={"left"} variant='caption'>TA </Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.selling)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.shipping)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.refund)}</Typography>
                        <Typography textAlign={"left"} variant='caption'>₹{fCurrency(params.value.total)}</Typography>
                    </Stack>

                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'shipping',
            headerName: 'Shipping',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>Pending </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>Shipped </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>Delivered </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>Canceled </Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>{params.value.pending}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>{params.value.shipped}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>{params.value.delivered}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={11}>{params.value.canceled}</Typography>
                    </Stack>

                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'replacement',
            headerName: 'Replacement',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Pending</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Approved </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Rejected </Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.pending}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.approved}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.rejected}</Typography>
                    </Stack>

                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
        {
            field: 'return',
            headerName: 'Return',
            flex: 1,
            renderCell: (params) => (
                <Stack direction={"row"}>
                    <Stack direction={"column"} pr={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Pending </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Approved </Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>Rejected </Typography>
                    </Stack>
                    <Stack direction={"column"} pl={1}>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.pending}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.approved}</Typography>
                        <Typography textAlign={"left"} variant='caption' fontSize={12}>{params.value.rejected}</Typography>
                    </Stack>
                </Stack>
            ),
            align: "left",
            headerAlign: GridAlignment,
        },
    ];

    const rows = orders.map((order, index) => ({
        slno: pageState.page * pageState.pageSize + index + 1,
        orderType: {
            type: order.replacementReference ? "Replacement" : "Normal",
            approval: order.invoices.awaitApproval,
            approved: order.invoices.approved,
            rejected: order.invoices.rejected
        },
        shipping: {
            pending: order.invoices.shippingPending,
            shippingInProgress: order.invoices.shippingInProgress,
            shipped: order.invoices.shipped,
            delivered: order.invoices.delivered,
            canceled: order.invoices.cancelled
        },
        replacement: {
            pending: order.invoices.replacementPending,
            approved: order.invoices.replacementApproved,
            rejected: order.invoices.replacementRejected,
        },
        return: {
            pending: order.invoices.returnPending,
            approved: order.invoices.returnApproved,
            rejected: order.invoices.returnRejected,
        },
        price: {
            selling: order.sellingPrice,
            shipping: order.shippingCharge,
            total: order.grandTotal,
            refund: order.refundAmount,
        },
        ...order
    }))


    const handleFilterBoxClose = () => {
        setFilterOpen(false)
        setOidFilter("");
        setUidFilter("");
        setRefIdFilter("");
        setPayModeFilter("")
        setToDate("")
        setFromDate("")
        setShippingStatus("");
        setReplacementStatus("");
        setReturnStatus("")
        orderType("")
    }

    const handleFilterSubmit = () => {
        setFilters((old) => ({ ...old, orderId: oidFilter, userId: uidFilter, replaceRefId: refIdFilter, paymentMode: payModeFilter, start: fromDate, end: toDate, shipStatus: shippingStatus, returnStatus, replacementStatus, orderType, courierId: courierIdFilter, invoiceNumber, }))
        handleFilterBoxClose();
    }

    const handleQueueModalOpen = async (e) => {
        setQueueListOpen(e.currentTarget);
    };

    const handleQueueModalClose = () => {
        setQueueListOpen(false);
    };

    const handleQueueChangePage = (event, newPage) => {
        console.log(newPage)
        setQueueListPage(newPage);
    };

    const handleExportButtonClick = async () => {
        const res = await axios.post(`${url}/order/export`, {
            orderId: filters.orderId,
            startDate: filters.start,
            endDate: filters.end,
            userId: filters.userId,
            shippingStatus: filters.shipStatus,
            returnStatus: filters.returnStatus,
            replacementStatus: filters.replacementStatus,
            replacementReference: filters.replaceRefId,
            orderType: filters.orderType
        })
        enqueueSnackbar("Successfully submited your Export!", { variant: 'success' })
        console.log(res.data)
    }


    const downloadDataHanlder = async (id) => {
        const res = await axios.get(`${url}/queue/order/download?id=${id}`)
        window.open(res.data.data.url)
    }

    return (
        <>
            <Stack direction={"row"} spacing={2} alignItems={"center"} m={1} mb={3} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Button sx={{ color: "#01ffff" }} onClick={handleQueueModalOpen}>
                        <FormatListNumbered />
                    </Button>
                    <Button sx={{ color: "#01ffff" }} onClick={handleExportButtonClick}>
                        <IosShare fontSize='small' /> Export
                    </Button>
                </Stack>


                <Tooltip title="Filter">
                    <IconButton onClick={(e) => setFilterOpen(e.currentTarget)}> <FilterList sx={{ color: "white" }} /></IconButton>
                </Tooltip>
            </Stack>
            {/* FILTER BOX */}
            <Popover
                elevation={2}
                anchorEl={filterOpen}
                open={filterOpen}
                onClose={() => setFilterOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack p={3} width={400} spacing={1} >
                    <Typography variant="h5">Filter by</Typography>
                    <Stack spacing={2}>
                        <Stack direction={"row"} spacing={1}>
                            <TextField
                                className={classes.textfiled}
                                onChange={(e) => setOidFilter(e.target.value)}
                                value={oidFilter}
                                size='small'
                                label="Order ID"
                            />
                            <TextField
                                className={classes.textfiled}
                                value={uidFilter}
                                onChange={(e) => setUidFilter(e.target.value)}
                                size='small'
                                label="User ID"
                            />
                        </Stack>

                        <Stack direction={"row"} spacing={1} >
                            <TextField
                                className={classes.textfiled}
                                value={refIdFilter}
                                onChange={(e) => setRefIdFilter(e.target.value)}
                                size='small'
                                label="Replacement Ref. ID"
                            />
                            <TextField
                                className={classes.textfiled}
                                value={courierIdFilter}
                                onChange={(e) => setCourierIdFilter(e.target.value)}
                                size='small'
                                label="Courier ID"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={1} >
                        <TextField
                            className={classes.textfiled}
                            fullWidth
                            value={invoiceNumber}
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            size='small'
                            label="Invoice Number"
                        />
                         <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={payModeFilter}
                                    label="paymentMode"
                                    onChange={(e) => setPayModeFilter(e.target.value)}
                                >
                                    <MenuItem value={"ONLINE"}>Online</MenuItem>
                                    <MenuItem value={"COD"}>COD</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>


                        <Stack direction={"row"} spacing={1} >
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Shipping</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={shippingStatus}
                                    label="shipping"
                                    onChange={(e) => setShippingStatus(e.target.value)}
                                >
                                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                                    <MenuItem value={"SHIPPED"}>Shipped</MenuItem>
                                    <MenuItem value={"DELIVERED"}>Delivered</MenuItem>
                                    <MenuItem value={"CANCELED"}>Canceled</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Replacement</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={replacementStatus}
                                    label="replacement"
                                    onChange={(e) => setReplacementStatus(e.target.value)}
                                >
                                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                                    <MenuItem value={"APPROVED"}>Approved</MenuItem>
                                    <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={"row"} spacing={1} >

                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Return</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={returnStatus}
                                    label="return"
                                    onChange={(e) => setReturnStatus(e.target.value)}
                                >
                                    <MenuItem value={"PENDING"}>Pending</MenuItem>
                                    <MenuItem value={"APPROVED"}>Approved</MenuItem>
                                    <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className={classes.textfiled}>
                                <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
                                <Select
                                    size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderType}
                                    label="order type"
                                    defaultValue='ALL'
                                    onChange={(e) => setOrderType(e.target.value)}
                                >
                                    <MenuItem value={"ALL"}>All</MenuItem>
                                    <MenuItem value={"NORMAL"}>Normal</MenuItem>
                                    <MenuItem value={"REPLACEMENT"}>Replacement</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField className={classes.textfiled} size='small' onChange={(e) => setFromDate(e.target.value)} type={"date"} label={"From"} InputLabelProps={{ shrink: true }} />
                            <TextField className={classes.textfiled} onChange={(e) => setToDate(e.target.value)} size='small' type={"date"} label={"To"} InputLabelProps={{ shrink: true }} />
                        </Stack>
                    </Stack>


                    <Button sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} type="submit" onClick={handleFilterSubmit} fullWidth variant="contained">Filter</Button>
                </Stack>
            </Popover>
            <Paper sx={{ p: 2 }}>

                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnSeparator": {
                            color: "#454545"
                        }
                    }}
                    getRowId={(row) => row.slno}
                    autoHeight
                    rows={rows}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    pagination
                    rowCount={pageState.total}
                    loading={pageState.loading}
                    columns={columns}
                    GridAlignment={'center'}
                    page={pageState.page}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState((old) => ({ ...old, page: newPage }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                    rowHeight={100}
                />
            </Paper>

            {/* LIST MODAL */}
            <Popover
                elevation={2}
                open={queueListOpen}
                anchorEl={queueListOpen}
                onClose={handleQueueModalClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'top',
                }}
            >
                <Paper sx={{ p: 2, width: 500 }} elevation={8}>
                    <Table>
                        <TableHead>
                            <TableRow
                            >
                                <TableCell sx={{ bgcolor: "#121212" }}>SL No</TableCell>
                                <TableCell sx={{ bgcolor: "#121212" }}>Date</TableCell>
                                <TableCell sx={{ bgcolor: "#121212" }}>Status</TableCell>
                                <TableCell sx={{ bgcolor: "#121212" }}><Button sx={{ color: "#01ffff" }} onClick={() => setReload(!reload)}>Reload <Cached fontSize='small' /></Button></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {exportJobs.map((job, index) => (
                                <TableRow key={index}>
                                    <TableCell>{queueListPage * queueListRowsPerPage + index + 1}</TableCell>
                                    <TableCell>{moment(job.updatedAt).format("DD-MM-YYYY")}<br /><Typography variant='caption'>{moment(job.updatedAt).format("hh:mm a")}</Typography></TableCell>
                                    <TableCell>{job.status}</TableCell>
                                    <TableCell align='center'>{job.isExpired === true ? "Expired" : <IconButton sx={{ color: "#01ffff" }} onClick={() => downloadDataHanlder(job._id)}>
                                        <Download />
                                    </IconButton>}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={exportJobCount}
                        page={queueListPage}
                        rowsPerPage={queueListRowsPerPage}
                        onPageChange={handleQueueChangePage}
                    />
                </Paper>
            </Popover>
        </>
    )
}

export default SuccessOrders