import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Close, Delete } from '@mui/icons-material'
import { makeStyles } from '@mui/styles';
import { Button, Divider, Grid, IconButton, Paper, setRef, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { sentenceCase } from 'change-case';


const url = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
    switch_track: {
        backgroundColor: "#185c5c",
    },
    switch_base: {
        color: "#01ffff",
        "&.Mui-disabled": {
            color: "##207a7a"
        },
        "&.Mui-checked": {
            color: "#01ffff"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#01ffff",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#01ffff",
        },
    },
}));
function ProductSpec({ productDescData, onPageRefresh, pageRefresh }) {

    const [newSpecTitle, setNewSpecTitle] = useState("");

    const [newSpecDescKey, setNewSpecDescKey] = useState("");

    const [newSpecDescValue, setNewSpecDescValue] = useState("");

    const [updateSpecKey, setUpdateSpecKey] = useState("");

    const [updateSpecValue, setUpdateSpecValue] = useState("");

    const { productId } = useParams();
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();

    const createNewSpec = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${url}/products/specifications/create`, {
                id: productId,
                title: newSpecTitle,
                descriptionKey: newSpecDescKey,
                descriptionValue: newSpecDescValue
            })
            enqueueSnackbar("Description created!", { variant: 'success' })
            onPageRefresh(!pageRefresh)
            setNewSpecDescKey("");
            setNewSpecTitle("");
            setNewSpecDescValue("")
        } catch (e) {
            enqueueSnackbar("Error !", { variant: 'error' })
            console.log(e)
        }
    }

    const addPropertyToTableDesc = async (e) => {
        try {
            await axios.post(`${url}/products/specifications/add-property`, {
                productId,
                tableId: e.target.id,
                descriptionKey: updateSpecKey,
                descriptionValue: updateSpecValue,
            })
            enqueueSnackbar("Property Added!", { variant: 'success' })
            setUpdateSpecKey("");
            setUpdateSpecValue("")
            onPageRefresh(!pageRefresh)

        } catch (e) {
            enqueueSnackbar("Error !", { variant: 'error' })
            console.log(e)
        }
    }

    const removeAPropertyFromTable = async (e, tableId, descKey) => {
        try {
            await axios.post(`${url}/products/specifications/delete-property`, {
                productId,
                tableId,
                descriptionKey: descKey
            })
            enqueueSnackbar("Property Removed!", { variant: 'success' })
            onPageRefresh(!pageRefresh)
        } catch (e) {
            enqueueSnackbar("Error !", { variant: 'error' })
            console.log(e)
        }
    }

    const deleteATable = async (e, tableId) => {
        try {
            await axios.post(`${url}/products/specifications/table-delete`, {
                productId,
                tableId,
            })
            enqueueSnackbar("Table Deleted!", { variant: 'success' })
            onPageRefresh(!pageRefresh)
        } catch (e) {
            enqueueSnackbar("Error !", { variant: 'success' })
            console.log(e)
        }
    }


    return (
        <Stack>
            <Typography mb={1} variant='h4'>Manage Table Specifications</Typography>
            <Stack>
                <form onSubmit={createNewSpec}>
                    <Stack spacing={1} justifyContent={"center"} p={2}>
                        <Stack >
                            <TextField className={classes.textfiled} size='small' label="Enter Title" fullWidth value={newSpecTitle} onChange={(e) => setNewSpecTitle(e.target.value)} />
                        </Stack>
                        <Stack spacing={1} direction={"row"}>
                            <TextField className={classes.textfiled} size='small' label="Enter Description Key" fullWidth value={newSpecDescKey} onChange={(e) => setNewSpecDescKey(e.target.value)} />
                            <TextField className={classes.textfiled} size='small' label="Enter Description Value" fullWidth value={newSpecDescValue} onChange={(e) => setNewSpecDescValue(e.target.value)} />
                        </Stack>
                        <Button type='submit' sx={{ marginTop: "10px", width: 250, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} variant='contained'>Add new</Button>
                    </Stack>
                </form>
                <Divider />
                <Stack spacing={1} mt={3}>
                    <Grid container spacing={3} >
                        {productDescData.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Stack justifyContent={"space-between"} direction={"row"} p={2} sx={{ bgcolor: "#121212" }}>
                                        <Typography variant='h6'>{sentenceCase(item.title)}</Typography>
                                        <IconButton onClick={(e) => deleteATable(e, item._id)}>
                                            <Delete sx={{ color: "white" }} />
                                        </IconButton>
                                    </Stack>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell >Key</TableCell>
                                                <TableCell >Value</TableCell>
                                                <TableCell width={2}>Remove</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {item.descriptions.map((desc) => (
                                                <TableRow key={item.key}>

                                                    <TableCell sx={{
                                                        maxWidth: 200,
                                                    }}>{sentenceCase(desc.key)}</TableCell>
                                                    <TableCell sx={{
                                                        maxWidth: 200,
                                                    }} >{sentenceCase(desc.value)}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton onClick={(e) => removeAPropertyFromTable(e, item._id, desc.key)}>
                                                            <Close fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>

                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Stack mt={2} spacing={1} justifyContent={"center"} p={2}>

                                        <Stack spacing={1} direction={"row"}>
                                            <TextField className={classes.textfiled} size='small' label="Enter Description Key" value={updateSpecKey} fullWidth onChange={(e) => setUpdateSpecKey(e.target.value)} />
                                            <TextField className={classes.textfiled} size='small' label="Enter Description Value" value={updateSpecValue} fullWidth onChange={(e) => setUpdateSpecValue(e.target.value)} />
                                            <Button id={item._id} variant='contained' sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd", }, }} onClick={addPropertyToTableDesc}>Add</Button>

                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ProductSpec