import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { sentenceCase } from 'change-case';
// @mui
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Container,
  Fade,
  Link as MUILink,
  Modal,
  Stack,
  Typography,
  Backdrop,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Pagination,
  Grid,
  Card,
  styled,
  IconButton,
  Popover,
  Chip,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { MoonLoader } from 'react-spinners';
import { FilterList } from '@mui/icons-material';
// components
import Iconify from '../components/iconify';
import Label from '../components/label/Label';
// mock
const useStyles = makeStyles({
  textfiled: {
    colorScheme: 'dark',

    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#01FFFF',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#01FFFF',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#01FFFF',
    },
  },
});
// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

export default function CategoryPage() {
  const [categories, setCategories] = useState([]);

  const [open, setOpen] = useState(false);

  const [nameInput, setNameInput] = useState('');

  const [imageInput, setImageInput] = useState('');

  const [isPopular, setIsPopular] = useState(false);

  const [productType, setProductType] = useState('brand-new');

  const [errMsg, setErrMsg] = useState('');

  const [limit, setLimit] = useState(8);

  const [page, setPage] = useState(1);

  const [count, setCount] = useState(null);

  const [isBlocked, setIsBlocked] = useState(false);

  const [searchInput, setSearchInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // UPDATE

  const [editPopupOpen, seteditPopupOpen] = useState();

  const [filterPopupOpen, setFilterPopupOpen] = useState();

  const [openEditModal, setOpenEditModal] = useState(false);

  const [selectedCardId, setSelectedCardId] = useState('');

  const [updateNameInput, setUpdateNameInput] = useState('');

  const [updatePopular, setUpdatePopular] = useState('');

  const [updateIsBlocked, setUpdateIsBlocked] = useState('');

  const [updateImageInput, setUpdateImageInput] = useState('');

  const [updateProductType, setUpdateProductType] = useState('');

  const [filters, setFilters] = useState({ isBlocked: '', isPopular: '', productType: '' });

  const navigate = useNavigate();

  const search = new URLSearchParams(window.location.search);
  const id = search.get('id');

  const formData = new FormData();
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef();
  const classes = useStyles();

  const filterOpen = Boolean(filterPopupOpen);

  const handleFilterBoxClose = () => {
    setFilterPopupOpen(null);
    setUpdateIsBlocked('');
    setUpdatePopular('');
    setUpdateProductType('');
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    setFilters((old) => ({
      ...old,
      isBlocked: updateIsBlocked,
      isPopular: updatePopular,
      productType: updateProductType,
    }));
    handleFilterBoxClose();
  };

  useEffect(() => {
    const fetchcategories = async () => {
      try {
        const res = await axios.get(`${url}/category/admin/all?search=${searchInput}&page=${page}&size=${limit}`, {
          params: filters,
        });
        setCategories(res.data.data.result);
        setLimit(res.data.data.limit);
        setCount(Math.ceil(res.data.data.total / limit));
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    fetchcategories();
  }, [open, page, limit, searchInput, openEditModal, filters]);

  const createCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      formData.append('name', nameInput);
      formData.append('isPopular', isPopular);
      formData.append('categoryImg', imageInput);
      formData.append('isBlocked', isBlocked);
      formData.append('productType', productType);

      setIsLoading(true);
      const res = await axios.post(`${url}/category/create`, formData);
      console.log(res.data.data);
      setIsLoading(false);
      setIsPopular(false);
      setProductType('');
      setNameInput('');
      fileRef.current.value = null;
      setOpen(false);
      setErrMsg('');
      enqueueSnackbar('Category created!', { variant: 'success' });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      console.log(error);
      setErrMsg(error.response.data.message);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'none',
    p: 4,
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  // ------------- upadte -------------- //

  const handleOpenMenu = (event, id) => {
    seteditPopupOpen(event.currentTarget);
    setSelectedCardId(id);
  };

  const handleCloseMenu = () => {
    seteditPopupOpen(null);
  };

  const handleOpenEditModal = async (id) => {
    try {
      setOpenEditModal(true);
      seteditPopupOpen(null);
      navigate(`?id=${id}`);
      const res = await axios.get(`${url}/category/single?id=${id}`);
      setUpdatePopular(res.data.data.isPopular ? 'true' : 'false');
      setUpdateIsBlocked(res.data.data.isBlocked ? 'true' : 'false');
      setUpdateNameInput(res.data.data.name);
      setUpdateProductType(res.data.data.productType);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setUpdateNameInput('');
    setUpdateIsBlocked('');
    setUpdatePopular('');
    setUpdateImageInput('');
    setUpdateProductType('');
    navigate('/dashboard/categories');
  };

  const updateCategoryHandler = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    try {
      setIsLoading(true);
      formData.append('id', id);
      formData.append('name', updateNameInput);
      formData.append('isBlocked', updateIsBlocked);
      formData.append('isPopular', updatePopular);
      formData.append('categoryImg', updateImageInput);
      formData.append('productType', updateProductType);

      await axios.put(`${url}/category/update`, formData);
      setIsLoading(false);
      enqueueSnackbar('Category Updated!', { variant: 'success' });
      setOpenEditModal(false);
      setUpdateNameInput('');
      setUpdateProductType('');
      setUpdateIsBlocked('');
      setUpdatePopular('');
      setUpdateImageInput('');
      navigate(`/dashboard/categories`);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrMsg(error.response.data.message);
      enqueueSnackbar('Error !', { variant: 'error' });
    }
  };

  const handleFilterBoxOpen = async (event) => {
    setFilterPopupOpen(event.currentTarget);
  };

  const StyledCategoryImg = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });

  const filterButtonRef = useRef(null);
  return (
    <>
      <Helmet>
        <title> Dashboard | Categories </title>
      </Helmet>

      <Container>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant="h4">Categories</Typography>
          <Button
            onClick={handleOpen}
            sx={{ backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Category
          </Button>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
          <TextField
            onChange={handleSearch}
            sx={{ mt: 2, width: 500 }}
            className={classes.textfiled}
            placeholder="Search..."
          />
          <Tooltip title="Filter">
            <IconButton onClick={handleFilterBoxOpen} ref={filterButtonRef}>
              <FilterList sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Stack>
        {/* filter */}

        <Popover
          elevation={2}
          id={id}
          open={filterOpen}
          anchorEl={filterButtonRef.current}
          onClose={handleFilterBoxClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <form onSubmit={handleFilterSubmit}>
            <Stack p={3} width={400} spacing={1}>
              <Typography variant="h5">Filter by</Typography>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={updateProductType}
                  label="Product Type"
                  onChange={(e) => setUpdateProductType(e.target.value)}
                >
                  <MenuItem value="brand-new">Brand-new</MenuItem>
                  <MenuItem value="pre-owned">Pre-owned</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Is Popular</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={updatePopular}
                  label="Is Popular"
                  onChange={(e) => setUpdatePopular(e.target.value)}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Is Blocked</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={updateIsBlocked}
                  label="Is Blocked"
                  onChange={(e) => setUpdateIsBlocked(e.target.value)}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>

              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                fullWidth
                variant="contained"
              >
                Filter
              </Button>
            </Stack>
          </form>
        </Popover>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mt: 5 }}>
          {/* <CategoryList categories={categories} /> */}
          {/* ------------------------------------ UPDATES ----------------------------------------------- */}
          <Grid container spacing={3}>
            {categories.map((category) => {
              const { _id, name, isBlocked, isPopular, image } = category;
              return (
                <Grid key={_id} item xs={12} sm={6} md={3}>
                  <Card sx={{ boxShadow: 'none' }}>
                    <MUILink underline="hover" color="inherit">
                      <Link to={`/dashboard/sub/category/${_id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Box sx={{ pt: '100%', position: 'relative' }}>
                          {isBlocked && (
                            <Label
                              variant="filled"
                              color={(isBlocked === true && 'error') || 'info'}
                              sx={{
                                zIndex: 9,
                                top: 16,
                                right: 16,
                                position: 'absolute',
                                textTransform: 'uppercase',
                              }}
                            >
                              {'BLOCKED'}
                            </Label>
                          )}

                          <StyledCategoryImg alt={'card'} src={image.fileUrl} />
                        </Box>
                      </Link>
                      <Stack spacing={0.5} sx={{ p: 3 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Typography variant="h5" noWrap>
                            {sentenceCase(name)}
                          </Typography>
                          <Typography
                            sx={{
                              position: 'absolute',
                              ml: 15,
                            }}
                            variant="body2"
                          >
                            {isPopular && <Label color={isPopular === true && 'success'}>{'Popular'}</Label>}
                          </Typography>

                          <IconButton onClick={(e) => handleOpenMenu(e, _id)} size="large" color="inherit">
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </Stack>
                        <Chip
                          label={category.productType && sentenceCase(category.productType)}
                          color={category.productType === 'brand-new' ? 'primary' : 'warning'}
                          variant="outlined"
                          size="small"
                          style={{ marginTop: '1em' }}
                        />
                      </Stack>
                    </MUILink>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Container>

      {/* CREATE MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ mb: 4 }} align="center" id="transition-modal-title" variant="h6" component="h2">
              Create New Category
            </Typography>
            <form onSubmit={createCategoryHandler}>
              <Typography color={'red'} m={1} variant="body2">
                {errMsg}
              </Typography>
              <TextField
                className={classes.textfiled}
                onChange={(e) => setNameInput(e.target.value)}
                name="name"
                required
                sx={{ mb: 2 }}
                fullWidth
                id="outlined-basic"
                label="Category name"
                variant="outlined"
              />

              <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productType}
                  label="popular category"
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <MenuItem value="brand-new">Brand-new</MenuItem>
                  <MenuItem value="pre-owned">Pre-owned</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Popular category</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isPopular}
                  label="popular category"
                  onChange={(e) => setIsPopular(e.target.value)}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
                <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isBlocked}
                  label="blocked"
                  onChange={(e) => setIsBlocked(e.target.value)}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
              <input
                ref={fileRef}
                onChange={(e) => setImageInput(e.target.files[0])}
                required
                name="categoryImg"
                type="file"
                accept="image/png, image/gif, image/jpeg"
              />
              {isLoading ? (
                <Button
                  type="submit"
                  sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                  variant="contained"
                  fullWidth
                >
                  <MoonLoader color="#ffffff" loading={isLoading} size={18} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                  variant="contained"
                  fullWidth
                >
                  Create
                </Button>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>

      <Stack sx={{ alignItems: 'center', mt: 6 }}>
        <Pagination
          onChange={(e, value) => setPage(value)}
          size="large"
          count={count}
          page={page}
          variant="outlined"
          color="primary"
        />
      </Stack>

      {/* EDIT MODAL AND POPOVER */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Category
          </Typography>

          <form onSubmit={updateCategoryHandler}>
            <Typography color={'red'} m={1} variant="body2">
              {errMsg}
            </Typography>
            <TextField
              className={classes.textfiled}
              onChange={(e) => setUpdateNameInput(e.target.value)}
              value={updateNameInput}
              name="name"
              sx={{ mb: 2 }}
              fullWidth
              id="outlined-basic"
              label="Category name"
              variant="outlined"
            />

            <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
              <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
              <Select
                required
                disabled
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updateProductType}
                label="popular category" 
                onChange={(e) => setUpdateProductType(e.target.value)}
              >
                <MenuItem value="brand-new">Brand-new</MenuItem>
                <MenuItem value="pre-owned">Pre-owned</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
              <InputLabel id="demo-simple-select-label">Popular category</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updatePopular}
                label="popular category"
                onChange={(e) => setUpdatePopular(e.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ mb: 2 }} fullWidth className={classes.textfiled}>
              <InputLabel id="demo-simple-select-label">Blocked</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updateIsBlocked}
                label="blocked"
                onChange={(e) => setUpdateIsBlocked(e.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
            <input
              onChange={(e) => setUpdateImageInput(e.target.files[0])}
              name="categoryImg"
              type="file"
              accept="image/png, image/gif, image/jpeg"
            />
            {isLoading ? (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                <MoonLoader color="#000" loading={isLoading} size={18} />
              </Button>
            ) : (
              <Button
                type="submit"
                sx={{ mt: 2, backgroundColor: '#01ffff', color: 'black', '&:hover': { backgroundColor: '#00bdbd' } }}
                variant="contained"
                fullWidth
              >
                Update
              </Button>
            )}
          </form>
        </Box>
      </Modal>
      {/*  */}
      <Popover
        elevation={2}
        open={Boolean(editPopupOpen)}
        anchorEl={editPopupOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenEditModal(selectedCardId)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover>
    </>
  );
}
