import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { MoonLoader } from 'react-spinners';
import { MoreVert } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Paper, Popover, Select, Stack, TextField, Typography } from '@mui/material'
import { sentenceCase } from 'change-case';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux'
import Label from '../components/label/Label';
import Iconify from '../components/iconify/Iconify';

// ------------------------------------------IMPORTS END------------------------------------------------------------------
const useStyles = makeStyles({
    textfiled: {
        colorScheme: "dark",
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#01FFFF"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#01FFFF"
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#01FFFF"
        }
    },
});
const url = process.env.REACT_APP_API_URL;

function AdminListPage() {

    const [adminData, setAdminData] = useState([]);

    const [openEditPopup, setOpenEditPopup] = useState(false);

    const [rowId, setRowId] = useState("");

    const [openEditModal, setOpenEditModal] = useState(false);

    const [isBlocked, setIsBlocked] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [errMsg, setErrMsg] = useState("");

    const [searchValue, setsearchValue] = useState("");

    const [selectedAdmin, setSelectedAdmin] = useState({});

    const [rowCount, setRowCount] = useState(0);

    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 10
    })
    const [createOpen, setCreateOpen] = useState(false);

    const [subEmail, setSubEmail] = useState("");

    const [subFullname, setSubFullname] = useState("");

    const [subPassword, setSubPassword] = useState("");

    const [adminType, setAdminType] = useState("SUB_ADMIN");


    const { currentAdmin } = useSelector((state) => state.user)

    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${url}/admin/all?search=${searchValue}&page=${pageState.page < 0 ? 0 : pageState.page}&size=${pageState.pageSize}`)
                setAdminData(res.data.data.data)
                setRowCount(res.data.data.count)
            } catch (e) {
                console.log(e)
            }
        }
        fetchData();
    }, [openEditModal, searchValue, pageState.page, pageState.pageSize, createOpen])


    const MatEdit = ({ index }) => {
        const handleEditClick = (e) => {
            setOpenEditPopup(e.currentTarget)
            setRowId(index)
        }
        return <FormControlLabel
            control={
                <IconButton aria-label="add an alarm" onClick={handleEditClick} >
                    <MoreVert style={{ color: "white" }} />
                </IconButton>
            }
        />
    };

    const handleOpenEditModal = async () => {
        setOpenEditPopup(null)
        setOpenEditModal(true)
        const res = await axios.get(`${url}/admin/get_admin?id=${rowId}`)
        setSelectedAdmin(res.data.data)
        setErrMsg("")

    }

    const updateAdminStatusHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            await axios.post(`${url}/admin/status?id=${rowId}`, { isBlocked })
            setIsLoading(false)
            setOpenEditModal(null)
            enqueueSnackbar("Admin status updated!", { variant: 'success' })
        } catch (e) {
            setErrMsg(e.response.data.message)
            enqueueSnackbar(e.response.data.message, { variant: 'error' })
            setIsLoading(false)
            console.log(e)
        }
    }

    const columns = [
        {
            field: 'slno',
            headerName: 'Sl.No.',
            filterable: false,
            flex: 1
        },
        {
            field: 'fullname',
            headerName: 'Admin Name',
            width: 250,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 120,
            flex: 1
        },
        {
            field: 'accType',
            headerName: 'Type',
            width: 120,
            flex: 1,
            valueFormatter: (params) => params.value.replace("_", " ")
        },
        {
            field: 'isBlocked',
            headerName: 'Status',
            width: 90,
            renderCell: (params) => <Paper sx={{ width: 80, fontSize: "12px", bgcolor: `${params.row.isBlocked === true ? "#5c0000" : "#155c00"}`, textAlign: "center" }}>{params.row.isBlocked === true ? "Blocked" : "Active"}</Paper>,
            flex: 1
        },
        {
            field: 'lastLogin',
            headerName: "Last Login",
            flex: 1,
            valueFormatter: (params) => moment(params.value).format("DD-MM-YYYY")
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: "center",
            align: 'center',
            width: 60,
            renderCell: (params) =>
            (
                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                    <MatEdit index={params.row._id} />
                </div>
            ),
            flex: 1
        },
    ];

    const rows = adminData.map((result, index) => (
        {
            "slno": pageState.page * pageState.pageSize + index + 1,
            ...result
        }))

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "none",
        p: 4,
    };
    const handleCreateModalOpen = () => {
        setCreateOpen(true)
    }

    const handleCreateSubAdmin = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            await axios.post(`${url}/admin/create`, {
                fullname: subFullname,
                email: subEmail,
                accType: adminType,
                password: subPassword
            })
            enqueueSnackbar("Admin Created!", { variant: 'success' })
            setIsLoading(false)
            setCreateOpen(false)
            setSubFullname("");
            setSubEmail("");
            setSubPassword("")
        } catch (e) {
            console.log(e)
            enqueueSnackbar(e.response.data.message, { variant: 'error' })
            setIsLoading(false)
        }
    }
    return (
        <>
            <Helmet>
                <title> Dashboard | Admins</title>
            </Helmet>
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography variant="h4">
                    Manage Admin
                </Typography>
                {currentAdmin.accType === "SUPER_ADMIN" &&
                    <Button onClick={handleCreateModalOpen} sx={{ backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        Create Sub Admin
                    </Button>
                }
            </Stack>
            {/* CREATE SubAdmin */}
            <Modal
                open={createOpen}
                onClose={() => setCreateOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create New Sub Admin
                    </Typography>
                    <form onSubmit={handleCreateSubAdmin}>
                        <Box >
                            <TextField className={classes.textfiled} onChange={(e) => setSubFullname(e.target.value)} sx={{ mt: 3 }} name="fullname" fullWidth id="outlined-basic" label="Admin Name" required variant="outlined" />
                            <TextField className={classes.textfiled} onChange={(e) => setSubEmail(e.target.value)} sx={{ mt: 3 }} name="email" fullWidth id="outlined-basic" label="Admin Email" required variant="outlined" />

                            <Box sx={{ minWidth: 120, mt: 2 }}>
                                <FormControl fullWidth className={classes.textfiled}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="type"
                                        value={adminType}
                                        onChange={(e) => setAdminType(e.target.value)}
                                    >
                                        <MenuItem value={"SUB_ADMIN"}>SUB ADMIN</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <TextField className={classes.textfiled} onChange={(e) => setSubPassword(e.target.value)} sx={{ mt: 3 }} name="password" fullWidth id="outlined-basic" label="Password" required variant="outlined" />

                        </Box>
                        {
                            isLoading ? <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>
                                <MoonLoader
                                    color="#ffffff"
                                    loading={isLoading}
                                    size={18}
                                /></Button> :
                                <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Create</Button>
                        }
                    </form>
                </Box>
            </Modal>
            <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                <TextField className={classes.textfiled} sx={{ mt: 2, width: 500 }} placeholder="Search..." onChange={(e) => setsearchValue(e.target.value)} />
            </Stack>

            <Paper sx={{ mt: 3, p: 2 }}>
                <DataGrid
                    sx={{
                        "& .MuiDataGrid-columnSeparator": {
                            color: "#454545"
                        }
                    }}
                    rows={rows}
                    columns={columns}
                    pagination
                    paginationMode='server'
                    getRowId={(row) => row.slno}
                    rowCount={rowCount}
                    pageSize={pageState.pageSize}
                    page={pageState.page}
                    onPageChange={(newPage) => {
                        setPageState((old) => ({ ...old, page: newPage }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState((old) => ({ ...old, pageSize: newPageSize }))}
                    autoHeight
                    rowsPerPageOptions={[2, 5, 10, 20, 30]}
                    GridAlignment={'center'}
                />

            </Paper>

            <Modal
                open={openEditModal}
                onClose={() => setOpenEditModal(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Status Update
                    </Typography>
                    <form onSubmit={updateAdminStatusHandler}>
                        <Typography variant={"body2"} color={"error"}>{errMsg}</Typography>

                        <Box sx={{ minWidth: 120, mt: 2 }}>
                            <TextField
                                className={classes.textfiled}
                                select
                                fullWidth
                                required
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isBlocked}
                                label="Status"
                                onChange={(e) => setIsBlocked(e.target.value)}
                            >
                                <MenuItem value={"false"}>Active</MenuItem>
                                <MenuItem value={"true"}>Block</MenuItem>
                            </TextField>
                        </Box>
                        {
                            isLoading ? <Button sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} type="submit" variant="contained" fullWidth>
                                <MoonLoader
                                    color="#000"
                                    loading={isLoading}
                                    size={18}
                                /></Button> :
                                <Button type="submit" sx={{ mt: 2, backgroundColor: "#01ffff", color: "black", "&:hover": { backgroundColor: "#00bdbd" } }} variant="contained" fullWidth>Update</Button>
                        }
                    </form>
                </Box>
            </Modal>

            <Popover
                elevation={1}
                open={openEditPopup}
                anchorEl={openEditPopup}
                onClose={() => setOpenEditPopup(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleOpenEditModal}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
            </Popover>
        </>
    )
}

export default AdminListPage