import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

// redux
import { loginFailure, loginStart, loginSuccess } from '../../../redux/userSlice';
// components

import Iconify from '../../../components/iconify';
import { loginSchema } from '../../../validations/login';

// ----------------------------------------------------------------------
const url = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  textfiled: {
    colorScheme: "dark",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01FFFF"
    },
    "& .MuiOutlinedInput-input": {
      color: "#01FFFF"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#01FFFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#01FFFF"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#01FFFF"
    }
  }
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("")
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const classes = useStyles();

  // FORM VALIDATION
  const initialValues = {
    email: "",
    password: ""
  }

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      dispatch(loginStart())
      try {
        const response = await axios.post(`${url}/auth/admin/login`, {
          email: values.email,
          password: values.password
        })
        dispatch(loginSuccess(response.data.data))
        navigate('/dashboard', { replace: true });
      } catch (error) {
        setErrMsg(error.response.data.message);
        dispatch(loginFailure())
      }
    }
  })


  return (
    <>

      <Typography variant="h4" sx={{ color: "white" }}>
        Sign in to Lambda Gaming
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <p style={{ color: "red" }}>{errMsg}</p>

          <TextField type="email" value={values.email}
            className={classes.textfiled}
            onChange={handleChange}
            onBlur={handleBlur} name="email" id="outlined-basic" label="Email Address" variant="outlined" />
          {errors.email && touched.email ? <Typography color={"error"} variant={"body2"}>{errors.email}</Typography> : null}

          <TextField
            className={classes.textfiled}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }
            }
          />
          {errors.password && touched.password ? <Typography color={"error"} variant={"body2"}>{errors.password}</Typography> : null}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2, cursor: "pointer" }}>
          <Link onClick={() => navigate("/forgot-password")} variant="subtitle2" underline="hover" sx={{ color: "#01FFFF" }}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ bgcolor: "#01FFFF", color: "#000000", "&:hover": { bgcolor: "#26c7c7" } }}>
          Login
        </LoadingButton>
      </form>
    </>
  );
}
