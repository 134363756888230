import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  boxShadow: '44.3042px 0px 46.4092px -27.125px #000000'
}));

const StyledContent = styled('div')(({ theme }) => ({
  width: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Lambda Ecom. </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <img src='/assets/images/Logo/logo.png' alt='logo' style={{ position: "static", width: 250, marginTop: 20 }} />
            <img src="/assets/images/Logo/lambda-login.webp" alt="login" style={{ width: 380, marginTop: 30 }} />
            <Typography variant="h3" sx={{ px: 5, mt: 5, color: "#FFFFFF" }}>
              Hi, Welcome Back
            </Typography>
          </StyledSection>
        )}
        <StyledContent>
          <Container >
            <Outlet />
          </Container>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
