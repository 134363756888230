import * as Yup from "yup"

export const loginSchema = Yup.object({
    email: Yup.string().email().required("Please enter your Email to continue!"),
    password: Yup.string().min(8).max(20).required("Please enter password to continue!")
})

export const forgotPassword = Yup.object({
    email: Yup.string().email().required("Please enter your Email to continue!"),
})
export const resetPassword = Yup.object({
    password: Yup.string().min(8).max(20).required("Please enter password to continue!"),
    confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
        )
    })
})